import Input from "components/Common/Input/Input";
import Toggle from "components/Common/Toggle/Toggle";
import { Body1SemiBold, Body2Medium } from "components/TextStyle";
import { IParticipant, Verification } from "interfaces/contract";
import React from "react";
import styled from "styled-components";

export default function VerificationModal({
  receivers,
  setRecipients,
  verificationModal,
  onChangeHandler,
}: {
  receivers: IParticipant[];
  setRecipients: React.Dispatch<React.SetStateAction<IParticipant[]>>;
  verificationModal: {
    opened: boolean;
    selectedReceiverId: string;
    phoneNumber?: string;
  };
  onChangeHandler: (id: string, key: string, value: string) => void;
}) {
  const onToggleHandler = (type: Verification) => {
    setRecipients((prev) =>
      prev.map((receiver) => {
        if (receiver.id === verificationModal.selectedReceiverId)
          return {
            ...receiver,
            verification: {
              ...receiver.verification,
              type: receiver.verification?.type === type ? null : type,
              password: "",
            },
          };
        else return { ...receiver };
      })
    );
  };

  return (
    <VerificationModalStyled>
      <div className="password">
        <div className="label">
          <Toggle
            isSelected={
              receivers.filter(
                (receiver) =>
                  receiver.id === verificationModal.selectedReceiverId
              )[0].verification?.type === "PASSWORD"
            }
            handleToggle={() => onToggleHandler("PASSWORD")}
          />
          <Body2Medium>암호 인증</Body2Medium>
        </div>
        <Input
          type="text"
          placeholder="암호 입력 (4~12자)"
          minLength={4}
          maxLength={12}
          value={
            receivers.filter(
              (receiver) => receiver.id === verificationModal.selectedReceiverId
            )[0].verification?.password || ""
          }
          setValue={(password: string) =>
            onChangeHandler(
              verificationModal.selectedReceiverId,
              "password",
              password
            )
          }
          isDisabled={
            receivers.filter(
              (receiver) => receiver.id === verificationModal.selectedReceiverId
            )[0].verification?.type !== "PASSWORD"
          }
        />
      </div>
      <div className="secure">
        <div className="label">
          <Toggle
            isSelected={
              receivers.filter(
                (receiver) =>
                  receiver.id === verificationModal.selectedReceiverId
              )[0].verification?.type === "PHONE"
            }
            handleToggle={() => {
              onToggleHandler("PHONE");
            }}
          />
          <Body2Medium>보안 인증</Body2Medium>
        </div>
        <div
          className={`message${
            receivers.filter(
              (receiver) => receiver.id === verificationModal.selectedReceiverId
            )[0].verification?.type === "PHONE"
              ? " selected"
              : ""
          }`}
        >
          <Body1SemiBold>휴대폰 본인인증</Body1SemiBold>
        </div>
      </div>
    </VerificationModalStyled>
  );
}

export const VerificationModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;

  & > div {
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;

    .label {
      display: flex;
      column-gap: 1.2rem;
    }

    .message {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      row-gap: 1.2rem;
      background-color: var(--bg-dark);
      color: var(--text-tertiary);
      border: 0.1rem solid transparent;
      border-radius: 0.5rem;

      &.selected {
        background-color: var(--bg-secondary);
        border-color: var(--stroke-secondary);
        color: var(--text-default);

        & > div:last-of-type {
          color: var(--text-secondary);
        }
      }
    }
  }
`;

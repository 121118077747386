import Button from "components/Common/Button/Button";
import {
  Body1Medium,
  Body1Regular,
  Body1SemiBold,
  Body2Medium,
} from "components/TextStyle";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as InformationIcon } from "assets/common/24px/icon_information_default.svg";
import Tooltip from "components/Common/Tooltip/Tooltip";
import useModal from "hooks/useModal";
import ActionModal from "components/Common/Modal/ActionModal";
import Input from "components/Common/Input/Input";
import useContractRemainCreditsQuery from "hooks/queries/contract/useContractRemainCreditsQuery";
import { useParams } from "react-router-dom";
import useUserState from "hooks/recoil/useUserState";
import saveFile from "utils/saveFile";
import useFileUpload from "hooks/useFileUpload";
import useTemplateDetailInfoQuery from "hooks/queries/template/useTemplateDetailInfoQuery";
import useTemplateDownloadExcelQuery from "hooks/queries/template/useTemplateDownloadExcelQuery";
import usePostTemplateUploadExcel from "hooks/mutations/template/usePostTemplateUploadExcel";
import { ReactComponent as DownIcon } from "assets/common/24px/icon_down_default.svg";
import { ReactComponent as UpIcon } from "assets/common/24px/icon_up.svg";
import MultipleItemList from "./MultipleItemList";
import { v4 as uuidv4 } from "uuid";
import { useFormContext } from "react-hook-form";

interface ReceiverProps {
  id: string;
  name: string;
  sequence?: number;
  email: string;
  phoneNumber: string;
  verification: {
    password: {
      isUsed: boolean;
      password: string;
    };
    phoneNumber: {
      isUsed: boolean;
    };
  };
}
const MultipleReceiverConfigForm = () => {
  const { openModal, closeModal, isOpen } = useModal();
  const { data: remainContractsCreditDto } = useContractRemainCreditsQuery();
  const remainCredits =
    remainContractsCreditDto?.remainContractCredit.remainCredits || 0;
  const { watch, setValue } = useFormContext();
  const receivers = watch("receivers");

  const handleUploadSuccess = (newReceivers: ReceiverProps[]) => {
    setValue("receivers", newReceivers);
  };

  return (
    <>
      {isOpen && (
        <MultipleReceiverSendModal
          closeModal={closeModal}
          onUploadSuccess={handleUploadSuccess}
        />
      )}
      <MultipleReceiverConfigFormLayout>
        <div className="top-container">
          <Body1SemiBold>수신자</Body1SemiBold>
          <div className="multiple-receiver-config-form-info">
            <Body1Medium>전송</Body1Medium>
            <LeftCase>{receivers?.length || 0}건</LeftCase>

            <hr />
            <Body1Medium>잔여 건수</Body1Medium>
            {remainCredits - (receivers?.length || 0) < 0 ? (
              <LeftCase style={{ color: "var(--red-500)" }}>
                {remainCredits - (receivers?.length || 0)}건
              </LeftCase>
            ) : (
              <LeftCase>{remainCredits - (receivers?.length || 0)}건</LeftCase>
            )}

            <Button colorType="tertiary" size="small" onClick={openModal}>
              일괄 등록
            </Button>
            <Tooltip description="파일을 업로드 하여 수신자 정보를 일괄 등록할 수 있습니다.">
              <InformationIcon />
            </Tooltip>
          </div>
        </div>
        {receivers?.length > 0 && (
          <>
            {receivers.map((receiver: ReceiverProps, index: number) => (
              <MultipleItemList
                key={index}
                id={index.toString()}
                receiver={receiver}
              />
            ))}
          </>
        )}
      </MultipleReceiverConfigFormLayout>
    </>
  );
};

export default MultipleReceiverConfigForm;

const MultipleReceiverConfigFormLayout = styled.div`
  display: flex;
  flex-direction: column;

  .top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    gap: 0.8rem;
  }

  margin-bottom: 4rem;
  .multiple-receiver-config-form-info {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    > hr {
      width: 0.1rem;
      height: 1.6rem;
      background-color: var(--stroke-light);
      border: none;
    }

    > button {
      margin-left: 1.6rem;
    }
  }
`;

const LeftCase = styled(Body1Medium)`
  color: var(--text-emphasis);
`;

const DescriptionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  ul {
    padding-left: 1.4rem;
    margin: 0;

    > li {
      list-style: outside;
      margin-bottom: 0.5rem;
    }
  }

  ol {
    padding-left: 1.8rem;
    margin: 0;

    > li {
      list-style: decimal;
      margin-bottom: 0.5rem;
    }
    li::marker {
      color: var(--text-default);
      font-size: 1.6rem;
    }
  }
`;

const FileLayout = styled.div`
  button {
    margin-bottom: 1.2rem;
  }

  .file-found-layout {
    display: flex;
    gap: 1.2rem;
    width: 100%;
    align-items: center;
    align-content: center;

    > div:first-child {
      flex: 1;
    }
    button {
      margin: 0;
      padding: 1.2rem 2.2rem;
    }
  }
`;

interface MultipleReceiverSendModalProps {
  closeModal: () => void;
  onUploadSuccess: (receivers: any[]) => void;
}

interface ErrorData {
  rowNum: number;
  errorMessage: string;
}

const ErrorAccordionLayout = styled.div`
  .error-accordion {
    margin-top: 1.2rem;
    border: 1px solid;
    border-radius: 0.5rem;
    margin-bottom: 0.8rem;
    overflow: hidden;

    .error-header {
      padding: 1.2rem 1.6rem;
      background-color: var(--background-tertiary);
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .dropdown-icon {
        transition: transform 0.2s ease-in-out;
      }
    }

    .error-content {
      padding: 1.2rem 1.6rem;
      border-top: 1px solid;
      color: var(--text-secondary);
    }
  }
`;

const groupErrorsByMessage = (errors: ErrorData[]) => {
  const grouped = new Map<string, number[]>();
  errors.forEach((error) => {
    if (!grouped.has(error.errorMessage)) {
      grouped.set(error.errorMessage, []);
    }
    grouped.get(error.errorMessage)?.push(error.rowNum);
  });
  return grouped;
};

const ErrorAccordion = ({
  message,
  rows,
}: {
  message: string;
  rows: number[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const hasRows = rows[0] !== 0;

  return (
    <div className="error-accordion">
      <div
        className="error-header"
        onClick={() => hasRows && setIsOpen(!isOpen)}
        style={{ cursor: hasRows ? "pointer" : "default" }}
      >
        <Body1Medium>{message}</Body1Medium>
        {hasRows && (isOpen ? <UpIcon /> : <DownIcon />)}
      </div>
      {isOpen && hasRows && (
        <div className="error-content">
          <Body1Regular>
            <div ref={dropdownRef}>
              오류 발생 행: {rows.sort((a, b) => a - b).join(", ")}행
            </div>
          </Body1Regular>
        </div>
      )}
    </div>
  );
};

const MultipleReceiverSendModal = ({
  closeModal,
  onUploadSuccess,
}: MultipleReceiverSendModalProps) => {
  const { templateId } = useParams();
  const [user] = useUserState();
  const [errorData, setErrorData] = useState<ErrorData[]>([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { data: templateData } = useTemplateDetailInfoQuery({
    organizationId: user.organization ?? "",
    templateId: templateId ?? "",
  });

  const templateNameWithFormat = `${
    templateData?.contractTemplateDetails?.templateName ?? "템플릿"
  }_포맷`;

  const { fileInputProps, inputFor, files } = useFileUpload({
    types: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
    ],
    maxFileCount: 1,
  });

  const { refetch: downloadExcel } = useTemplateDownloadExcelQuery({
    organizationUuid: user.organization ?? "",
    contractTemplateUuid: templateId ?? "",
    enabled: false,
  });

  const { mutateAsync: uploadExcel } = usePostTemplateUploadExcel({
    onSuccess: (data) => {
      console.log(
        "🚀 ~ file: MultipleReceiverConfigForm.tsx:305 ~ data:",
        data
      );
      const formattedReceivers = data.map((receiver: any) => ({
        id: uuidv4(),
        name: receiver.name,
        email: receiver.emailAddress,
        phoneNumber: receiver.phoneNumber,
        sequence: receiver.contactSequence,
        verification: {
          password: {
            isUsed: receiver.securityVerifyType === "PASSWORD",
            password: receiver.password || "",
          },
          phoneNumber: {
            isUsed: receiver.securityVerifyType === "PHONE",
          },
        },
      }));

      onUploadSuccess(formattedReceivers);
      closeModal();
    },
    onError: (error) => {
      const errorResult = error.response?.data.result;
      setErrorData(errorResult);
      setShowErrorModal(true);
    },
  });

  const handleUploadExcel = async () => {
    if (!files?.[0]) {
      console.log("파일이 없습니다.");
      closeModal();
      return;
    }

    try {
      const res = await uploadExcel({
        organizationUuid: user.organization ?? "",
        contractTemplateUuid: templateId ?? "",
        excelFile: files[0],
      });
      console.log(res);
    } catch (e) {
      console.log("업로드 실패");
    }
  };

  const handleDownloadFormat = async () => {
    try {
      const { data: res } = await downloadExcel();
      if (res) {
        const file = new File([res.data], `${templateNameWithFormat}.xlsx`, {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveFile(file);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <ActionModal
        widthFull
        title={`대량 전송 - ${
          templateData?.contractTemplateDetails?.templateName ?? "템플릿"
        }`}
        handleModalClose={closeModal}
        handleCancelButton={closeModal}
        handleConfirmButton={handleUploadExcel}
      >
        <DescriptionLayout>
          <Body1Regular>대량전송이란?</Body1Regular>
          <ul>
            <li>
              동일한 템플릿을 동시에 여러명에게 전송할 수 있습니다. <br />
              (1회 최대 500명)
            </li>
            <li>
              대량전송 엑셀 포맷을 이용하면 템플릿 일부 필드값을 수신자별로
              <br />
              다르게 입력하여 전송할 수 있습니다.
            </li>
            <li>
              수신자 정보를 수정할 경우, 대량전송의 업로드한 파일을 <br />
              수정해야 합니다.
            </li>
          </ul>
        </DescriptionLayout>
        <DescriptionLayout>
          <Body1Regular>이용방법</Body1Regular>
          <ol start={1}>
            <li>
              [포맷 다운로드]를 클릭하여 대량 전송용 엑셀 파일을 다운로드
              <br />
              받습니다.
            </li>
            <li>
              수신자 정보 및 템플릿에 설정된 필드에 미리 작성할 내용을 <br />
              엑셀 파일에 입력 후 저장합니다.
            </li>
            <li>[파일찾기]를 클릭하여 저장한 엑셀 파일을 업로드합니다.</li>
            <li>업로드한 내용이 화면의 수신자 목록에 표시됩니다.</li>
            <li>
              [미리보기] 버튼으로 각 수신자별 입력값이 제대로 반영되었는지
              <br />
              확인 후 전송합니다.
            </li>
          </ol>
        </DescriptionLayout>
        <FileLayout>
          <Button
            colorType="tertiary"
            size="medium"
            onClick={handleDownloadFormat}
          >
            <Body2Medium>포맷 다운로드</Body2Medium>
          </Button>

          <div className="file-found-layout">
            <Input
              readOnly
              value={files?.[0]?.name ?? ""}
              placeholder="파일을 등록하세요."
            />
            <Button colorType="tertiary" size="large" {...inputFor}>
              <Body1SemiBold>파일찾기</Body1SemiBold>
            </Button>
            <Input
              type="file"
              style={{
                display: "none",
                width: "0",
              }}
              {...fileInputProps}
            />
          </div>
        </FileLayout>
      </ActionModal>

      {showErrorModal && (
        <ActionModal
          title="엑셀 포맷 오류"
          handleModalClose={() => setShowErrorModal(false)}
          handleConfirmButton={() => setShowErrorModal(false)}
          confirmText="확인"
        >
          <DescriptionLayout>
            <Body1Regular>
              데이터에 오류가 있습니다.
              <br />
              아래의 오류를 확인하고 수정해주세요.
              <br />
              수정한 파일을 다시 업로드해 주세요.
            </Body1Regular>
            <ErrorAccordionLayout>
              {Array.from(groupErrorsByMessage(errorData)).map(
                ([message, rows]) => (
                  <ErrorAccordion key={message} message={message} rows={rows} />
                )
              )}
            </ErrorAccordionLayout>
          </DescriptionLayout>
        </ActionModal>
      )}
    </>
  );
};

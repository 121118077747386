import React from "react";
import { Label } from "../CreateContractRight";
import Tooltip from "components/Common/Tooltip";
import { Body3Regular } from "components/TextStyle";
import CheckBox from "components/Common/CheckBox/CheckBox";
import { ReactComponent as InfoIcon } from "assets/common/20px/icon_information_small.svg";
import { OptionProps } from "interfaces/contract";

export default function CopyOption({
  template,
  selectedField,
  fields,
  setPages,
  selectedPageId,
  selectedFieldId,
}: OptionProps) {
  return selectedField?.copied ||
    fields.filter(
      (field) =>
        field.fieldType === selectedField?.fieldType &&
        `${field.fieldType}:${field.id}` !== selectedFieldId &&
        (template
          ? field.writer?.label === selectedField.writer?.label
          : field.writer?.name === selectedField?.writer?.name) &&
        (field.copy?.length as number) === 0 &&
        (!field.copied ||
          (field.copied && selectedField.copy?.includes(field.id)))
    )?.length === 0 ? (
    <></>
  ) : (
    <>
      <Label>
        내용 복사
        <Tooltip side="top" trigger={<InfoIcon />}>
          <Body3Regular>복사할 입력란을 선택</Body3Regular>
        </Tooltip>
      </Label>
      <div className="copyOptions">
        {fields
          .filter(
            (field) =>
              field.fieldType === selectedField?.fieldType &&
              `${field.fieldType}:${field.id}` !== selectedFieldId &&
              (template
                ? field.writer?.label === selectedField.writer?.label
                : field.writer?.name === selectedField?.writer?.name) &&
              (field.copy?.length as number) === 0 &&
              (!field.copied ||
                (field.copied && selectedField.copy?.includes(field.id)))
          )
          .map((el) => (
            <div key={el.id} className="checkbox">
              <CheckBox
                checked={selectedField?.copy?.includes(el.id)}
                onChange={(e) => {
                  setPages((pages) =>
                    pages.map((page) => {
                      if (page.id === selectedPageId) {
                        return {
                          ...page,
                          fields: page?.fields?.map((field) => {
                            if (
                              `${field.fieldType}:${field.id}` ===
                              selectedFieldId
                            )
                              return {
                                ...field,
                                copy: field.copy?.includes(el.id)
                                  ? field.copy?.filter((id) => id !== el.id)
                                  : field.copy?.concat(el.id),
                              };
                            else if (field.id === el.id) {
                              return {
                                ...field,
                                copied: e.target.checked
                                  ? selectedField?.id || ""
                                  : "",
                              };
                            } else return { ...field };
                          }),
                        };
                      } else if (
                        page.fields.filter((field) => field.id === el.id)
                          ?.length > 0
                      )
                        return {
                          ...page,
                          fields: page.fields.map((field) => {
                            if (field.id === el.id) {
                              return {
                                ...field,
                                copied: e.target.checked
                                  ? selectedField?.id
                                  : "",
                              };
                            } else return { ...field };
                          }),
                        };
                      else return { ...page };
                    })
                  );
                }}
              />
              {el.name}
            </div>
          ))}
      </div>
    </>
  );
}

import { Notification } from "models/Notification";

export const NOTIFICATION_TYPE_MAP = {
  INVITE_SUCCESS: {
    text: "회원가입",
  },
  INVITE_EXPIRED: {
    text: "초대 만료",
  },
  ORG_MEMBER_WITHDRAW: {
    text: "조직 탈퇴",
  },
  SEND_CONTRACT: {
    text: "서명 요청",
  },
  CONTRACT_RECEIVE: {
    text: "서명 요청",
  },
  SIGN_START: {
    text: "작성 시작",
  },
  SIGN_COMPLETE: {
    text: "서명 완료",
  },
  CONTRACT_COMPLETE: {
    text: "서명 완료",
  },
  CONTRACT_REFUSE: {
    text: "작성 거절",
  },
  CONTRACT_CANCEL: {
    text: "서명 요청 취소",
  },
  DIRECT_URL_CONFIRM_REQ: {
    text: "승인 대기",
  },
  DIRECT_URL_CONFIRM_REFUSE: {
    text: "승인 거절",
  },
  DIRECT_URL_CONFIRM_ALLOW: {
    text: "승인 완료",
  },
  PAYMENT_COMPLETE: {
    text: "결제 완료",
  },
  PAYMENT_FAIL: {
    text: "결제 실패",
  },
  PAYMENT_CANCEL: {
    text: "결제 취소",
  },
  UNSUBSCRIPTION: {
    text: "구독 해지",
  },
};

export const getNotificationLink = (
  type: Notification["type"],
  contractClass: "NORMAL" | "GANIN" | "DIRECT_URL"
): { link?: string; state?: { type: string } } => {
  // 회원가입
  if (type === "INVITE_SUCCESS") {
    return { link: "/setting/member", state: { type: "REGISTERED" } };
  }
  // 초대 만료
  if (type === "INVITE_EXPIRED") {
    return { link: "/setting/member", state: { type: "INVITED" } };
  }
  // 조직 탈퇴
  if (type === "ORG_MEMBER_WITHDRAW") {
    return { link: "/setting/member", state: { type: "WITHDRAWN" } };
  }

  // 서명 요청
  if (type === "SEND_CONTRACT") {
    return { link: "/contract/dashboard/normal" };
  }

  // 서명 요청
  if (type === "CONTRACT_RECEIVE") {
    return { link: "/contract/dashboard/normal" };
  }

  // 작성 시작
  if (type === "SIGN_START") {
    if (contractClass === "NORMAL") {
      return { link: "/contract/dashboard/normal" };
    }
    if (contractClass === "DIRECT_URL") {
      return { link: "/contract/dashboard/direct-url" };
    }
  }
  // 서명 완료
  if (type === "SIGN_COMPLETE") {
    if (contractClass === "NORMAL") {
      return { link: "/contract/dashboard/normal" };
    }
    if (contractClass === "DIRECT_URL") {
      return { link: "/contract/dashboard/direct-url" };
    }
  }
  // 서명 완료 (다자간 계약에서 모든 참여자 서명 시)
  if (type === "CONTRACT_COMPLETE") {
    if (contractClass === "NORMAL") {
      return { link: "/contract/dashboard/normal" };
    }
    if (contractClass === "DIRECT_URL") {
      return { link: "/contract/dashboard/direct-url" };
    }
  }
  // 작성 거절
  if (type === "CONTRACT_REFUSE") {
    return { link: "/contract/dashboard/normal" };
  }
  // 서명 요청 취소
  if (type === "CONTRACT_CANCEL") {
    return { link: "/contract/dashboard/normal" };
  }
  // 승인 대기
  if (type === "DIRECT_URL_CONFIRM_REQ") {
    return { link: "/contract/dashboard/direct-url" };
  }
  // 승인 취소
  if (type === "DIRECT_URL_CONFIRM_REFUSE") {
    return { link: "/contract/dashboard/direct-url" };
  }
  // 승인 완료
  if (type === "DIRECT_URL_CONFIRM_ALLOW") {
    return { link: "/contract/dashboard/direct-url" };
  }
  // 결제 완료
  if (type === "PAYMENT_COMPLETE") {
    return { link: "" };
  }
  // 결제 실패
  if (type === "PAYMENT_FAIL") {
    return { link: "" };
  }
  // 결제 취소
  if (type === "PAYMENT_CANCEL") {
    return { link: "" };
  }
  // 구독 해지
  if (type === "UNSUBSCRIPTION") {
    return { link: "" };
  }
  return { link: "" };
};

import React from "react";
import { registerLocale } from "react-datepicker";
import { ko } from "date-fns/locale/ko";
import DatePicker from "./DatePicker";

registerLocale("ko", ko);

interface DatePickerModalProps {
  selected: Date | null;
  onChangeHandler: (date: Date | null) => void;
  onSelectHandler: (date: Date | null) => void;
  placeholder?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  isMobile?: boolean;
}

export default function DatePickerModal({
  selected,
  onChangeHandler,
  onSelectHandler,
  placeholder = "",
  disabled,
  minDate,
  maxDate,
  isMobile,
}: DatePickerModalProps) {
  return (
    <DatePicker
      className="datepicker"
      isMobile={isMobile}
      minDate={minDate || new Date()}
      maxDate={maxDate || new Date(2099, 11, 31)}
      selected={selected}
      onChange={onChangeHandler}
      onSelect={onSelectHandler}
      placeholderText={disabled ? "" : placeholder || ""}
      disabled={disabled}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      inputProps={{
        isIcon: false,
      }}
    />
  );
}

import React from "react";
import { Body1Medium } from "components/TextStyle";
import Button from "components/Common/Button/Button";
import styled from "styled-components";

interface ReceiverProps {
  id: string;
  name: string;
  sequence?: number;
  email: string;
  phoneNumber: string;
  verification: {
    password: {
      isUsed: boolean;
      password: string;
    };
    phoneNumber: {
      isUsed: boolean;
    };
  };
}

interface MultipleItemListProps {
  id: string;
  receiver: ReceiverProps;
}

export function MultipleItemList({ id, receiver }: MultipleItemListProps) {
  return (
    <StyledSortableItem key={id}>
      <div>
        <InputContainer $width="14rem">
          <Body1Medium>{receiver.name}</Body1Medium>
        </InputContainer>
        <InputContainer $width="28rem">
          <Body1Medium>{receiver.email} </Body1Medium>
        </InputContainer>
        <InputContainer $width="24rem">
          <Body1Medium>{receiver.phoneNumber}</Body1Medium>
        </InputContainer>
        <div style={{ marginLeft: "2.4rem" }}>
          <Body1Medium>
            {receiver.verification.password.isUsed ? "암호 " : "개별 "}
            인증:{" "}
          </Body1Medium>

          <Button colorType="tertiary" size="small" disabled>
            {receiver.verification.password.isUsed ? "변경하기" : "설정하기"}
          </Button>
        </div>
      </div>
    </StyledSortableItem>
  );
}

const InputContainer = styled.div<{ $width: string }>`
  width: ${(props) => props.$width};
  border: 1px solid var(--stroke-light);
  border-radius: 0.5rem;
  padding: 1.2rem 1.4rem;
  display: flex;
  color: var(--text-disabled);
  overflow-y: auto;
  white-space: nowrap;
`;

const StyledSortableItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
  align-items: center;
  margin-bottom: 1rem;

  > div {
    display: flex;
    gap: 0.8rem;
    align-items: center;
  }

  > div > div {
    display: flex;
    gap: 1.2rem;
    align-items: center;
  }

  input {
    margin-top: 0;
    margin-left: 0;
  }
`;

export default MultipleItemList;

import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ActionModal from "components/Common/Modal/ActionModal";
import TextArea from "components/Common/Input/TextArea";
import useReviewDenyMutation from "hooks/mutations/directURL/useReviewDenyMutation";
interface ReviewRejectModalProps {
  onClose: () => void;
}

export default function ReviewRejectModal({ onClose }: ReviewRejectModalProps) {
  const navigate = useNavigate();
  const { mutate: reviewDeny } = useReviewDenyMutation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<{ reason: string }>();

  const jwt =
    window.sessionStorage.getItem("jwt") || window.localStorage.getItem("jwt");

  const refreshJwt =
    window.sessionStorage.getItem("refreshJwt") ||
    window.localStorage.getItem("refreshJwt");

  const handleConfirm = () => {
    handleSubmit((data) => {
      reviewDeny(
        { reason: data.reason },
        {
          onSuccess: () => {
            toast("거절되었습니다.");

            if (jwt || refreshJwt) {
              navigate("/contract/dashboard/direct-url");
            } else {
              navigate("/auth/sign-in", {
                state: {
                  isReview: true,
                },
              });
            }
          },
          onError: () => {
            toast("거절에 실패했습니다.");
          },
        }
      );
    })();
  };

  return (
    <ActionModal
      title="거절하기"
      handleModalClose={onClose}
      handleCancelButton={onClose}
      handleConfirmButton={handleConfirm}
    >
      <TextArea
        maxLength={100}
        placeholder="거절 사유는 승인 요청자에게 전송됩니다."
        {...register("reason", {
          required: true,
        })}
        isError={errors.reason ? true : false}
        _style={{
          height: "14rem",
        }}
      />
    </ActionModal>
  );
}

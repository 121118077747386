import { Field } from "pages/contract/send";
import styled from "styled-components";

export const DraggableStyled = styled.div<{
  scale?: number;
  type?: "sender" | "receiver";
  field?: Field;
  color: string;
  required: boolean;
  align?: "LEFT" | "CENTER" | "RIGHT";
  fontSize?: string;
  complete?: boolean;
  newField?: boolean;
  isMobile?: boolean;
}>`
  position: absolute;
  inset: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;
  min-width: ${({ newField }) => (newField ? "100%" : "unset")};
  width: max-content;
  height: max-content;
  opacity: 0;

  &.dragging {
    opacity: 0.01;

    .options {
      display: none;
    }
  }

  .wrapper {
    display: flex;
    column-gap: 0.4rem;
    color: var(--icon-default);

    & > svg {
      cursor: pointer;
    }

    .field-container {
      position: relative;
    }

    .container {
      width: ${({ field, scale }) =>
        scale
          ? `${
              scale * (field === "IMAGE" ? 24 : field === "CHECKBOX" ? 4.4 : 16)
            }rem`
          : `${field === "IMAGE" ? 24 : field === "CHECKBOX" ? 4.4 : 16}rem`};
      height: ${({ field, scale }) =>
        scale
          ? `${
              scale *
              (field === "IMAGE" ? 14 : field === "CHECKBOX" ? 4.4 : 4.4)
            }rem`
          : `${field === "IMAGE" ? 14 : field === "CHECKBOX" ? 4.4 : 4.4}rem`};
      background-color: ${({ color, complete }) =>
        complete ? `transparent` : `${color}4D`};
      border: ${({ scale, type, required, complete }) =>
        scale
          ? `${scale * 0.2}rem solid ${
              complete
                ? "transparent"
                : type === "sender"
                ? "var(--icon-secondary)"
                : required
                ? `#FF0000`
                : `var(--blue-500)`
            }`
          : `0.2rem solid ${
              complete
                ? "transparent"
                : type === "sender"
                ? "var(--icon-secondary)"
                : required
                ? `#FF0000`
                : `var(--blue-500)`
            }`};
      align-items: center;

      input[type="text"],
      input[type="file"],
      textarea {
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        background-color: transparent;
        font-size: ${({ scale, fontSize }) =>
          scale
            ? `${scale * (fontSize ? +fontSize / 10 : 1.4)}rem`
            : `${fontSize ? +fontSize / 10 : 1.4}rem`};
        color: var(--text-default);
        text-align: ${({ align }) => align || "unset"};

        &::placeholder {
          color: var(--text-secondary);
        }
      }

      textarea {
        resize: none;
      }

      & > svg {
        min-width: max-content;
        max-height: 100%;
        width: ${({ scale }) => (scale ? `${scale * 2}rem` : "2rem")};
        height: ${({ scale }) => (scale ? `${scale * 2}rem` : "2rem")};
      }

      & > div {
        font-size: ${({ scale }) => (scale ? `${scale * 1.4}rem` : "1.4rem")};
        line-height: ${({ scale }) => (scale ? `${scale * 2}rem` : "2rem")};
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        display: --webkit-box;
        line-clamp: 1;

        &.select {
          overflow: unset;
        }
      }
    }
  }

  .options {
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
    background-color: white;
    border-radius: 1.2rem;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    width: max-content;

    .option {
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 1.2rem;

      .alignOptions {
        svg {
          cursor: pointer;
        }
      }
    }
  }
`;

import { PALETTE, SCALES } from "config/constant";
import useTemplateDetailsById from "hooks/queries/template/useTemplateDetailsById";
import useUserState from "hooks/recoil/useUserState";
import { IPage, IParticipant } from "interfaces/contract";
import { Field, FIELDS } from "pages/contract/send";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Document, Page, pdfjs } from "react-pdf";
import Item from "components/Draggable/Item";
import { PageContainer } from "pages/contract/send/detail/editor";
import { Body1Medium, Body1Regular, Body2Medium } from "components/TextStyle";
import Dropdown from "components/Common/Dropdown/Dropdown";
import {
  ScaleControllerStyled,
  ToolbarStyled,
} from "components/Toolbar/styles";
import { PreviewStyled } from "pages/contract/send/styles";
import { TextAlign } from "models/Contract";
import Button from "components/Common/Button/Button";
import useModal from "hooks/useModal";
import ActionModal from "components/Common/Modal/ActionModal";
import usePostTemplateCopy from "hooks/mutations/template/usePostTemplateCopy";
import useContractDetail from "hooks/queries/contract/useContractDetail";
import styled from "styled-components";
import useOrganizationListQuery from "hooks/queries/organization/useOrganizationListQuery";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

interface ErrorResponse {
  message: string;
}

const initialParticipants: IParticipant[] = [
  {
    id: uuidv4(),
    label: "발신자",
    type: "sender",
    color: "#666F7B",
  },
  ...Array(10)
    .fill(0)
    .map(
      (_, idx) =>
        ({
          id: uuidv4(),
          sequence: idx,
          color: PALETTE.receivers[idx],
          label: `수신자${idx === 0 ? "" : idx + 1}`,
          type: "receiver",
        } as IParticipant)
    ),
];

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
export default function ViewDocuments() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [participants] = useState(initialParticipants);
  const [scale, setScale] = useState("100%");
  const [pdf, setPdf] = useState<File | string | null>(null);
  const [pages, setPages] = useState<IPage[]>([]);
  const [selectedPageId, setSelectedPageId] = useState("");
  const [, setSelectedFieldType] = useState<Field>(null);
  const [selectedFieldId, setSelectedFieldId] = useState("");
  const pagesContainerRef = useRef<null[] | HTMLDivElement[]>([]);

  const selectField = (id: string, type: Field) => {
    if (
      pages
        ?.find((page) => page.id === selectedPageId)
        ?.fields.findIndex(
          (field) => `${field.fieldType}:${field.id}` === id
        ) !== -1
    ) {
      setSelectedFieldId(id);
      setSelectedFieldType(type);
    }
  };
  const { isOpen, closeModal, openModal } = useModal();

  const [user] = useUserState();

  const { data: templateDetail } = useTemplateDetailsById({
    organizationUuid: user?.organization,
    templateUuid: state?.templateId,
  });

  const { data: contractDetail } = useContractDetail(state?.contractId);

  const { data: organizationsList } = useOrganizationListQuery();

  useEffect(() => {
    if (contractDetail?.contractDetail.contractFileUrl) {
      setPdf(contractDetail?.contractDetail.contractFileUrl);
    }
    if (templateDetail?.contractTemplateDetail.templateFile) {
      setPdf(templateDetail?.contractTemplateDetail.templateFile);
    }
  }, [contractDetail, templateDetail]);

  const { mutate } = usePostTemplateCopy();
  const scrollToPage = (pageId: string) => {
    document.getElementById(pageId)?.scrollIntoView({ behavior: "smooth" });
  };

  const handleDownloadPdf = async () => {
    mutate(
      {
        organizationUuid: user?.organization,
        templateUuid: state?.templateId,
      },
      {
        onSuccess: (result) =>
          navigate(`/template/create/upload?id=${result.templateUuid}`),
        onError: (error) => {
          const err = error as AxiosError<ErrorResponse>;
          if (err.response?.status === 400) {
            toast(err.response?.data?.message);
          } else {
            toast("사본을 생성할 수 없습니다.");
          }
          closeModal();
          navigate(`/template/dashboard`);
        },
      }
    );
  };
  const onClickEdit = () => {
    openModal();
  };

  return (
    <>
      <Top />
      <ToolbarStyled>
        <div className="left">
          <Body1Medium onClick={() => navigate(-1)}>이전</Body1Medium>
        </div>
        <div className="right">
          <Button
            colorType="primary"
            size="medium"
            handleClick={onClickEdit}
            disabled={
              organizationsList.organizationList.find(
                (o) => o.organizationUuid === user.organization
              )?.memberPermission === "USER"
            }
          >
            <Body2Medium>수정</Body2Medium>
          </Button>
        </div>
      </ToolbarStyled>
      <ScaleControllerStyled>
        <Dropdown
          table
          selected={scale}
          setSelected={setScale}
          dropdownList={SCALES.map((scale) => ({
            label: `${scale}%`,
            value: `${scale}%`,
          }))}
          backgroundDimmedWhenOpened
        />
      </ScaleControllerStyled>
      <PreviewStyled>
        <Document
          className="pdf"
          file={pdf}
          noData={<></>}
          onLoadSuccess={(doc) => {
            setPages(() => {
              const firstPageId = uuidv4();
              setSelectedPageId(firstPageId);

              if (state?.data?.contractClass === "GANIN") {
                return Array(doc.numPages)
                  .fill(0)
                  .map((_, idx) => ({
                    id: idx === 0 ? firstPageId : uuidv4(),
                    order: idx + 1,
                    fields:
                      idx % 2 === 1
                        ? []
                        : templateDetail?.contractTemplateDetail
                            .contractTemplateInputList
                        ? templateDetail?.contractTemplateDetail.contractTemplateInputList
                            .filter((input) => input.page === idx + 1)
                            .map((input) => ({
                              fieldType: input.inputType as Field,
                              id: input.uuid as string,
                              width: input.width as number,
                              height: input.height as number,
                              writer: participants[input.receiverSequence],
                              position: {
                                x: input.x as number,
                                y: input.y as number,
                              },
                              type:
                                input.receiverSequence === 0
                                  ? "sender"
                                  : "receiver",
                              name: input.inputName || "",
                              required: input.required || false,
                              description: input.description || "",
                              size: `${input.textSize as number}`,
                              align: input.textAlign as TextAlign,
                              commas: input.isCommaText,
                              autoDate: input.isTodayDate,
                              contents: input.dropDownItems
                                ?.map((item) => item.value)
                                .join(", "),
                              buttonType:
                                input.checkBoxItems || input.radioBtnItems
                                  ? input.radioBtnItems &&
                                    input.radioBtnItems?.length > 0
                                    ? "radio"
                                    : "checkbox"
                                  : undefined,
                              buttonsCount:
                                input.checkBoxItems || input.radioBtnItems
                                  ? input.radioBtnItems &&
                                    input.radioBtnItems?.length > 0
                                    ? input.radioBtnItems.length
                                    : input.checkBoxItems
                                    ? input.checkBoxItems.length
                                    : undefined
                                  : undefined,
                              selectCount: {
                                number: input.numOfConcurrentSelect,
                                mode: input.typeOfConcurrentSelect,
                                options:
                                  input.checkBoxItems || input.radioBtnItems
                                    ? input.radioBtnItems &&
                                      input.radioBtnItems?.length > 0
                                      ? input.radioBtnItems.map((item) => ({
                                          id: item.uuid,
                                          label: item.name || "",
                                          width: 44,
                                          height: 44,
                                          checked: false,
                                          position: {
                                            x: item.x,
                                            y: item.y,
                                          },
                                        }))
                                      : input.checkBoxItems
                                      ? input.checkBoxItems.map((item) => ({
                                          id: item.uuid,
                                          label: item.name || "",
                                          width: 44,
                                          height: 44,
                                          checked: false,
                                          position: {
                                            x: item.x,
                                            y: item.y,
                                          },
                                        }))
                                      : undefined
                                    : undefined,
                              },
                            }))
                        : [],
                  }));
              } else {
                return Array(doc.numPages)
                  .fill(0)
                  .map((_, idx) => ({
                    id: idx === 0 ? firstPageId : uuidv4(),
                    order: idx + 1,
                    fields: templateDetail?.contractTemplateDetail
                      .contractTemplateInputList
                      ? templateDetail?.contractTemplateDetail.contractTemplateInputList
                          .filter((input) => input.page === idx + 1)
                          .map((input) => ({
                            fieldType: input.inputType as Field,
                            id: input.uuid as string,
                            width: input.width as number,
                            height: input.height as number,
                            writer: participants[input.receiverSequence],
                            position: {
                              x: input.x as number,
                              y: input.y as number,
                            },
                            type:
                              input.receiverSequence === 0
                                ? "sender"
                                : "receiver",
                            name: input.inputName || "",
                            required: input.required || false,
                            description: input.description || "",
                            size: `${input.textSize as number}`,
                            align: input.textAlign as TextAlign,
                            commas: input.isCommaText,
                            autoDate: input.isTodayDate,
                            contents: input.dropDownItems
                              ?.map((item) => item.value)
                              .join(", "),
                            buttonType:
                              input.checkBoxItems || input.radioBtnItems
                                ? input.radioBtnItems &&
                                  input.radioBtnItems?.length > 0
                                  ? "radio"
                                  : "checkbox"
                                : undefined,
                            buttonsCount:
                              input.checkBoxItems || input.radioBtnItems
                                ? input.radioBtnItems &&
                                  input.radioBtnItems?.length > 0
                                  ? input.radioBtnItems.length
                                  : input.checkBoxItems
                                  ? input.checkBoxItems.length
                                  : undefined
                                : undefined,
                            selectCount: {
                              number: input.numOfConcurrentSelect,
                              mode: input.typeOfConcurrentSelect,
                              options:
                                input.checkBoxItems || input.radioBtnItems
                                  ? input.radioBtnItems &&
                                    input.radioBtnItems?.length > 0
                                    ? input.radioBtnItems.map((item) => ({
                                        id: item.uuid,
                                        label: item.name || "",
                                        width: 44,
                                        height: 44,
                                        checked: false,
                                        position: {
                                          x: item.x,
                                          y: item.y,
                                        },
                                      }))
                                    : input.checkBoxItems
                                    ? input.checkBoxItems.map((item) => ({
                                        id: item.uuid,
                                        label: item.name || "",
                                        width: 44,
                                        height: 44,
                                        checked: false,
                                        position: {
                                          x: item.x,
                                          y: item.y,
                                        },
                                      }))
                                    : undefined
                                  : undefined,
                            },
                          }))
                      : [],
                  }));
              }
            });
          }}
        >
          <div className="left">
            <div className="pages">
              {pages.map((page) => (
                <div
                  className={`page${
                    selectedPageId === page.id ? " current" : ""
                  }`}
                  key={page.id}
                  onClick={() => {
                    setSelectedPageId(page.id);
                    scrollToPage(page.id);
                  }}
                >
                  <div className="label">
                    <Body1Regular>{page.order}</Body1Regular>
                  </div>
                  <Page
                    className="thumbnail"
                    height={140}
                    pageNumber={page.order}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="main">
            <div className="pdf-container">
              {pages.map((page, order) => (
                <PageContainer
                  key={page.id}
                  id={page.id}
                  order={order}
                  setSelectedPageId={setSelectedPageId}
                >
                  <Page
                    className="page"
                    pageNumber={page.order}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    scale={+scale.replace("%", "") / 50}
                  />
                  <div
                    className={`canvas:${page.id} canvas`}
                    onClick={() =>
                      setSelectedPageId && setSelectedPageId(page.id)
                    }
                    ref={(el) => {
                      if (!pagesContainerRef.current) return;

                      pagesContainerRef.current[order] = el;
                    }}
                  >
                    {page?.fields.map(
                      ({
                        id,
                        type,
                        fieldType,
                        selectCount,
                        position: { x, y },
                      }) =>
                        fieldType === "CHECKBOX" ? (
                          selectCount?.options?.map((option) => (
                            <Item
                              edit={type === "sender"}
                              preview
                              field={
                                FIELDS.find(
                                  (field) => field.value === fieldType
                                ) || FIELDS[0]
                              }
                              id={id}
                              optionId={option.id}
                              key={id}
                              pages={pages}
                              setPages={setPages}
                              type={type}
                              setSelectedFieldType={setSelectedFieldType}
                              selectedFieldId={selectedFieldId}
                              setSelectedFieldId={setSelectedFieldId}
                              selectedPageId={selectedPageId}
                              selectField={selectField}
                              scale={+scale.replace("%", "") / 100}
                              style={{
                                width: `max-content`,
                                height: `max-content`,
                                position: "absolute",
                                left:
                                  option.position.x *
                                  (+scale.replace("%", "") / 100),
                                top:
                                  option.position.y *
                                  (+scale.replace("%", "") / 100),
                                zIndex:
                                  selectedFieldId === `${fieldType}:${id}`
                                    ? 5
                                    : 4,
                                opacity: 1,
                              }}
                            />
                          ))
                        ) : (
                          <Item
                            edit={type === "sender"}
                            preview
                            templateSend
                            field={
                              FIELDS.find(
                                (field) => field.value === fieldType
                              ) || FIELDS[0]
                            }
                            id={id}
                            key={id}
                            pages={pages}
                            setPages={setPages}
                            type={type}
                            setSelectedFieldType={setSelectedFieldType}
                            selectedFieldId={selectedFieldId}
                            setSelectedFieldId={setSelectedFieldId}
                            selectedPageId={selectedPageId}
                            selectField={selectField}
                            scale={+scale.replace("%", "") / 100}
                            style={{
                              width: `max-content`,
                              height: `max-content`,
                              position: "absolute",
                              left: x * (+scale.replace("%", "") / 100),
                              top: y * (+scale.replace("%", "") / 100),
                              zIndex:
                                selectedFieldId === `${fieldType}:${id}`
                                  ? 5
                                  : 4,
                              opacity: 1,
                            }}
                          />
                        )
                    )}
                  </div>
                </PageContainer>
              ))}
            </div>
          </div>
        </Document>
      </PreviewStyled>
      {isOpen && (
        <ActionModal
          title="사본 생성"
          handleConfirmButton={handleDownloadPdf}
          handleCancelButton={closeModal}
        >
          <Body1Regular>
            수정하려면 사본을 생성해야 합니다. <br />
            사본 생성 후 수정하시겠습니까?
          </Body1Regular>
        </ActionModal>
      )}
    </>
  );
}

const Top = styled.div`
  width: 100%;
  height: 6.8rem;
  border-bottom: 0.1rem solid var(--stroke-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

import NoticeNavigation from "components/Notice/NoticeNavigation";
import { Board } from "pages/dashboard/style";
import React from "react";
import styled from "styled-components";
import { ReactComponent as RightArrowIcon } from "assets/common/24px/icon_right_default.svg";
import { Body3Regular } from "components/TextStyle";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const GUIDE_LIST = [
  {
    subTitle: "처음 오셨나요?",
    title: "01. 퀵 가이드 다운로드",
    link: "https://eform-s3-public-static-files-bucket.s3.ap-northeast-2.amazonaws.com/guide/%5B%E1%84%8B%E1%85%B5%E1%84%91%E1%85%A9%E1%86%B7%5D+%E1%84%8F%E1%85%B1%E1%86%A8%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3.pdf",
  },

  {
    subTitle: "및춤형 서비스를 찾으시나요?",
    title: "02. 브랜딩 서비스 가이드 다운로드",
    link: "https://eform-s3-public-static-files-bucket.s3.ap-northeast-2.amazonaws.com/guide/%5B%E1%84%8B%E1%85%B5%E1%84%91%E1%85%A9%E1%86%B7%5D+%E1%84%87%E1%85%B3%E1%84%85%E1%85%A2%E1%86%AB%E1%84%83%E1%85%B5%E1%86%BC+%E1%84%89%E1%85%A5%E1%84%87%E1%85%B5%E1%84%89%E1%85%B3+%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3.pdf",
  },
];

const NoticeGuidePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <NoticeNavigation />
      <NoticeGuideBoard>
        <NoticeGuideLayout>
          <GuideListLayout>
            {GUIDE_LIST.map((guide) => {
              return (
                <div key={guide.link}>
                  <GuideListItem>
                    <h4>{guide.subTitle}</h4>
                    <Link
                      to={guide.link}
                      target="_blank"
                      className="guide-list-title"
                    >
                      <h2>{guide.title}</h2>
                      <RightArrowIcon />
                    </Link>
                  </GuideListItem>
                  <hr />
                </div>
              );
            })}
            <div
              className="add-more-guide"
              onClick={() => {
                navigate("/setting/support");
              }}
            >
              <Body3Regular>더 많은 도움이 필요하신가요</Body3Regular>
              <RightArrowIcon />
            </div>
          </GuideListLayout>
        </NoticeGuideLayout>
      </NoticeGuideBoard>
    </>
  );
};

export default NoticeGuidePage;

const NoticeGuideBoard = styled(Board)`
  width: 100%;
  padding: 10rem 0;
`;

const NoticeGuideLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 11rem;
  margin: auto;
  padding: 0 24rem;
`;

const GuideListLayout = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5.4rem;

  .guide-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
  }

  .add-more-guide {
    display: flex;
    align-items: center;
    color: var(--text-secondary);
    svg {
      width: 2rem;
      height: 2rem;
    }
    cursor: pointer;
  }

  hr {
    width: 100%;
    height: 1px;
    background-color: var(--stroke-light);
    border: none;
    margin: 0;
  }

  > hr:last-child {
    display: none;
  }
`;

const GuideListItem = styled.li`
  margin-bottom: 5.4rem;
  width: 56rem;
  h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.54px;

    margin: 0 0 1.2rem 0;

    color: var(--text-secondary);
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px; /* 136.364% */
    letter-spacing: -0.66px;

    margin: 0;
  }
`;

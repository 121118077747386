import React, { ChangeEvent } from "react";
import Pagination, { PaginationProps } from "@mui/material/Pagination";
import { StyledEngineProvider } from "@mui/styled-engine";
import styled from "styled-components";

interface PagingProps extends PaginationProps {
  count: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  handlePage?: (newPage: number) => void;
}

const Paging = ({
  count,
  currentPage,
  setCurrentPage,
  handlePage,
}: PagingProps) => {
  const handleChangePage = (_: ChangeEvent<unknown>, newPage: number) => {
    handlePage && handlePage(newPage);
    setCurrentPage(newPage);
  };
  return (
    <StyledEngineProvider>
      <CustomPagination
        count={count}
        page={currentPage}
        onChange={handleChangePage}
      />
    </StyledEngineProvider>
  );
};

export default Paging;

const CustomPagination = styled(Pagination)`
  .MuiPaginationItem-text {
    background-color: transparent !important;
    width: 3rem;
    height: 3rem;
    margin: 0;
    box-sizing: border-box;

    color: var(--Text-Color-Text-Tertiery, #9aa1ab);
    text-align: center;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.42px;
    padding: 0;
    transition: none;
  }

  .MuiPaginationItem-text:hover {
    color: #666f7b !important;
    background-color: transparent !important;
  }

  .Mui-selected {
    background-color: transparent !important;
    color: #0048c8 !important;
  }
`;

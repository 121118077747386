import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

interface UseTemplateDownloadExcelQueryProps {
  organizationUuid: string;
  contractTemplateUuid: string;
  enabled?: boolean;
}

const useTemplateDownloadExcelQuery = ({
  organizationUuid = "",
  contractTemplateUuid = "",
  enabled = true,
}: Partial<UseTemplateDownloadExcelQueryProps>) => {
  return useQuery({
    ...queries.template.downloadExcel({
      organizationUuid,
      contractTemplateUuid,
    }),
    enabled: enabled && !!organizationUuid && !!contractTemplateUuid,
  });
};

export default useTemplateDownloadExcelQuery;

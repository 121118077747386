import React, { useState } from "react";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { ReactComponent as BellActiveIcon } from "assets/common/24px/icon_bell_active.svg";
import { ReactComponent as BellIcon } from "assets/common/24px/icon_bell.svg";
import { device } from "components/styles";
import styled from "styled-components";
import NotificationDropDownItem, {
  NotificationDropDownEmptyMenuItem,
} from "./NotificationDropDownMenuItem";
import { Link, useNavigate } from "react-router-dom";
import useNotificationToday from "hooks/queries/notification/useNotificationToday";
import { format } from "date-fns";
import {
  getNotificationLink,
  NOTIFICATION_TYPE_MAP,
} from "config/notification";
import Button from "components/Mobile/Common/Button/Button";
import useNotificationsReadUpdate from "hooks/mutations/notification/useNotificationsReadUpdate";
import { NotificationList } from "models/Notification";

const NotificationDropDownMenu = () => {
  const navigate = useNavigate();
  const { data } = useNotificationToday();
  const { mutate: notificationReadUpdate } = useNotificationsReadUpdate();
  const [open, setOpen] = useState(false);

  const hasUnreadNotifications = data.notificationList.some(
    (notification) => !notification.isRead
  );

  const handleNotificationReadUpdate = () => {
    hasUnreadNotifications && notificationReadUpdate();
    setOpen((prev) => !prev);
  };

  const onClickNotification = (
    notification: NotificationList["contents"][0]
  ) => {
    const { notificationType, contractClass } = notification;
    const { link, state } = getNotificationLink(
      notificationType,
      contractClass
    );
    if (link) {
      navigate(link, { state });
    }
  };

  return (
    <DropDownRoot open={open} onOpenChange={setOpen}>
      <DropDownTrigger
        asChild
        onClick={handleNotificationReadUpdate}
        onPointerDown={(e) => e.preventDefault()}
      >
        <button>
          {hasUnreadNotifications ? <BellActiveIcon /> : <BellIcon />}
        </button>
      </DropDownTrigger>
      {open && <Backdrop onClick={() => setOpen(false)} />}
      <DropDownContent align="end" alignOffset={-120} sideOffset={30}>
        <div className="notification-list">
          {data.notificationList.map((notification) => (
            <div
              key={notification.notificationUuid}
              onClick={() => {
                handleNotificationReadUpdate();
                onClickNotification(notification);
              }}
            >
              <NotificationDropDownItem
                time={format(notification.createdAt, "yyyy-MM-dd hh:mm")}
                title={
                  NOTIFICATION_TYPE_MAP[notification.notificationType]?.text
                }
                content={notification.content.split("\n")[0]}
                isRead={notification.isRead}
              />
              <hr />
            </div>
          ))}
          {data.notificationList.length === 0 && (
            <NotificationDropDownEmptyMenuItem>
              <p>신규 알림이 없습니다.</p>
            </NotificationDropDownEmptyMenuItem>
          )}
        </div>
        <Link to={"/alert/dashboard"}>
          <Button
            colorType="secondary"
            size="medium"
            onClick={() => setOpen(false)}
          >
            전체 알림 확인하기
          </Button>
        </Link>
      </DropDownContent>
    </DropDownRoot>
  );
};

export default NotificationDropDownMenu;

const DropDownRoot = styled(Dropdown.Root)`
  position: relative;
  padding: 1.1rem 1.4rem;
  outline: none;
`;

const DropDownTrigger = styled(Dropdown.Trigger)`
  position: relative;
  padding: 1.1rem 1.4rem;
  display: flex;
  white-space: nowrap;
`;

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
`;

const DropDownContent = styled(Dropdown.Content)`
  min-width: 28.4rem;
  height: fit-content;
  overflow-y: auto;
  padding: 0.8rem;
  gap: 1rem;
  border-radius: 0.4rem;

  background: var(--bg-white);

  @media ${device.md}, ${device.sm} {
    width: 24rem;
  }

  @media ${device.xs} {
    width: 16rem;
  }

  .notification-list {
    max-height: 36rem;
    overflow-y: auto;

    hr {
      background-color: var(--stroke-light);
      height: 0.1rem;
      outline: none;
      border: none;
    }

    > a:last-child hr {
      display: none;
    }
  }
`;

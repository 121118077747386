import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { postTemplateUploadExcel } from "apis/template";

const usePostTemplateUploadExcel = (
  options?: UseMutationOptions<any, any, any>
) => {
  return useMutation({
    mutationFn: postTemplateUploadExcel,
    ...options,
  });
};

export default usePostTemplateUploadExcel;

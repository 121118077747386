import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchReviewApproval } from "apis/sign";
import { queries } from "config/queryKey";
import { toast } from "react-toastify";

const useReviewApprovalMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchReviewApproval,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.directURL._def,
      });
      toast("검토가 완료되었습니다.");
    },
  });
};

export default useReviewApprovalMutation;

import Dropdown from "components/Common/Dropdown/Dropdown";
import TextArea from "components/Common/Input/TextArea";
import Tooltip from "components/Common/Tooltip/Tooltip";
import { Body1SemiBold, Body3Regular } from "components/TextStyle";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ReactComponent as InformationIcon } from "assets/common/24px/icon_information_default.svg";
import styled from "styled-components";
import { MAIL_TEMPLATE_OPTIONS } from "config/constant";
import useMailTemplateAllQuery from "hooks/queries/mailTemplate/useMailTemplateAllQuery";
import useUserState from "hooks/recoil/useUserState";
import useMailTemplateDetailQuery from "hooks/queries/mailTemplate/useMailTemplateDetailQuery";

export interface MailConfigFormData {
  mail: {
    type: "DEFAULT" | "NAME" | string;
    title: string;
    content: string;
  };
}

const MailConfigForm = () => {
  const [user] = useUserState();
  const { control, register, watch, setValue } =
    useFormContext<MailConfigFormData>();

  const { data: mailTemplateList } = useMailTemplateAllQuery(
    user?.organization
  );

  const { data: mailTemplateDetail } = useMailTemplateDetailQuery(
    watch("mail.type").includes("-") ? watch("mail.type") : ""
  );

  useEffect(() => {
    setValue("mail.title", mailTemplateDetail?.title || "");
    setValue("mail.content", mailTemplateDetail?.content || "");
  }, [watch("mail.type"), setValue, mailTemplateDetail]);

  return (
    <MailConfigLayout>
      <div className="mail-config-title">
        <div>
          <Body1SemiBold
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.4rem",
            }}
          >
            메일 설정
            <Tooltip
              description={
                <>
                  작성 요청 이메일의 제목과 문구를 입력해 주세요. <br />
                  입력하지 않을 경우 기본 내용으로 보내집니다.
                </>
              }
              side="bottom"
            >
              <InformationIcon />
            </Tooltip>
          </Body1SemiBold>
          <ConfigContentDescription>
            카카오톡(문자) 내용은 기본 문구로 전송됩니다.
          </ConfigContentDescription>
        </div>
        <div>
          <Controller
            control={control}
            name="mail"
            render={({ field: { value, ref, onChange } }) => {
              const dropdownList = MAIL_TEMPLATE_OPTIONS.concat(
                ...(mailTemplateList || []).map((template) => ({
                  value: template.uuid,
                  label: template.name,
                }))
              );

              return (
                <Dropdown
                  outline={false}
                  ref={ref}
                  selected={value.type}
                  setSelected={(newValue: string) => {
                    onChange({
                      ...value,
                      type: newValue,
                    });
                    setValue("mail.type", newValue);
                  }}
                  dropdownList={dropdownList}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="mail-text-area-layout">
        <TextArea
          placeholder="메일 제목을 작성해주세요  ex) 이폼 '계약명'에 서명해주세요."
          maxLength={100}
          rows={1}
          {...register("mail.title")}
        />
        <TextArea
          maxLength={1000}
          rows={8}
          placeholder="메일 내용을 작성해주세요"
          {...register("mail.content")}
        />
      </div>
    </MailConfigLayout>
  );
};

export default MailConfigForm;

const MailConfigLayout = styled.div`
  .mail-config-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 3.2rem;
  }

  .mail-text-area-layout {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }
`;

const ConfigContentDescription = styled(Body3Regular)`
  margin-top: 0.8rem;
  color: var(--text-secondary);
`;

import Input from "components/Common/Input/Input";
import {
  Body1Medium,
  Body1SemiBold,
  Body2Medium,
  Body3Regular,
} from "components/TextStyle";
import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { useFieldArray, useFormContext } from "react-hook-form";
import useContractRemainCreditsQuery from "hooks/queries/contract/useContractRemainCreditsQuery";
import ActionModal from "components/Common/Modal/ActionModal";
import Toggle from "components/Common/Toggle/Toggle";
import { VerificationModalStyled } from "components/Contract/VerificationModal";
import RadioButton from "components/Common/RadioButton/RadioButton";
import Tooltip from "components/Common/Tooltip";
import { ReactComponent as InformationIcon } from "assets/common/20px/icon_information_small.svg";
import useTemplateDetailInfoQuery from "hooks/queries/template/useTemplateDetailInfoQuery";
import useUserState from "hooks/recoil/useUserState";
import { useNavigate, useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "../SortableItem";
import { ContractConfigFormData } from "./ContractConfigForm";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import useContactGroupsContactsAllQuery from "hooks/queries/contact/useContactGroupsContactsAllQuery";
import { ContactDto } from "models/Contact";
import { ConfigFormData } from "pages/template/send/detail/config";
export interface ManyReceiverConfigFormData {
  receivers: {
    id: string;
    name: string;
    sequence?: number;
    email: string;
    phoneNumber: string;
    verification: {
      password: {
        isUsed: boolean;
        password: string;
      };
      phoneNumber: {
        isUsed: boolean;
      };
    };
  }[];
}

const ManyReceiverConfigForm = () => {
  const navigate = useNavigate();
  const [user] = useUserState();
  const { templateId } = useParams();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
    // 입력 시 키보드 입력과 드래그앤드롭 키보드 입력 충돌로 주석 처리
    // useSensor(KeyboardSensor, {
    //   coordinateGetter: sortableKeyboardCoordinates,
    // })
  );

  const [verificationModal, setVerificationModal] = useState({
    opened: false,
    index: 99,
    prevData: {
      password: {
        isUsed: false,
        password: "",
      },
      phoneNumber: {
        isUsed: false,
      },
    },
  });

  const { getValues, setValue } = useFormContext<ContractConfigFormData>();
  const { control, register } = useFormContext<ManyReceiverConfigFormData>();
  const { getValues: getConfigValues } = useFormContext<ConfigFormData>();
  const { fields, update, replace, move } = useFieldArray({
    control,
    name: "receivers",
  });

  const { data: remainContractsCreditDto } = useContractRemainCreditsQuery();
  const { data: templateDetailInfo } = useTemplateDetailInfoQuery({
    organizationId: user?.organization,
    templateId,
  });

  const [contacts, setContacts] = useState<ContactDto[]>([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );

  const { data: contactsData } = useContactGroupsContactsAllQuery(
    user?.organization
  );

  const isConfirmButtonActive = () => {
    if (
      (fields[verificationModal.index].verification.password.isUsed &&
        fields[verificationModal.index].verification.password.password.length <
          4) ||
      fields[verificationModal.index].verification.password.password.length > 12
    ) {
      return false;
    } else return true;
  };

  const onCloseHandler = () => {
    update(verificationModal.index, {
      ...fields[verificationModal.index],
      verification: verificationModal.prevData,
    });

    setVerificationModal((prev) => ({ ...prev, opened: false, index: 99 }));
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setValue("contractType", value as "MANY" | "MANY_SEQ");
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over) return;

    if (active.id !== over.id) {
      const oldIndex = fields.findIndex(
        (receiver) => receiver.id === active.id
      );
      const newIndex = fields.findIndex((receiver) => receiver.id === over.id);
      move(oldIndex, newIndex);
    }
  };

  useEffect(() => {
    if (
      (templateDetailInfo?.contractTemplateDetails.receiverCnt as number) === 1
    ) {
      navigate(`/template/send/${templateId}/config/single`);
    } else if (
      (templateDetailInfo?.contractTemplateDetails.receiverCnt as number) > 1
    ) {
      replace(
        Array(templateDetailInfo?.contractTemplateDetails.receiverCnt as number)
          .fill(0)
          .map((_, idx) => ({
            id: uuidv4(),
            name: "",
            sequence: idx + 1,
            email: "",
            phoneNumber: "",
            verification: {
              password: {
                isUsed: getConfigValues("password.isUsed"),
                password: getConfigValues("password.password"),
              },
              phoneNumber: {
                isUsed: getConfigValues("isPhoneNumberAuth.isUsed"),
              },
            },
          }))
      );
    }
  }, [templateDetailInfo?.contractTemplateDetails.receiverCnt]);

  useEffect(() => {
    if (verificationModal.opened) {
      setVerificationModal((prev) => ({
        ...prev,
        prevData: fields[verificationModal.index].verification,
      }));
    }
  }, [verificationModal.opened]);

  useEffect(() => {
    setValue("contractType", "MANY_SEQ");
  }, []);

  useEffect(() => {
    if (!contactsData) return;
    const allContacts: ContactDto[] = [];
    contactsData.forEach((group) => {
      allContacts.push(...group.contactGroupsAndContacts.contactDtos);
    });
    setContacts(allContacts);
  }, [contactsData]);

  return (
    <>
      <ManyReceiverLayout>
        <div className="single-receiver-form-title">
          <div className="left">
            <Body1SemiBold>수신자</Body1SemiBold>
            <LeftSendTitle>
              잔여건수:{" "}
              {(
                (remainContractsCreditDto?.remainContractCredit
                  .remainCredits as number) || 0
              ).toLocaleString("ko")}
              건
            </LeftSendTitle>
          </div>
          <div className="right">
            <div className="radioContainer">
              <RadioButton
                id="order"
                value="MANY_SEQ"
                label="순차전송"
                name="mode"
                checked={getValues("contractType") === "MANY_SEQ"}
                onChange={onChangeHandler}
              />
            </div>
            <div className="radioContainer">
              <RadioButton
                id="once"
                value="MANY"
                label="동시전송"
                name="mode"
                checked={getValues("contractType") === "MANY"}
                onChange={onChangeHandler}
              />
              <Tooltip align="end" side="bottom" trigger={<InformationIcon />}>
                <Body3Regular>
                  여러 명이 1건의 폼을 작성할 때 전송 순서를 설정할 수 있습니다.
                  <br />
                  <span>순차전송:</span> 순서를 정하고 정한 순서대로 전송
                  <br />
                  <span>동시전송:</span> 순서에 상관없이 동시에 전송
                </Body3Regular>
              </Tooltip>
            </div>
          </div>
        </div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={fields}
            strategy={verticalListSortingStrategy}
            disabled={getValues("contractType") === "MANY"}
          >
            {fields.map((field, index) => (
              <SortableItem
                id={field.id}
                key={field.id}
                idx={index}
                field={field}
                register={register}
                setVerificationModal={setVerificationModal}
                mode={getValues("contractType") as "MANY" | "MANY_SEQ"}
                contacts={contacts}
                openDropdownIndex={openDropdownIndex}
                setOpenDropdownIndex={setOpenDropdownIndex}
                setValue={setValue}
              />
            ))}
          </SortableContext>
        </DndContext>
      </ManyReceiverLayout>
      {verificationModal.opened && (
        <ActionModal
          title="인증 수단 추가"
          disableCloseWithOutsideClickOrEsc
          handleModalClose={onCloseHandler}
          handleCancelButton={onCloseHandler}
          handleConfirmButton={() =>
            setVerificationModal((prev) => ({
              ...prev,
              opened: false,
              index: 99,
            }))
          }
          disabledConfirmButton={!isConfirmButtonActive()}
        >
          <VerificationModalStyled>
            <div className="password">
              <div className="label">
                <Toggle
                  isSelected={
                    fields[verificationModal.index].verification.password
                      .isUsed as boolean
                  }
                  handleToggle={() =>
                    update(verificationModal.index, {
                      ...fields[verificationModal.index],
                      verification: {
                        ...fields[verificationModal.index].verification,
                        password: {
                          ...fields[verificationModal.index].verification
                            .password,
                          isUsed:
                            !fields[verificationModal.index].verification
                              .password.isUsed,
                        },
                        phoneNumber: {
                          isUsed: fields[verificationModal.index].verification
                            .phoneNumber.isUsed
                            ? false
                            : fields[verificationModal.index].verification
                                .phoneNumber.isUsed,
                        },
                      },
                    })
                  }
                />
                <Body2Medium>암호 인증</Body2Medium>
              </div>
              <Input
                type="text"
                placeholder="암호 입력 (4~12자)"
                minLength={4}
                maxLength={12}
                value={
                  fields[verificationModal.index].verification.password.password
                }
                setValue={(password: string) =>
                  update(verificationModal.index, {
                    ...fields[verificationModal.index],
                    verification: {
                      ...fields[verificationModal.index].verification,
                      password: {
                        ...fields[verificationModal.index].verification
                          .password,
                        password,
                      },
                      phoneNumber: {
                        ...fields[verificationModal.index].verification
                          .phoneNumber,
                      },
                    },
                  })
                }
                isDisabled={
                  !fields[verificationModal.index].verification.password.isUsed
                }
              />
            </div>
            <div className="secure">
              <div className="label">
                <Toggle
                  isSelected={
                    fields[verificationModal.index].verification.phoneNumber
                      .isUsed
                  }
                  handleToggle={() =>
                    update(verificationModal.index, {
                      ...fields[verificationModal.index],
                      verification: {
                        ...fields[verificationModal.index].verification,
                        phoneNumber: {
                          isUsed:
                            !fields[verificationModal.index].verification
                              .phoneNumber.isUsed,
                        },
                        password: {
                          isUsed: fields[verificationModal.index].verification
                            .phoneNumber.isUsed
                            ? false
                            : fields[verificationModal.index].verification
                                .phoneNumber.isUsed,
                          password: "",
                        },
                      },
                    })
                  }
                />
                <Body2Medium>보안 인증</Body2Medium>
              </div>
              <div>
                <div
                  className={`message${
                    fields[verificationModal.index].verification.phoneNumber
                      .isUsed
                      ? " selected"
                      : ""
                  }`}
                >
                  <Body1SemiBold>휴대폰 본인인증</Body1SemiBold>
                </div>
              </div>
            </div>
          </VerificationModalStyled>
        </ActionModal>
      )}
    </>
  );
};

export default ManyReceiverConfigForm;

const ManyReceiverLayout = styled.div`
  margin-bottom: 4rem;

  .single-receiver-form-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      margin-bottom: 2rem;

      .radioContainer {
        display: flex;
        align-items: center;

        input,
        label {
          margin: 0;
        }
      }
    }
  }

  .single-receiver-form-from {
    display: flex;
    justify-content: space-between;
    gap: 0.8rem;
    align-items: center;
    margin-bottom: 2rem;

    > div {
      display: flex;
      gap: 0.8rem;
      align-items: center;

      .color {
        width: 2rem;
        aspect-ratio: 1/1;
        border-radius: 100%;
      }
    }

    > div > div {
      display: flex;
      gap: 1.2rem;
      align-items: center;
    }

    svg {
      cursor: pointer;
    }

    input {
      margin-top: 0;
      margin-left: 0;
    }
  }
`;

const LeftSendTitle = styled(Body1Medium)`
  color: var(--text-emphasis);
`;

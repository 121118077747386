import Dropdown from "components/Common/Dropdown/Dropdown";
import Item from "components/Draggable/Item";
import { ScaleControllerStyled } from "components/Toolbar/styles";
import { PALETTE } from "config/constant";
import useTemplateDetailsById from "hooks/queries/template/useTemplateDetailsById";
import useUserState from "hooks/recoil/useUserState";
import { IPage, IParticipant } from "interfaces/contract";
import { TextAlign } from "models/Contract";
import { FIELDS, Field } from "pages/contract/send";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import { ConfigMobileNavigation } from "components/template/Config/(mobile)/MobileLayout";
import { ReactComponent as ArrowLeftIcon } from "assets/common/24px/icon_left_default.svg";
import useContractDetail from "hooks/queries/contract/useContractDetail";
import useContractsInputs from "hooks/queries/contract/useContractsInputs";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const initialParticipants: IParticipant[] = [
  {
    id: uuidv4(),
    label: "발신자",
    type: "sender",
    color: "#666F7B",
  },
  ...Array(10)
    .fill(0)
    .map(
      (_, idx) =>
        ({
          id: uuidv4(),
          sequence: idx,
          color: PALETTE.receivers[idx],
          label: `수신자${idx === 0 ? "" : idx + 1}`,
          type: "receiver",
        } as IParticipant)
    ),
];

const MobileViewDocuments = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [participants] = useState(initialParticipants);
  const [scale, setScale] = useState("50%");
  const [pdf, setPdf] = useState<File | string | null>(null);
  const [pages, setPages] = useState<IPage[]>([]);
  const [selectedPageId, setSelectedPageId] = useState("");
  const [, setSelectedFieldType] = useState<Field>(null);
  const [selectedFieldId, setSelectedFieldId] = useState("");
  const selectField = (id: string, type: Field) => {
    if (
      pages
        ?.find((page) => page.id === selectedPageId)
        ?.fields.findIndex(
          (field) => `${field.fieldType}:${field.id}` === id
        ) !== -1
    ) {
      setSelectedFieldId(id);
      setSelectedFieldType(type);
    }
  };

  const [user] = useUserState();
  const { data: templateDetail } = useTemplateDetailsById({
    organizationUuid: user.organization,
    templateUuid: state?.templateId,
  });

  const { data: templateDetailInfo } = useTemplateDetailsById({
    organizationUuid: user?.organization,
    templateUuid: state?.templateId,
  });

  const { data: contractDetail } = useContractDetail(state?.contractId);
  const { data: contractInputs } = useContractsInputs(state?.contractId);

  useEffect(() => {
    setPdf(templateDetail?.contractTemplateDetail.templateFile as string);
  }, [templateDetailInfo]);

  useEffect(() => {
    if (state?.fileUrl) {
      setPdf(state?.fileUrl);
      return;
    }
  }, [state]);

  useEffect(() => {
    if (contractDetail?.contractDetail.contractFileUrl) {
      setPdf(contractDetail?.contractDetail.contractFileUrl);
    }
  }, [contractDetail]);
  return (
    <>
      <ConfigMobileNavigation>
        <ScaleControllerStyled>
          <ArrowLeftIcon
            onClick={() => navigate(-1)}
            style={{
              position: "absolute",
              left: "2rem",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
          <Dropdown
            table
            selected={scale}
            setSelected={setScale}
            dropdownList={["50", "100"].map((scale) => ({
              label: `${scale}%`,
              value: `${scale}%`,
            }))}
            backgroundDimmedWhenOpened
          />
        </ScaleControllerStyled>
      </ConfigMobileNavigation>
      <StyledPreview>
        <Document
          className="pdf"
          file={pdf}
          noData={<></>}
          onLoadSuccess={(doc) => {
            setPages(() => {
              const firstPageId = uuidv4();
              setSelectedPageId(firstPageId);

              if (contractInputs) {
                return Array(doc.numPages)
                  .fill(0)
                  .map((_, idx) => ({
                    id: idx === 0 ? firstPageId : uuidv4(),
                    order: idx + 1,
                    fields: contractInputs?.contractInputList
                      .contractReceiverInputList
                      ? contractInputs?.contractInputList.contractReceiverInputList
                          .filter((input) => input.page === idx + 1)
                          .map((input) => ({
                            fieldType: input.inputType as Field,
                            id: input.uuid as string,
                            width:
                              (input.width as number) *
                              (+scale.replace("%", "") / 100),
                            height:
                              (input.height as number) *
                              (+scale.replace("%", "") / 100),
                            writer: participants[input.contractSequence],
                            position: {
                              x:
                                (input.x as number) *
                                (+scale.replace("%", "") / 100),
                              y:
                                (input.y as number) *
                                (+scale.replace("%", "") / 100),
                            },
                            type:
                              input.contractSequence === 0
                                ? "sender"
                                : "receiver",
                            name: input.inputName || "",
                            required: input.required || false,
                            description: input.description || "",
                            size: `${input.textSize as number}`,
                            align: input.textAlign as TextAlign,
                            commas: input.isCommaText,
                            autoDate: input.isTodayDate,
                            contents: input.dropDownItems
                              ?.map((item) => item.value)
                              .join(", "),
                            buttonType:
                              input.checkBoxItems || input.radioButtonItems
                                ? input.radioButtonItems &&
                                  input.radioButtonItems?.length > 0
                                  ? "radio"
                                  : "checkbox"
                                : undefined,
                            buttonsCount:
                              input.checkBoxItems || input.radioButtonItems
                                ? input.radioButtonItems &&
                                  input.radioButtonItems?.length > 0
                                  ? input.radioButtonItems.length
                                  : input.checkBoxItems
                                  ? input.checkBoxItems.length
                                  : undefined
                                : undefined,
                            selectCount: {
                              number: input.numOfConcurrentSelect,
                              mode: input.typeOfConcurrentSelect,
                              options:
                                input.checkBoxItems || input.radioButtonItems
                                  ? input.radioButtonItems &&
                                    input.radioButtonItems?.length > 0
                                    ? input.radioButtonItems.map((item) => ({
                                        id: item.uuid,
                                        label: item.name || "",
                                        width: 44,
                                        height: 44,
                                        checked: false,
                                        position: {
                                          x:
                                            item.x *
                                            (+scale.replace("%", "") / 100),
                                          y:
                                            item.y *
                                            (+scale.replace("%", "") / 100),
                                        },
                                      }))
                                    : input.checkBoxItems
                                    ? input.checkBoxItems.map((item) => ({
                                        id: item.uuid,
                                        label: item.name || "",
                                        width:
                                          44 * (+scale.replace("%", "") / 100),
                                        height:
                                          44 * (+scale.replace("%", "") / 100),
                                        checked: false,
                                        position: {
                                          x:
                                            item.x *
                                            (+scale.replace("%", "") / 100),
                                          y:
                                            item.y *
                                            (+scale.replace("%", "") / 100),
                                        },
                                      }))
                                    : undefined
                                  : undefined,
                            },
                          }))
                      : [],
                  }));
              } else
                return Array(doc.numPages)
                  .fill(0)
                  .map((_, idx) => ({
                    id: idx === 0 ? firstPageId : uuidv4(),
                    order: idx + 1,
                    fields: templateDetail?.contractTemplateDetail
                      .contractTemplateInputList
                      ? templateDetail?.contractTemplateDetail.contractTemplateInputList
                          .filter((input) => input.page === idx + 1)
                          .map((input) => ({
                            fieldType: input.inputType as Field,
                            id: input.uuid as string,
                            width:
                              (input.width as number) *
                              (+scale.replace("%", "") / 100),
                            height:
                              (input.height as number) *
                              (+scale.replace("%", "") / 100),
                            writer: participants[input.receiverSequence],
                            position: {
                              x:
                                (input.x as number) *
                                (+scale.replace("%", "") / 100),
                              y:
                                (input.y as number) *
                                (+scale.replace("%", "") / 100),
                            },
                            type:
                              input.receiverSequence === 0
                                ? "sender"
                                : "receiver",
                            name: input.inputName || "",
                            required: input.required || false,
                            description: input.description || "",
                            size: `${input.textSize as number}`,
                            align: input.textAlign as TextAlign,
                            commas: input.isCommaText,
                            autoDate: input.isTodayDate,
                            contents: input.dropDownItems
                              ?.map((item) => item.value)
                              .join(", "),
                            buttonType:
                              input.checkBoxItems || input.radioBtnItems
                                ? input.radioBtnItems &&
                                  input.radioBtnItems?.length > 0
                                  ? "radio"
                                  : "checkbox"
                                : undefined,
                            buttonsCount:
                              input.checkBoxItems || input.radioBtnItems
                                ? input.radioBtnItems &&
                                  input.radioBtnItems?.length > 0
                                  ? input.radioBtnItems.length
                                  : input.checkBoxItems
                                  ? input.checkBoxItems.length
                                  : undefined
                                : undefined,
                            selectCount: {
                              number: input.numOfConcurrentSelect,
                              mode: input.typeOfConcurrentSelect,
                              options:
                                input.checkBoxItems || input.radioBtnItems
                                  ? input.radioBtnItems &&
                                    input.radioBtnItems?.length > 0
                                    ? input.radioBtnItems.map((item) => ({
                                        id: item.uuid,
                                        label: item.name || "",
                                        width: 44,
                                        height: 44,
                                        checked: false,
                                        position: {
                                          x:
                                            item.x *
                                            (+scale.replace("%", "") / 100),
                                          y:
                                            item.y *
                                            (+scale.replace("%", "") / 100),
                                        },
                                      }))
                                    : input.checkBoxItems
                                    ? input.checkBoxItems.map((item) => ({
                                        id: item.uuid,
                                        label: item.name || "",
                                        width:
                                          44 * (+scale.replace("%", "") / 100),
                                        height:
                                          44 * (+scale.replace("%", "") / 100),
                                        checked: false,
                                        position: {
                                          x:
                                            item.x *
                                            (+scale.replace("%", "") / 100),
                                          y:
                                            item.y *
                                            (+scale.replace("%", "") / 100),
                                        },
                                      }))
                                    : undefined
                                  : undefined,
                            },
                          }))
                      : [],
                  }));
            });
          }}
        >
          <div className="main">
            <div className="pdf-container">
              {pages.map((page) => (
                <div key={page.id} className="page-wrapper">
                  <Page
                    className="page"
                    pageNumber={page.order}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    scale={+scale.replace("%", "") / 100}
                  />
                  {page.fields.map(
                    ({
                      id,
                      type,
                      fieldType,
                      selectCount,
                      position: { x, y },
                    }) =>
                      fieldType === "CHECKBOX" ? (
                        selectCount?.options?.map((option) => (
                          <Item
                            edit={false}
                            preview
                            field={
                              FIELDS.find(
                                (field) => field.value === fieldType
                              ) || FIELDS[0]
                            }
                            id={id}
                            optionId={option.id}
                            key={`${id}-${option.id}`}
                            pages={pages}
                            setPages={setPages}
                            type={type}
                            setSelectedFieldType={setSelectedFieldType}
                            selectedFieldId={selectedFieldId}
                            setSelectedFieldId={setSelectedFieldId}
                            selectField={selectField}
                            selectedPageId={page.id}
                            scale={+scale.replace("%", "") / 100}
                            style={{
                              width: `max-content`,
                              height: `max-content`,
                              position: "absolute",
                              left:
                                option.position.x *
                                (+scale.replace("%", "") / 100),
                              top:
                                option.position.y *
                                (+scale.replace("%", "") / 100),
                              zIndex:
                                selectedFieldId === `${fieldType}:${id}`
                                  ? 5
                                  : 4,
                              opacity: 1,
                            }}
                          />
                        ))
                      ) : (
                        <Item
                          isMobile
                          edit={false}
                          preview
                          templateSend
                          field={
                            FIELDS.find((field) => field.value === fieldType) ||
                            FIELDS[0]
                          }
                          id={id}
                          key={id}
                          pages={pages}
                          setPages={setPages}
                          type={type}
                          setSelectedFieldType={setSelectedFieldType}
                          selectedFieldId={selectedFieldId}
                          setSelectedFieldId={setSelectedFieldId}
                          selectedPageId={page.id}
                          selectField={selectField}
                          scale={+scale.replace("%", "") / 100}
                          style={{
                            width: `max-content`,
                            height: `max-content`,
                            position: "absolute",
                            left: x * (+scale.replace("%", "") / 100),
                            top: y * (+scale.replace("%", "") / 100),
                            zIndex:
                              selectedFieldId === `${fieldType}:${id}` ? 5 : 4,
                            opacity: 1,
                          }}
                        />
                      )
                  )}
                </div>
              ))}
            </div>
          </div>
        </Document>
      </StyledPreview>
    </>
  );
};

export default MobileViewDocuments;

const StyledPreview = styled.div`
  .pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px 0;
    background-color: #f5f5f5;

    .main {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .pdf-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        overflow-x: auto;
        min-width: 100vw;

        .page-wrapper {
          position: relative;
          background: white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          overflow-x: scroll;
          margin: 0 auto;
        }
      }
    }
  }
`;

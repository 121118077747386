import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchNotifications } from "apis/notification";
import { queries } from "config/queryKey";

const useNotificationsReadUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchNotifications,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.notification._def });
    },
  });
};

export default useNotificationsReadUpdate;

import React, { MouseEvent } from "react";
import { TableCell, TableRow } from "../Table/Table";
import CheckBox from "components/Common/CheckBox/CheckBox";
import { Body2Regular } from "components/TextStyle";
import DropdownMenu from "components/Common/Dropdown/DropdownMenu";

import { ReactComponent as StarOnIcon } from "assets/common/20px/icon_star_on.svg";
import { ReactComponent as StarOffIcon } from "assets/common/20px/icon_star_off.svg";
import { ReactComponent as KebabIcon } from "assets/common/24px/icon_kebab.svg";
import { ReactComponent as GroupIcon } from "assets/common/16px/icon_group.svg";
import DashboardRowSendButton from "./DashboardRowSendButton";
import useModal from "hooks/useModal";
import TemplateDeleteModal from "../Modals/TemplateDeleteModal";
import TemplateDataExportModal from "../Modals/TemplateDataExportModal";
import { useNavigate } from "react-router-dom";
import TemplateDirectUrlModal from "../Modals/TemplateDirectUrlModal";
import useTemplateFavoriteMutation from "hooks/mutations/template/useTemplateFavoriteMutation";
import useUserState from "hooks/recoil/useUserState";
import useDeleteTemplateFavoriteMutation from "hooks/mutations/template/useDeleteTemplateFavoriteMutation";
import useDeleteTemplateMutation from "hooks/mutations/template/useDeleteTemplateMutation";
import { getTemplateByIdById } from "apis/template";
import EditInput from "components/Setting/EditInput";
import useTemplateNameUpdateMutation from "hooks/mutations/template/useTemplateNameUpdateMutation";
import Tooltip from "components/Common/Tooltip";
import saveFile from "utils/saveFile";

interface DashboardRowProps {
  id: string;
  onChecked: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
  isBookmark: boolean;
  status: "COMPLETE" | "TEMP";
  templateName: string;
  receiverCount: number;
  register: string;
  createdAt: string;
  updatedAt: string;
}
const DashboardRow = ({
  id,
  onChecked,
  isChecked,
  isBookmark,
  status,
  templateName,
  receiverCount,
  register,
  createdAt,
  updatedAt,
}: DashboardRowProps) => {
  const navigate = useNavigate();
  const [user] = useUserState();
  const { mutate: favoriteMutate } = useTemplateFavoriteMutation();
  const { mutate: unFavoriteMutate } = useDeleteTemplateFavoriteMutation();
  const { mutate: deleteMutate } = useDeleteTemplateMutation();
  const { mutate: updateNameMutate } = useTemplateNameUpdateMutation();

  const STATUS_MAP = {
    COMPLETE: {
      label: "완료",
      color: "var(--text-default)",
    },
    TEMP: {
      label: "임시",
      color: "var(--text-emphasis)",
    },
  } as const;

  const {
    isOpen: deleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal();

  const {
    isOpen: dataExportModalOpen,
    closeModal: closeDataExportModal,
    openModal: openDataExportModal,
  } = useModal();

  const {
    isOpen: directURLModalOpen,
    closeModal: closeDirectURLModal,
    openModal: openDirectURLModal,
  } = useModal();

  const handleFavorite = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    if (status === "TEMP") return;

    favoriteMutate({
      organizationId: user.organization ?? "",
      organizationGroupId: user.organizationGroup ?? "",
      templateId: id,
    });
  };

  const handleUnFavorite = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    if (status === "TEMP") return;

    unFavoriteMutate({
      organizationId: user.organization ?? "",
      organizationGroupId: user.organizationGroup ?? "",
      templateId: id,
    });
  };

  const handleDelete = () => {
    deleteMutate({
      organizationId: user.organization ?? "",
      organizationGroupId: user.organizationGroup ?? "",
      templateIdList: [id],
    });
    closeDeleteModal();
    navigate("/template/dashboard");
  };

  const handleRowClick = () => {
    navigate(`/template/${id}`);
  };

  console.log(user.organizationGroup_permission === "MEMBER");

  return (
    <>
      {deleteModalOpen && (
        <TemplateDeleteModal
          closeModal={closeDeleteModal}
          handleDelete={handleDelete}
          deleteCount={1}
        />
      )}

      {dataExportModalOpen && (
        <TemplateDataExportModal
          closeModal={closeDataExportModal}
          templateIdParam={id}
        />
      )}

      {directURLModalOpen && (
        <TemplateDirectUrlModal
          closeModal={closeDirectURLModal}
          templateUuid={id}
        />
      )}

      <TableRow
        onClick={handleRowClick}
        style={{
          cursor: "pointer",
        }}
      >
        <TableCell>
          <CheckBox
            checked={isChecked}
            onChange={onChecked}
            isDisabled={user.organizationGroup_permission === "MEMBER"}
          />
        </TableCell>
        <TableCell>
          {isBookmark ? (
            <StarOnIcon
              style={{
                cursor: "pointer",
              }}
              onClick={handleUnFavorite}
            />
          ) : (
            <StarOffIcon
              style={{
                cursor: "pointer",
              }}
              onClick={handleFavorite}
            />
          )}
        </TableCell>
        <TableCell>
          <Body2Regular
            style={{
              color: STATUS_MAP[status].color,
            }}
          >
            {STATUS_MAP[status].label}
          </Body2Regular>
        </TableCell>
        <TableCell
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.6rem",
          }}
        >
          <EditInput
            value={templateName}
            isDisabledEdit={user.organizationGroup_permission === "MEMBER"}
            callback={(value) => {
              updateNameMutate({
                organizationId: user.organization,
                organizationGroupId: user.organizationGroup,
                templateId: id,
                name: value,
              });
            }}
          >
            {receiverCount > 1 && (
              <Tooltip trigger={<GroupIcon />}>
                2인 이상의 서명 참여자가 설정한 템플릿입니다.
              </Tooltip>
            )}
          </EditInput>
        </TableCell>
        <TableCell>
          <Body2Regular>{register}</Body2Regular>
        </TableCell>
        <TableCell>
          <Body2Regular>{createdAt}</Body2Regular>
        </TableCell>
        <TableCell>
          <Body2Regular>{updatedAt}</Body2Regular>
        </TableCell>
        <TableCell>
          <DashboardRowSendButton
            receiverCount={receiverCount}
            isDisabled={status === "TEMP"}
            list={[
              ...(receiverCount <= 1
                ? [
                    {
                      label: "Direct URL 만들기",
                      onClick: openDirectURLModal,
                    },
                    {
                      label: "대량 전송하기",
                      onClick: () => {
                        navigate(`/template/send/${id}/config/multiple`);
                      },
                    },
                  ]
                : []),
            ]}
            id={id}
          />
        </TableCell>
        <TableCell>
          <DropdownMenu
            isDisabled={user.organizationGroup_permission === "MEMBER"}
            list={
              status === "TEMP"
                ? [
                    {
                      label: "수정하기",
                      onClick: (e) => {
                        e.stopPropagation();
                        navigate(`/template/create/upload?id=${id}`);
                      },
                    },
                    {
                      label: "삭제",
                      onClick: (e) => {
                        e.stopPropagation();
                        openDeleteModal();
                      },
                    },
                  ]
                : [
                    {
                      label: "문서보기",
                      onClick: (e) => {
                        e.stopPropagation();
                        navigate(`/template/send/view`, {
                          state: {
                            templateId: id,
                          },
                        });
                      },
                    },
                    {
                      label: "원본 다운로드",
                      onClick: async (e) => {
                        e.stopPropagation();
                        const result = await getTemplateByIdById({
                          organizationId: user.organization,
                          organizationGroupId: user.organizationGroup,
                          templateId: id,
                        });
                        saveFile(result.data, {
                          fileName: decodeURIComponent(
                            result.headers["content-disposition"].split(
                              "filename="
                            )[1]
                          ).replace(/\+/g, " "),
                        });
                      },
                    },
                    {
                      label: "템플릿 데이터 받기",
                      onClick: (e) => {
                        e.stopPropagation();
                        openDataExportModal();
                      },
                    },
                    {
                      label: "삭제",
                      onClick: (e) => {
                        e.stopPropagation();
                        openDeleteModal();
                      },
                    },
                  ]
            }
          >
            <KebabIcon />
          </DropdownMenu>
        </TableCell>
      </TableRow>
    </>
  );
};

export default DashboardRow;

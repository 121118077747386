import React from "react";
import { Body1Medium, Body1Regular, Body1SemiBold } from "components/TextStyle";
import { ContractModeModalStyled } from "components/Toolbar/styles";
import gifParaphAnimation from "assets/Contract/paraph_animation.gif";
import { IPage } from "interfaces/contract";
import { IConfigForm } from "pages/contract/send";
import { useLocation, useParams } from "react-router-dom";
import useContractRemainCreditsQuery from "hooks/queries/contract/useContractRemainCreditsQuery";
import { UseFormReturn } from "react-hook-form";
import { ConfigFormData } from "pages/template/send/detail/config";
import useUserState from "hooks/recoil/useUserState";
import useTemplateDetailsById from "hooks/queries/template/useTemplateDetailsById";
import { toast } from "react-toastify";

interface IContractModeModal {
  methods?: UseFormReturn<ConfigFormData, any, undefined>;
  setForm?: React.Dispatch<React.SetStateAction<IConfigForm>>;
  setContractModeModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
  pages?: IPage[];
  setStampModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  goToPreview?: (ganinImageUrl?: string) => void;
}

export default function ContractModeModal({
  methods,
  setForm,
  setContractModeModalOpened,
  setCurrentStep,
  pages,
  setStampModalOpened,
  goToPreview,
}: IContractModeModal) {
  const { templateId } = useParams();
  const { state } = useLocation();

  const [user] = useUserState();

  const { data: remainContractsCreditDto } = useContractRemainCreditsQuery();

  const { data: templateDetails } = useTemplateDetailsById({
    organizationUuid: user?.organization,
    templateUuid: templateId,
  });

  return (
    <ContractModeModalStyled
      insufficient={
        (remainContractsCreditDto?.remainContractCredit.remainCredits || 0) <= 0
      }
    >
      <Body1Regular>계약서 형식을 선택해주세요.</Body1Regular>
      <div className="contractModes">
        <div
          className="contractMode"
          onClick={() => {
            if (
              (remainContractsCreditDto?.remainContractCredit.remainCredits ||
                0) -
                (methods?.getValues("receivers")?.length || 0) <=
              0
            ) {
              toast("전송할 수 있는 잔여 건수가 충분하지 않습니다.");
              return;
            }

            setForm &&
              setForm((prev) => ({ ...prev, contractClass: "NORMAL" }));
            setContractModeModalOpened(false);
            setCurrentStep && setCurrentStep((prev) => prev + 1);
            goToPreview && goToPreview();
          }}
        >
          <div className="text">
            <div>
              <Body1SemiBold>일반 형식으로 보내기</Body1SemiBold>
              <Body1Medium>
                잔여 건수 :{" "}
                {remainContractsCreditDto?.remainContractCredit.remainCredits.toLocaleString(
                  "ko"
                )}
                건
              </Body1Medium>
            </div>
            <Body1Regular>
              입력란 배치를 완료한 계약서를 그대로 전송합니다.
            </Body1Regular>
          </div>
        </div>
        <div
          className={`contractMode${
            (pages?.length as number) <= 1 ||
            state?.contractType === "MULTIPLE" ||
            (methods?.getValues("receivers").length as number) > 1 ||
            (templateDetails?.contractTemplateDetail.pageNum as number) <= 1
              ? " disabled"
              : ""
          }`}
          onClick={() => {
            if (
              (pages?.length as number) <= 1 ||
              state?.contractType === "MULTIPLE" ||
              (methods?.getValues("receivers").length as number) > 1 ||
              (templateDetails?.contractTemplateDetail.pageNum as number) <= 1
            )
              return;

            if (
              (remainContractsCreditDto?.remainContractCredit.remainCredits ||
                0) -
                (methods?.getValues("receivers")?.length || 0) <=
              0
            ) {
              toast("전송할 수 있는 잔여 건수가 충분하지 않습니다.");
              return;
            }

            setContractModeModalOpened(false);
            setStampModalOpened(true);
          }}
        >
          <div className="text">
            <div>
              <Body1SemiBold>간인 형식으로 보내기</Body1SemiBold>
              <Body1Medium>
                잔여 건수 :{" "}
                {remainContractsCreditDto?.remainContractCredit.remainCredits.toLocaleString(
                  "ko"
                )}
                건
              </Body1Medium>
            </div>
            <Body1Regular>
              계약서 모든 페이지에 간인을 찍어 전송합니다. 단, 문서가 한
              페이지일 경우는 제외됩니다.
            </Body1Regular>
          </div>
          <div className="animation">
            <img src={gifParaphAnimation} alt="간인 애니메이션" />
          </div>
        </div>
      </div>
    </ContractModeModalStyled>
  );
}

import { Board as DefaultBoard } from "components/Common/Board/Board";
import styled from "styled-components";
import { SubTitle } from "components/TextStyle";
export const ContractSendLayout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 2rem;
  padding-top: 1rem;
  padding-bottom: 4rem;
  margin-bottom: 0.5rem;

  overflow-x: auto;
`;

export const BoardListLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Board = styled(DefaultBoard)`
  padding: 4rem;
`;

export const BoardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;

  .title {
    display: flex;
    justify-content: space-between;

    margin-bottom: 4rem;
  }

  .title-more {
    > a {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      color: var(--text-secondary);
    }
  }
`;

export const BoardTitle = styled(SubTitle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;

  > span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .organization {
    color: var(--text-middle-emphasis);
  }

  .total {
    margin-left: 0.4rem;
    color: var(--text-emphasis);
  }
`;

export const DashBoardPageLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;

  > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 71%;
  }

  > div:last-child {
    width: 29%;
  }
`;

export const NoticeListLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  > hr {
    height: 0.1rem;
    width: 100%;
    outline: none;
    border: none;
    background-color: var(--stroke-light);
    margin: 0;
  }

  > hr:last-child {
    display: none;
  }
`;

export const RightLayout = styled.div`
  max-width: 52rem;
  height: 100%;
`;

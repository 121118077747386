import Konva from "konva";
import React, { useRef } from "react";
import { Image as ImageRefType } from "konva/lib/shapes/Image";
import { Image } from "react-konva";

interface ImagePreviewProps {
  file: File;
  contrast: number;
  rotation: number;
}

const ImagePreview = ({ file, contrast, rotation }: ImagePreviewProps) => {
  const url = window.URL.createObjectURL(file);
  const img = new window.Image();
  img.src = url;
  const ref = useRef<ImageRefType>(null);

  if (ref.current) {
    ref.current.cache();
    ref.current.contrast(contrast);
    // Image 컴포넌트에서 props로 contrast는 지원하지 않음 따라서 ref를 이용해 설정해야함 cache 선행 필수
    ref.current.rotation(rotation);
  }

  return (
    <Image
      image={img}
      width={250}
      height={250}
      filters={[Konva.Filters.Contrast]}
      ref={ref}
      x={125}
      y={125}
      offsetX={125}
      offsetY={125}
    />
  );
};

export default ImagePreview;

import {
  getSignAssetsPresignedUrl,
  getSignDirectUrlsAssetsPresignedUrl,
} from "apis/sign";
import { ReactComponent as PencilIcon } from "assets/common/20px/icon_pencil.svg";
import { ReactComponent as DeleteIcon } from "assets/common/24px/icon_close_default.svg";
import RequiredOption from "components/Contract/Options/Required";
import Resizable from "components/Resizable";
import { Body3Regular } from "components/TextStyle";
import { IField, IPage } from "interfaces/contract";
import { Field } from "pages/contract/send";
import CreateSignBottomSheet from "pages/profile/sign/(mobile)/CreateSignBottomSheet";
import CreateSignModal from "pages/profile/sign/CreateSignModal";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { DraggableStyled } from "./styles";

interface SignatureProps extends React.HTMLAttributes<HTMLDivElement> {
  template?: boolean;
  edit?: boolean;
  complete?: boolean;
  id: string;
  type?: "sender" | "receiver";
  fields: IField[];
  pages: IPage[];
  setPages: React.Dispatch<React.SetStateAction<IPage[]>>;
  selectedPageId: string;
  selectedFieldId?: string;
  setSelectedFieldId?: React.Dispatch<React.SetStateAction<string>>;
  onDeleteHandler: (id: string) => void;
  selectField?: (id: string, type: Field) => void;
  scale: number;
  setSignatureSelectionModal?: React.Dispatch<React.SetStateAction<boolean>>;
  templateSend?: boolean;
  isMobile?: boolean;
  currentSize: { w: number; h: number };
  handleMouseDown: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>,
    direction: string
  ) => void;
  handleMouseMove: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>
  ) => void;
  handleMouseUp: () => void;
  newField?: boolean;
}

export default function Signature({
  template,
  edit = false,
  complete = false,
  id,
  type,
  fields,
  pages,
  setPages,
  selectedPageId,
  selectedFieldId,
  setSelectedFieldId,
  onDeleteHandler,
  selectField,
  scale,
  setSignatureSelectionModal,
  draggable,
  templateSend,
  isMobile,
  currentSize,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  newField,
  ...args
}: SignatureProps) {
  const { state } = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const signatureRef = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(true);

  const closeModal = () => setIsOpen(false);

  const sign = async (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      let fileUrl = "";

      if (state?.directUrl && state?.directUrlCode) {
        fileUrl = await getSignDirectUrlsAssetsPresignedUrl({
          file,
          fileName: file.name,
        });
      } else {
        fileUrl = await getSignAssetsPresignedUrl({
          file,
          fileName: file.name,
        });
      }

      if (fileUrl && e.target && e.target.result) {
        setPages((prev) =>
          prev.map((page) => {
            if (isMobile) {
              if (page.order.toString() === selectedPageId) {
                return {
                  ...page,
                  fields: page.fields.map((field) => {
                    if (`${field.fieldType}:${field.id}` === id) {
                      return {
                        ...field,
                        value: fileUrl,
                        signPreview: e.target?.result,
                      };
                    } else return { ...field };
                  }),
                };
              } else return { ...page };
            } else
              return {
                ...page,
                fields: page.fields.map((field) => {
                  if (`${field.fieldType}:${field.id}` === id) {
                    return {
                      ...field,
                      value: fileUrl,
                      signPreview: e.target?.result,
                    };
                  } else return { ...field };
                }),
              };
          })
        );
        setIsOpen(false);
      }
    };
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      e.stopPropagation();

      // 서명하기 버튼 클릭 시 모달 닫지 않음
      const target = e.target as HTMLElement;
      if (target.closest(".sender")) {
        return;
      }

      if (
        signatureRef.current === e.target ||
        !signatureRef.current?.contains(e.target as Node)
      ) {
        setShowButton(false);
        showButton && setSelectedFieldId && setSelectedFieldId("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setSelectedFieldId]);

  return (
    <>
      <SignatureStyled
        id={id}
        type={type}
        color={
          type === "sender"
            ? "#666F7B"
            : (fields.find((field) => `${field.fieldType}:${field.id}` === id)
                ?.writer?.color as string)
        }
        required={
          !!fields.find((field) => `${field.fieldType}:${field.id}` === id)
            ?.required
        }
        scale={scale}
        draggable={draggable}
        complete={complete}
        newField={newField}
        isMobile={isMobile || false}
        {...args}
      >
        <div className="wrapper">
          <div className="field-container">
            <div
              ref={signatureRef}
              className="container signature"
              onClick={(e) => {
                e.stopPropagation();
                setShowButton(true);
                setShowOptions(true);
                selectField && selectField(id, id.split(":")[0] as Field);
              }}
              style={{
                width: `${(currentSize.w / 10) * scale}rem`,
                height: isMobile
                  ? `${1.4 * scale}rem`
                  : `${(currentSize.h / 10) * scale}rem`,
              }}
            >
              {edit ? (
                <div className="edit">
                  {fields.find(
                    (field) => `${field.fieldType}:${field.id}` === id
                  )?.value ||
                  fields.find(
                    (field) => `${field.fieldType}:${field.id}` === id
                  )?.signPreview ? (
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url(${
                          fields.find(
                            (field) => `${field.fieldType}:${field.id}` === id
                          )?.signPreview ||
                          fields.find(
                            (field) => `${field.fieldType}:${field.id}` === id
                          )?.value
                        })`,
                      }}
                    />
                  ) : complete ? (
                    ""
                  ) : type === "receiver" && showButton ? (
                    <div
                      className="option"
                      style={{ width: isMobile ? "10rem" : "32.4rem" }}
                    >
                      <button
                        className="select-sign-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsOpen(true);
                        }}
                      >
                        수신자 서명 선택하기
                      </button>
                    </div>
                  ) : (
                    <>
                      <PencilIcon />
                      {isEdit ? (
                        <textarea
                          value={
                            fields.find(
                              (field) => `${field.fieldType}:${field.id}` === id
                            )?.name
                          }
                          style={{
                            fontSize: `${
                              +(fields.find(
                                (field) =>
                                  `${field.fieldType}:${field.id}` === id
                              )?.size as string) / 10
                            }rem`,
                            lineHeight: 1.4285714286,
                            color: "var(--text-secondary)",
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setIsEdit(false);
                            }
                          }}
                          onChange={(e) => {
                            setPages((prev) =>
                              prev.map((page) => {
                                if (
                                  page.fields
                                    .map(
                                      (field) =>
                                        `${field.fieldType}:${field.id}`
                                    )
                                    .includes(id)
                                ) {
                                  return {
                                    ...page,
                                    fields: page.fields.map((field) => {
                                      if (
                                        `${field.fieldType}:${field.id}` === id
                                      ) {
                                        return {
                                          ...field,
                                          name: e.target.value,
                                        };
                                      } else return { ...field };
                                    }),
                                  };
                                } else return { ...page };
                              })
                            );
                          }}
                        />
                      ) : (
                        <Body3Regular
                          style={{
                            fontSize: `${
                              +(fields.find(
                                (field) =>
                                  `${field.fieldType}:${field.id}` === id
                              )?.size as string) / 10
                            }rem`,
                            lineHeight: 1.4285714286,
                          }}
                          onDoubleClick={
                            !newField && draggable
                              ? () => setIsEdit((prev) => !prev)
                              : undefined
                          }
                        >
                          {
                            fields.find(
                              (field) => `${field.fieldType}:${field.id}` === id
                            )?.name
                          }
                        </Body3Regular>
                      )}
                    </>
                  )}
                </div>
              ) : fields.find(
                  (field) => `${field.fieldType}:${field.id}` === id
                )?.value ? (
                <div
                  className="selectedSignature"
                  style={{
                    backgroundImage: `url(${
                      fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.value
                    })`,
                  }}
                />
              ) : (
                <>
                  <PencilIcon />
                  {isEdit ? (
                    <textarea
                      value={
                        fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.name
                      }
                      style={{
                        fontSize: isMobile
                          ? "0.4rem"
                          : `${
                              +(fields.find(
                                (field) =>
                                  `${field.fieldType}:${field.id}` === id
                              )?.size as string) / 10
                            }rem`,
                        lineHeight: 1.4285714286,
                        color: "var(--text-secondary)",
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setIsEdit(false);
                        }
                      }}
                      onChange={(e) => {
                        setPages((prev) =>
                          prev.map((page) => {
                            if (
                              page.fields
                                .map(
                                  (field) => `${field.fieldType}:${field.id}`
                                )
                                .includes(id)
                            ) {
                              return {
                                ...page,
                                fields: page.fields.map((field) => {
                                  if (`${field.fieldType}:${field.id}` === id) {
                                    return { ...field, name: e.target.value };
                                  } else return { ...field };
                                }),
                              };
                            } else return { ...page };
                          })
                        );
                      }}
                    />
                  ) : (
                    <Body3Regular
                      style={{
                        fontSize: isMobile
                          ? "0.4rem"
                          : `${
                              +(fields.find(
                                (field) =>
                                  `${field.fieldType}:${field.id}` === id
                              )?.size as string) / 10
                            }rem`,
                        lineHeight: 1.4285714286,
                      }}
                      onDoubleClick={
                        !newField && draggable
                          ? () => setIsEdit((prev) => !prev)
                          : undefined
                      }
                    >
                      {
                        fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.name
                      }
                    </Body3Regular>
                  )}
                </>
              )}
            </div>
            {!newField && draggable && id === selectedFieldId && (
              <Resizable
                pages={pages}
                setPages={setPages}
                selectedFieldId={selectedFieldId}
                selectedPageId={selectedPageId}
                handleMouseUp={handleMouseUp}
                handleMouseDown={handleMouseDown}
                handleMouseMove={handleMouseMove}
                color={
                  type === "sender"
                    ? "#666F7B"
                    : !!fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.required
                    ? "red"
                    : "var(--blue-500)"
                }
                scale={scale}
              />
            )}
          </div>
          {draggable && id === selectedFieldId && (
            <DeleteIcon onClick={() => onDeleteHandler(id)} />
          )}
        </div>
        {/* 선택했을 때 => 기본값 */}
        {/* 계약서 작성 - 필드 배치할 때 */}
        {/* 템플릿 작성 - 필드 배치할 때 + 수신자인 경우만 */}
        {/* 템플릿 전송 - 미리보기에서 + 발신자인 경우만 */}
        {selectedFieldId === id &&
          showOptions &&
          (draggable ||
            (draggable && template && type === "receiver") ||
            (templateSend && type === "sender")) && (
            <div
              className={`options${
                type === "receiver" ? " receiver" : " sender"
              }`}
              style={{
                width: isMobile || type === "receiver" ? "16rem" : "32.4rem",
                padding: isMobile ? "0.8rem" : "2rem",
                borderRadius: isMobile ? "0.4rem" : "1.487rem",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {type === "sender" && (
                <div className="option">
                  <div className="option-header">
                    <button
                      type="button"
                      className="select"
                      onClick={
                        setSignatureSelectionModal
                          ? () => setSignatureSelectionModal(true)
                          : undefined
                      }
                    >
                      발신자 서명 선택하기
                    </button>
                    {isMobile && (
                      <button
                        className="close-button"
                        onClick={() => setShowOptions(false)}
                      >
                        <DeleteIcon />
                      </button>
                    )}
                  </div>
                </div>
              )}
              {!templateSend && type === "receiver" && (
                <div className="option">
                  <RequiredOption
                    fields={fields}
                    setPages={setPages}
                    selectedPageId={selectedPageId}
                    selectedFieldId={selectedFieldId}
                  />
                </div>
              )}
            </div>
          )}
      </SignatureStyled>
      {isMobile ? (
        <CreateSignBottomSheet
          isOpen={isOpen}
          closeBottomSheet={closeModal}
          sign={sign}
        />
      ) : (
        <CreateSignModal
          isOpen={isOpen}
          closeModal={closeModal}
          signMode
          sign={sign}
        />
      )}
    </>
  );
}

const SignatureStyled = styled(DraggableStyled)<{
  isMobile: boolean;
}>`
  .wrapper {
    .container {
      padding: ${({ scale, isMobile }) =>
        isMobile ? "0" : scale ? `0 ${scale * 1}rem` : "0 1rem"};
      box-sizing: border-box;
      display: flex;
      column-gap: 0.4rem;
      border-radius: ${({ scale }) => (scale ? `${scale * 0.4}rem` : "0.4rem")};
      color: var(--text-secondary);

      .selectedSignature {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .edit {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${({ scale, fontSize }) =>
          scale
            ? `${scale * (fontSize ? +fontSize / 10 : 1.6)}rem`
            : `${fontSize ? +fontSize / 10 : 1.6}rem`};

        .img {
          display: block;
          margin: 0 auto;
          width: 100%;
          height: 100%;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
        }

        .option {
          position: absolute;
          bottom: ${({ isMobile }) => (isMobile ? "-3rem" : "-6.4rem")};
          left: 50%;
          transform: translateX(-50%);
          padding: ${({ isMobile }) => (isMobile ? "0.6rem" : "1rem")};
          background-color: var(--white);
          border-radius: 0.4rem;
          box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0.08);
          z-index: 1;

          .select-sign-button {
            width: 100%;
            padding: ${({ isMobile }) => (isMobile ? "0.2rem 0" : "0.8rem 0")};
            background: none;
            border: 0.05rem solid var(--text-default);
            border-radius: 0.4rem;
            color: var(--text-default);
            cursor: pointer;
            font-size: ${({ isMobile }) => (isMobile ? "0.6rem" : "1.6rem")};
            font-weight: 600;
            line-height: ${({ isMobile }) => (isMobile ? "1.2rem" : "2rem")};
            letter-spacing: -0.03em;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .sender {
    border-radius: 1.487rem;

    .option-header {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;

      .select {
        flex: 1;
        padding: 0.8rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.19rem;
        font-weight: 600;
        line-height: 1.78rem;
        letter-spacing: -0.03em;
        color: var(--text-default);
        border: 0.1rem solid var(--text-default);
        border-radius: 0.372rem;
      }

      .close-button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }
  }

  .receiver {
    width: 20rem;
    display: flex;
    align-items: center;
  }

  .receiver.edit {
    width: 34.2rem;
  }

  .select-sign-button {
    padding: 0.8rem 1.2rem;
    background: none;
    border: 0.1rem solid var(--text-default);
    border-radius: 0.4rem;
    color: var(--text-default);
    cursor: pointer;
    font-size: 1.2rem;
    white-space: nowrap;

    &:hover {
      background: var(--bg-dark);
    }
  }
`;

import React, { useState } from "react";
import { toast } from "react-toastify";
import { useController, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Button from "components/Common/Button/Button";
import Input from "components/Common/Input/Input";
import Expired from "components/Sign/Expired";
import Invalid from "components/Sign/Invalid";
import { REGEX } from "config/regex";
import useSignVerifyMutation from "hooks/mutations/sign/useSignVerifyMutation";
import { EmailReq } from "models/Sign";

export default function Email() {
  const navigate = useNavigate();
  const [isExpired, setIsExpired] = useState({
    expired: false,
    date: "",
  });
  const [isInvalid, setIsInvalid] = useState({
    invalid: false,
    date: "",
    reason: "",
    name: "",
  });
  const [query] = useSearchParams();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<EmailReq>({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const { mutate } = useSignVerifyMutation();

  const { field: email } = useController({
    name: "email",
    control,
    rules: {
      required: "true",
    },
  });

  const handleSubmitForm = (data: { email: string }) => {
    const ci = query.get("ci");
    const ri = query.get("ri");

    if (!ci || !ri) return;

    const req = { ...data, ci, ri, verifyType: "EMAIL" as const };

    mutate(
      { verifyType: "EMAIL", data: req },
      {
        onSuccess: (res) => {
          const {
            senderName,
            senderEmail,
            receiverName,
            securityVerifyType,
            accessJwt,
          } = res.contractInfo;

          navigate(`/receiver/verification/login?ci=${ci}&ri=${ri}`, {
            state: {
              senderName,
              senderEmail,
              receiverName,
              securityVerifyType,
              accessJwt,
            },
          });
        },
        onError: (error) => {
          if (error?.response?.data.message === "만료된 계약입니다.") {
            setIsExpired((prev) => ({
              ...prev,
              expired: true,
              date: error?.response?.data.extras?.expiredDate || "",
            }));
          } else if (
            error?.response?.data.message === "거절/취소된 계약입니다."
          ) {
            const {
              denyAt: date,
              denyUserName: name,
              denyReason: reason,
            } = error?.response?.data.extras;
            setIsInvalid((prev) => ({
              ...prev,
              invalid: true,
              date,
              name,
              reason,
            }));
          } else if (
            error?.response?.data.message ===
            "계약 수신 정보를 찾을 수 없습니다."
          ) {
            toast("계약 수신 정보를 찾을 수 없습니다.");
          }
        },
      }
    );
  };

  return (
    <>
      <EmailStyled onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="title-container">
          <div className="title">수신자 본인 확인</div>
          <div className="guide">
            전자문서의 보안을 위해,
            <br />
            해당 문서를 수신한 <span>이메일 주소</span>를 입력해주세요.
          </div>
        </div>
        <div className="input-and-button">
          <Input
            placeholder="이메일을 입력해 주세요."
            width="53rem"
            {...register("email", {
              required: "이메일을 입력해주세요.",
              pattern: {
                value: REGEX.email,
                message: "이메일 형식이 올바르지 않습니다.",
              },
            })}
            value={email.value}
            isError={errors.email ? true : false}
            errorMessage={errors.email?.message}
          />
          <Button
            type="submit"
            colorType="primary"
            size="large"
            disabled={!isValid}
          >
            확인
          </Button>
        </div>
      </EmailStyled>
      {isExpired.expired && <Expired date={isExpired.date} />}
      {isInvalid.invalid && (
        <Invalid
          date={isInvalid.date}
          name={isInvalid.name}
          reason={isInvalid.reason}
        />
      )}
    </>
  );
}

export const EmailStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 6rem;

  .title-container {
    text-align: center;
    width: max-content;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    .title {
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 3.6rem;
      letter-spacing: -0.03em;
    }

    .guide {
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: -0.03em;

      span {
        color: var(--text-middle-emphasis);
      }
    }
  }

  .input-and-button {
    display: flex;
    flex-direction: column;
    row-gap: 4rem;

    button {
      width: 100%;
    }
  }
`;

import Button from "components/Common/Button/Button";
import Input from "components/Common/Input/Input";
import {
  Body1Medium,
  Body1SemiBold,
  Body2Medium,
  Body3Regular,
} from "components/TextStyle";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "assets/common/20px/icon_plus.svg";
import { ReactComponent as DeleteIcon } from "assets/common/20px/icon_close_small.svg";
import { useFieldArray, useFormContext } from "react-hook-form";
import useContractRemainCreditsQuery from "hooks/queries/contract/useContractRemainCreditsQuery";
import ActionModal from "components/Common/Modal/ActionModal";
import Toggle from "components/Common/Toggle/Toggle";
import { VerificationModalStyled } from "components/Contract/VerificationModal";
import useUserState from "hooks/recoil/useUserState";
import useTemplateDetailInfoQuery from "hooks/queries/template/useTemplateDetailInfoQuery";
import { useNavigate, useParams } from "react-router";
import {
  formatPhoneNumberInput,
  isValidEmail,
  isValidPhoneNumberInput,
  formatEmailInput,
  formatNameInput,
} from "utils/utility";
import { ContactDto } from "models/Contact";
import useContactGroupsContactsAllQuery from "hooks/queries/contact/useContactGroupsContactsAllQuery";
import { ConfigFormData } from "pages/template/send/detail/config";

export interface SingleReceiverConfigFormData {
  receivers: {
    name: string;
    email: string;
    phoneNumber: string;
    verification: {
      password: {
        isUsed: boolean;
        password: string;
      };
      phoneNumber: {
        isUsed: boolean;
      };
    };
  }[];
}

const SingleReceiverConfigForm = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const [user] = useUserState();
  const [phoneErrors, setPhoneErrors] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [emailErrors, setEmailErrors] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [verificationModal, setVerificationModal] = useState({
    opened: false,
    index: 99,
    prevData: {
      password: {
        isUsed: false,
        password: "",
      },
      phoneNumber: {
        isUsed: false,
      },
    },
  });
  const [contacts, setContacts] = useState<ContactDto[]>([]);
  const { data: contactsData } = useContactGroupsContactsAllQuery(
    user?.organization
  );
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );

  const { control, register, getValues, setValue } =
    useFormContext<SingleReceiverConfigFormData>();
  const { getValues: getConfigValues } = useFormContext<ConfigFormData>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "receivers",
  });

  const { data: remainContractsCreditDto, refetch } =
    useContractRemainCreditsQuery();

  const { data: templateDetailInfo } = useTemplateDetailInfoQuery({
    organizationId: user?.organization,
    templateId,
  });

  // 연락처 데이터 설정
  useEffect(() => {
    if (!contactsData) return;
    const allContacts: ContactDto[] = [];
    contactsData.forEach((group) => {
      allContacts.push(...group.contactGroupsAndContacts.contactDtos);
    });
    setContacts(allContacts);
  }, [contactsData]);

  const isPossibleToAddReceiver = () => {
    if (!remainContractsCreditDto) return true;
    if (
      (remainContractsCreditDto?.remainContractCredit.remainCredits as number) -
        getValues("receivers").length >
      0
    )
      return true;
    else return false;
  };

  const isConfirmButtonActive = () => {
    if (
      (fields[verificationModal.index].verification.password.isUsed &&
        fields[verificationModal.index].verification.password.password.length <
          4) ||
      fields[verificationModal.index].verification.password.password.length > 12
    ) {
      return false;
    } else return true;
  };

  const handleAddReceiverClick = () => {
    if (!remainContractsCreditDto?.remainContractCredit.remainCredits) {
      refetch();
      return;
    } else if (!isPossibleToAddReceiver()) return;

    append({
      name: "",
      email: "",
      phoneNumber: "",
      verification: {
        password: {
          isUsed: getConfigValues("password.isUsed"),
          password: getConfigValues("password.password"),
        },
        phoneNumber: {
          isUsed: getConfigValues("isPhoneNumberAuth.isUsed"),
        },
      },
    });
  };

  const onCloseHandler = () => {
    update(verificationModal.index, {
      ...fields[verificationModal.index],
      verification: verificationModal.prevData,
    });

    setVerificationModal((prev) => ({ ...prev, opened: false, index: 99 }));
  };

  useEffect(() => {
    if (
      (templateDetailInfo?.contractTemplateDetails.receiverCnt as number) > 1
    ) {
      navigate(`/template/send/${templateId}/config/many`);
    }
  }, [templateDetailInfo?.contractTemplateDetails.receiverCnt]);

  useEffect(() => {
    if (verificationModal.opened) {
      setVerificationModal((prev) => ({
        ...prev,
        prevData: fields[verificationModal.index].verification,
      }));
    }
  }, [verificationModal.opened]);

  const handleNameChange = (index: number, value: string) => {
    const formattedName = formatNameInput(value);
    setValue(`receivers.${index}.name`, formattedName);

    if (!formattedName) {
      setOpenDropdownIndex(index);
    } else {
      setOpenDropdownIndex(null);
    }
  };

  const handlePhoneNumberChange = (index: number, value: string) => {
    const formatted = formatPhoneNumberInput(value);
    setValue(`receivers.${index}.phoneNumber`, formatted || "");

    setPhoneErrors((prev) => ({
      ...prev,
      [index]: !isValidPhoneNumberInput(formatted || "") && value !== "",
    }));
  };

  const handleEmailChange = (index: number, value: string) => {
    const formattedEmail = formatEmailInput(value);
    setValue(`receivers.${index}.email`, formattedEmail);

    setEmailErrors((prev) => ({
      ...prev,
      [index]: value !== "" && !isValidEmail(formattedEmail),
    }));
  };

  const handleNameInputClick = (index: number) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handleContactSelect = (index: number, contact: ContactDto) => {
    setValue(`receivers.${index}.name`, contact.contactName);
    setValue(`receivers.${index}.email`, contact.contactEmail);
    setValue(`receivers.${index}.phoneNumber`, contact.contactPhoneNumber);
    setOpenDropdownIndex(null);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".name-input-container")) {
        setOpenDropdownIndex(null);
      }
    };

    // 드롭다운이 열려있을 때만 이벤트 리스너 추가
    if (openDropdownIndex !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // 컴포넌트 언마운트 또는 openDropdownIndex 변경 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdownIndex]);

  return (
    <>
      <SingleReceiverLayout>
        <div className="single-receiver-form-title">
          <Body1SemiBold>수신자</Body1SemiBold>
          <LeftSendTitle>
            잔여건수:{" "}
            {(
              (remainContractsCreditDto?.remainContractCredit
                .remainCredits as number) || 0
            ).toLocaleString("ko")}
            건
          </LeftSendTitle>
        </div>
        {((remainContractsCreditDto?.remainContractCredit.remainCredits ||
          0) === 0
          ? []
          : fields
        ).map((field, index) => (
          <div key={field.id} className="single-receiver-form-from">
            <div>
              <div className="name-input-container">
                <Input
                  placeholder="이름"
                  {...register(`receivers.${index}.name` as const, {
                    onChange: (e) => handleNameChange(index, e.target.value),
                  })}
                  onClick={() => handleNameInputClick(index)}
                />
                {openDropdownIndex === index && (
                  <ul className="contact-dropdown">
                    {contacts
                      .filter((contact) =>
                        contact.contactName
                          .toLowerCase()
                          .includes((field.name || "").toLowerCase())
                      )
                      .map((contact) => (
                        <li
                          key={contact.contactUuid}
                          onClick={() => handleContactSelect(index, contact)}
                        >
                          {contact.contactName}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
              <InputWrapper>
                <Input
                  placeholder="이메일"
                  {...register(`receivers.${index}.email` as const, {
                    onChange: (e) => handleEmailChange(index, e.target.value),
                  })}
                  isError={emailErrors[index]}
                  errorMessage={"이메일 형식이 올바르지 않습니다."}
                />
              </InputWrapper>

              <InputWrapper>
                <Input
                  placeholder="휴대폰 번호"
                  {...register(`receivers.${index}.phoneNumber` as const, {
                    onChange: (e) =>
                      handlePhoneNumberChange(index, e.target.value),
                  })}
                  isError={phoneErrors[index]}
                  errorMessage="휴대폰 번호 형식이 올바르지 않습니다."
                  maxLength={13}
                />
              </InputWrapper>
              <div
                style={{
                  marginLeft: "2.4rem",
                }}
              >
                <Body1Medium>
                  {field.verification.password.isUsed &&
                  field.verification.password.password
                    ? "암호 "
                    : field.verification.phoneNumber.isUsed
                    ? "보안"
                    : `개별`}
                  인증:{" "}
                </Body1Medium>
                <Button
                  colorType="tertiary"
                  size="small"
                  onClick={() =>
                    setVerificationModal((prev) => ({
                      ...prev,
                      opened: true,
                      index,
                    }))
                  }
                >
                  {(field.verification.password.isUsed &&
                    field.verification.password.password) ||
                  field.verification.phoneNumber.isUsed
                    ? "변경"
                    : "설정"}
                  하기
                </Button>
              </div>
            </div>
            {fields.length > 1 && <DeleteIcon onClick={() => remove(index)} />}
          </div>
        ))}
      </SingleReceiverLayout>
      <AddReceiverButton
        possible={isPossibleToAddReceiver()}
        onClick={handleAddReceiverClick}
      >
        <Body3Regular
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isPossibleToAddReceiver() ? "수신자 추가하기" : "잔여 건수 부족"}
          {isPossibleToAddReceiver() && <PlusIcon />}
        </Body3Regular>
      </AddReceiverButton>
      {verificationModal.opened && (
        <ActionModal
          title="인증 수단 추가"
          disableCloseWithOutsideClickOrEsc
          handleModalClose={onCloseHandler}
          handleCancelButton={onCloseHandler}
          handleConfirmButton={() =>
            setVerificationModal((prev) => ({
              ...prev,
              opened: false,
              index: 99,
            }))
          }
          disabledConfirmButton={!isConfirmButtonActive()}
        >
          <VerificationModalStyled>
            <div className="password">
              <div className="label">
                <Toggle
                  isSelected={
                    fields[verificationModal.index].verification.password
                      .isUsed as boolean
                  }
                  handleToggle={() => {
                    setValue(
                      `receivers.${verificationModal.index}.verification.password.isUsed`,
                      !getValues(
                        `receivers.${verificationModal.index}.verification.password.isUsed`
                      )
                    );

                    if (
                      !getValues(
                        `receivers.${verificationModal.index}.verification.password.isUsed`
                      )
                    ) {
                      setValue(
                        `receivers.${verificationModal.index}.verification.password.password`,
                        ""
                      );
                    }

                    if (
                      getValues(
                        `receivers.${verificationModal.index}.verification.phoneNumber.isUsed`
                      ) &&
                      getValues(
                        `receivers.${verificationModal.index}.verification.password.isUsed`
                      )
                    ) {
                      setValue(
                        `receivers.${verificationModal.index}.verification.phoneNumber.isUsed`,
                        false
                      );
                    }
                  }}
                />
                <Body2Medium>암호 인증</Body2Medium>
              </div>
              <Input
                type="text"
                placeholder="암호 입력 (4~12자)"
                minLength={4}
                maxLength={12}
                value={
                  fields[verificationModal.index].verification.password.password
                }
                setValue={(password: string) =>
                  setValue(
                    `receivers.${verificationModal.index}.verification.password.password`,
                    password
                  )
                }
                isDisabled={
                  !fields[verificationModal.index].verification.password.isUsed
                }
              />
            </div>
            <div className="secure">
              <div className="label">
                <Toggle
                  isSelected={
                    fields[verificationModal.index].verification.phoneNumber
                      .isUsed
                  }
                  handleToggle={() => {
                    setValue(
                      `receivers.${verificationModal.index}.verification.phoneNumber.isUsed`,
                      !getValues(
                        `receivers.${verificationModal.index}.verification.phoneNumber.isUsed`
                      )
                    );

                    if (
                      getValues(
                        `receivers.${verificationModal.index}.verification.password.isUsed`
                      ) &&
                      getValues(
                        `receivers.${verificationModal.index}.verification.phoneNumber.isUsed`
                      )
                    ) {
                      setValue(
                        `receivers.${verificationModal.index}.verification.password.isUsed`,
                        false
                      );
                      setValue(
                        `receivers.${verificationModal.index}.verification.password.password`,
                        ""
                      );
                    }
                  }}
                />
                <Body2Medium>보안 인증</Body2Medium>
              </div>
              <div>
                <div
                  className={`message${
                    fields[verificationModal.index].verification.phoneNumber
                      .isUsed
                      ? " selected"
                      : ""
                  }`}
                >
                  <Body1SemiBold>휴대폰 본인인증</Body1SemiBold>
                </div>
              </div>
            </div>
          </VerificationModalStyled>
        </ActionModal>
      )}
    </>
  );
};

export default SingleReceiverConfigForm;

const SingleReceiverLayout = styled.div`
  margin-bottom: 3.2rem;
  .single-receiver-form-title {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-bottom: 2rem;
  }

  .single-receiver-form-from {
    display: flex;
    justify-content: space-between;
    gap: 0.8rem;
    align-items: center;
    margin-bottom: 2rem;

    > div {
      display: flex;
      gap: 1.2rem;
      align-items: center;
    }

    > div > div {
      display: flex;
      gap: 1.2rem;
      align-items: center;
    }

    svg {
      cursor: pointer;
    }

    input {
      margin-top: 0;
      margin-left: 0;
    }

    .name-input-container {
      position: relative;

      .contact-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        background: white;
        border: 1px solid var(--border-default);
        border-radius: 8px;
        margin-top: 4px;
        padding: 4px 0;
        z-index: 1000;
        color: var(--text-secondary);

        li {
          padding: 8px 16px;
          cursor: pointer;

          &:hover {
            background-color: var(--background-hover);
          }
        }
      }
    }
  }
`;

const LeftSendTitle = styled(Body1Medium)`
  color: var(--text-emphasis);
`;

const AddReceiverButton = styled.button<{ possible: boolean }>`
  width: 100%;
  padding: 3rem 0;
  border-radius: 12px;
  border: ${({ possible }) =>
    possible ? "1.5px dashed #3182F659" : "1.5px dashed #e71f2a"};
  background: ${({ possible }) => (possible ? "#d7e5ff59" : "#fff9f9")};
  margin-bottom: 4rem;
  color: ${({ possible }) => (possible ? " var(--text-secondary)" : "#E71F2A")};
  cursor: ${({ possible }) => (possible ? "pointer" : "default")};
`;

const InputWrapper = styled.div`
  position: relative;

  .errorMsg {
    position: absolute;
    top: 100%;
    white-space: nowrap;
  }
`;

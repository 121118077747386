export const JWT_KEY = "jwt";
export const JWT_REFRESH_KEY = "refreshJwt";
export const CONTRACT_SEND_PATH = "/contract/send";
export const ALLOWED_FILE_TYPES = [
  "pdf",
  "png",
  "jpg",
  "jpeg",
  "doc",
  "hwp",
  "hwpx",
  "vnd.openxmlformats-officedocument.wordprocessingml.document",
];
export const IMAGE_TYPES = ["image/png", "image/jpeg"];
export const ATTACHMENTS = [...IMAGE_TYPES, "application/pdf", "hwp", "hwpx"];
export const MAX_FILE_SIZE = 10 * 1024 * 1024;
export const SCALES = [50, 75, 100, 120, 150, 200];
export const FONT_SIZES = [
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "24",
  "30",
  "36",
  "48",
  "60",
];
export const MAIL_TEMPLATE_OPTIONS = [
  { value: "DEFAULT", label: "메일 템플릿(기본)" },
];
export const PALETTE = {
  sender: "#666F7B",
  receivers: [
    "#F8A1FF",
    "#FFCB67",
    "#EFF176",
    "#D5ED7E",
    "#95F2FF",
    "#6A9DF8",
    "#AD96FF",
    "#5CFF82",
    "#33FFF3",
    "#F7752D",
  ],
};

import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";
import useUserState from "hooks/recoil/useUserState";

const useContractCheckPhoneVerifyQuery = (organizationUuid: string) => {
  const [user] = useUserState();
  return useQuery({
    ...queries.contract.checkPhoneVerify(organizationUuid),
    enabled: !!user.organization,
  });
};

export default useContractCheckPhoneVerifyQuery;

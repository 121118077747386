import React, { useState } from "react";
import {
  TableBody,
  TableContainer,
  TableHeadCell,
  TableHeader,
  TableLayout,
  TableRow,
} from "../Table/Table";
import CheckBox from "components/Common/CheckBox/CheckBox";
import { Body2SemiBold } from "components/TextStyle";
import DashboardRow from "./DashboardRow";
import { Template } from "models/Template";
import { User } from "models/User";
import { format } from "date-fns";
import useUserState from "hooks/recoil/useUserState";

const DashboardTable = ({
  checkedRow,
  setCheckedRow,
  templateList,
}: {
  checkedRow: string[];
  setCheckedRow: React.Dispatch<React.SetStateAction<string[]>>;
  templateList: {
    templateUuid: Template["id"];
    isFavorite: boolean;
    isCreatedUser: boolean;
    templateStatus: "COMPLETE" | "TEMP";
    templateName: Template["name"];
    receiverCount: number;
    createdUserName: User["name"];
    createAt: Date;
    updatedAt: Date;
  }[];
}) => {
  const [allChecked, setAllChecked] = useState(false);
  const [user] = useUserState();
  const handleAllChecked = () => {
    if (allChecked) {
      setCheckedRow([]);
    } else {
      setCheckedRow(templateList.map((item) => item.templateUuid));
    }
    setAllChecked((pre) => !pre);
  };
  return (
    <>
      <TableLayout>
        <TableContainer gridTemplateColumns="auto auto auto 1fr auto auto auto auto auto">
          <TableHeader>
            <TableRow>
              <TableHeadCell>
                <CheckBox
                  onChange={handleAllChecked}
                  checked={allChecked}
                  isDisabled={user.organizationGroup_permission === "MEMBER"}
                />
              </TableHeadCell>
              <TableHeadCell></TableHeadCell>
              <TableHeadCell>
                <Body2SemiBold>상태</Body2SemiBold>
              </TableHeadCell>
              <TableHeadCell>
                <Body2SemiBold>템플릿 이름</Body2SemiBold>
              </TableHeadCell>
              <TableHeadCell>
                <Body2SemiBold>등록자</Body2SemiBold>
              </TableHeadCell>
              <TableHeadCell>
                <Body2SemiBold>생성일</Body2SemiBold>
              </TableHeadCell>
              <TableHeadCell>
                <Body2SemiBold>마지막 수정일</Body2SemiBold>
              </TableHeadCell>
              <TableHeadCell></TableHeadCell>
              <TableHeadCell></TableHeadCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {templateList.map((template) => {
              return (
                <DashboardRow
                  {...template}
                  id={template.templateUuid}
                  isBookmark={template.isFavorite}
                  status={template.templateStatus}
                  templateName={template.templateName}
                  receiverCount={template.receiverCount}
                  register={
                    template.isCreatedUser
                      ? `나(${template.createdUserName})`
                      : template.createdUserName
                  }
                  createdAt={format(template.createAt, "yy.MM.dd HH:mm:ss")}
                  updatedAt={format(template.updatedAt, "yy.MM.dd HH:mm:ss")}
                  key={template.templateUuid}
                  onChecked={(e) => {
                    e.stopPropagation();
                    if (e.target.checked) {
                      setCheckedRow([...checkedRow, template.templateUuid]);
                    } else {
                      setCheckedRow(
                        checkedRow.filter((id) => id !== template.templateUuid)
                      );
                    }
                  }}
                  isChecked={checkedRow.includes(template.templateUuid)}
                />
              );
            })}
          </TableBody>
        </TableContainer>
      </TableLayout>
    </>
  );
};

export default DashboardTable;

import React from "react";
import {
  LNBContainer,
  LNBItemContainer,
  LNBItemsContainer,
  LNBSubItemsContainer,
} from "./style";

import { useLocation, useNavigate } from "react-router-dom";
import { MenuItem, SubMenuItem } from "./MenuItems";
import { MENU_ITEMS } from "./MenuItemsConstant";
import { Organization } from "models/Organization";
import { Role } from "models/OrganizationGroup";
import useUserState from "hooks/recoil/useUserState";

export interface MenuItems {
  label: string;
  icon: string;
  activeIcon: string;
  to: string;
  include?: string[];
  permission?: {
    organization: Organization["permission"][];
    organization_group?: Role[];
  };
  subMenu?: {
    label: string;
    to: string;
    include: string[];
    icon: string;
    permission?: {
      organization: Organization["permission"][];
      organization_group?: Role[];
    };
  }[];
  hidden?: boolean;
}

const LNB = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [user] = useUserState();

  return (
    <LNBContainer>
      <LNBItemsContainer>
        {MENU_ITEMS.filter((item) => !item.hidden).map(
          (menuItem: MenuItems, idx: number) => {
            const { subMenu, to, include } = menuItem;
            const isSelectedMenu =
              pathname.split("/")[1].includes(to.split("/")[1]) ||
              include?.some((sub) =>
                sub.split("/")[1].includes(pathname.split("/")[1])
              );

            const isIncludeSubMenu =
              subMenu?.some((sub) => sub.include.includes(pathname)) ?? false;

            const handleMenu = () => navigate(to);

            return (
              <LNBItemContainer key={idx}>
                <MenuItem
                  handleClick={handleMenu}
                  isSelected={isSelectedMenu || isIncludeSubMenu}
                  menuItem={menuItem}
                />

                {isIncludeSubMenu && (
                  <LNBSubItemsContainer>
                    {subMenu?.map((subMenuItem, idx: number) => {
                      const isSelectedSubMenu =
                        subMenuItem.include.includes(pathname);
                      const handleSubMenu = () => navigate(subMenuItem.to);
                      const isOrganizationPermission =
                        !subMenuItem.permission?.organization ||
                        (user.organization_permission &&
                          subMenuItem.permission.organization.includes(
                            user.organization_permission
                          ));

                      return (
                        isOrganizationPermission && (
                          <SubMenuItem
                            key={idx}
                            isSelected={isSelectedSubMenu}
                            subMenuItem={subMenuItem}
                            handleClick={handleSubMenu}
                          />
                        )
                      );
                    })}
                  </LNBSubItemsContainer>
                )}
              </LNBItemContainer>
            );
          }
        )}
      </LNBItemsContainer>
    </LNBContainer>
  );
};

export default LNB;

import { ReactComponent as NumberIcon } from "assets/common/20px/icon_square.svg";
import { ReactComponent as DeleteIcon } from "assets/common/24px/icon_close_default.svg";
import Toggle from "components/Common/Toggle/Toggle";
import AlignOption from "components/Contract/Options/Align";
import FontSizeOption from "components/Contract/Options/FontSize";
import RequiredOption from "components/Contract/Options/Required";
import Resizable from "components/Resizable";
import { Body3Regular } from "components/TextStyle";
import { IField, IPage } from "interfaces/contract";
import { TextAlign } from "models/Contract";
import { Field } from "pages/contract/send";
import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { DraggableStyled } from "./styles";

interface NumberProps extends React.HTMLAttributes<HTMLDivElement> {
  edit?: boolean;
  complete?: boolean;
  id: string;
  type: "sender" | "receiver";
  fields: IField[];
  pages: IPage[];
  setPages: React.Dispatch<React.SetStateAction<IPage[]>>;
  selectedPageId: string;
  setSelectedFieldId?: React.Dispatch<React.SetStateAction<string>>;
  selectedFieldId?: string;
  onDeleteHandler: (id: string) => void;
  selectField?: (id: string, type: Field) => void;
  scale: number;
  align?: ({
    type,
    align,
    all,
  }: {
    type: Field;
    align?: TextAlign | undefined;
    all?: boolean | undefined;
  }) => void;
  pageContainerRef?: any;
  currentSize: { w: number; h: number };
  handleMouseDown: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>,
    direction: string
  ) => void;
  handleMouseMove: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>
  ) => void;
  handleMouseUp: () => void;
  onChangeHandler?: (id: string, value: string) => void;
  newField?: boolean;
  isMobile?: boolean;
}

export default function Number({
  edit = false,
  complete = false,
  id,
  type,
  fields,
  pages,
  setPages,
  selectedPageId,
  setSelectedFieldId,
  selectedFieldId,
  onDeleteHandler,
  selectField,
  scale,
  draggable,
  align,
  currentSize,
  pageContainerRef,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  onChangeHandler,
  newField,
  isMobile,
  ...args
}: NumberProps) {
  const [isEdit, setIsEdit] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleCommas = () => {
    setPages((prev) =>
      prev.map((page) => ({
        ...page,
        fields: page.fields.map((field) => {
          if (`${field.fieldType}:${field.id}` === selectedFieldId) {
            return { ...field, commas: !field.commas };
          } else return { ...field };
        }),
      }))
    );
  };

  return (
    <NumberStyled
      id={id}
      type={type}
      color={
        type === "sender"
          ? "#666F7B"
          : (fields.find((field) => `${field.fieldType}:${field.id}` === id)
              ?.writer?.color as string)
      }
      required={
        !!fields.find((field) => `${field.fieldType}:${field.id}` === id)
          ?.required
      }
      scale={scale}
      draggable={draggable}
      align={
        fields.find((field) => `${field.fieldType}:${field.id}` === id)?.align
      }
      fontSize={
        fields.find((field) => `${field.fieldType}:${field.id}` === id)?.size
      }
      complete={complete}
      newField={newField}
      isMobile={isMobile}
      {...args}
    >
      <div className="wrapper">
        <div className="field-container">
          <div
            className="container number"
            onClick={(e) => {
              e.stopPropagation();
              selectField && selectField(id, id.split(":")[0] as Field);
            }}
            style={{
              width: `${(currentSize.w / 10) * scale}rem`,
              height: isMobile
                ? `${1.4 * scale}rem`
                : `${(currentSize.h / 10) * scale}rem`,
            }}
          >
            {edit ? (
              <input
                type="text"
                style={{ fontSize: isMobile ? "0.8rem" : "" }}
                placeholder={
                  complete || isFocused
                    ? ""
                    : fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.description || ""
                }
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                value={
                  isMobile
                    ? fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.value
                      ? fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.commas
                        ? (+fields.find(
                            (field) => `${field.fieldType}:${field.id}` === id
                          )?.value).toLocaleString()
                        : fields.find(
                            (field) => `${field.fieldType}:${field.id}` === id
                          )?.value
                      : ""
                    : fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.value
                    ? fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.commas
                      ? (+fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.value).toLocaleString()
                      : fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.value
                    : ""
                }
                onChange={(e) =>
                  onChangeHandler &&
                  onChangeHandler(id, e.target.value.replace(/[^0-9]/gi, ""))
                }
                onKeyDown={(e) => {
                  if (
                    (e.keyCode > 47 && e.keyCode < 58) ||
                    e.keyCode === 8 ||
                    e.keyCode === 37 ||
                    e.keyCode === 39 ||
                    e.keyCode === 46
                  ) {
                    return;
                  } else {
                    toast("숫자만 입력 가능합니다.", {
                      toastId: "only-number",
                    });
                  }
                }}
                disabled={complete}
              />
            ) : (
              <>
                <NumberIcon />
                {isEdit ? (
                  <textarea
                    value={
                      fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.name
                    }
                    style={{
                      fontSize: isMobile
                        ? "0.4rem"
                        : `${
                            (+(fields.find(
                              (field) => `${field.fieldType}:${field.id}` === id
                            )?.size as string) /
                              10) *
                            scale
                          }rem`,
                      lineHeight: 1.4285714286,
                      color: "var(--text-secondary)",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsEdit(false);
                      }
                    }}
                    onChange={(e) => {
                      setPages((prev) =>
                        prev.map((page) => {
                          if (
                            page.fields
                              .map((field) => `${field.fieldType}:${field.id}`)
                              .includes(id)
                          ) {
                            return {
                              ...page,
                              fields: page.fields.map((field) => {
                                if (`${field.fieldType}:${field.id}` === id) {
                                  return { ...field, name: e.target.value };
                                } else return { ...field };
                              }),
                            };
                          } else return { ...page };
                        })
                      );
                    }}
                  />
                ) : (
                  <Body3Regular
                    style={{
                      fontSize: isMobile
                        ? "0.4rem"
                        : `${
                            (+(fields.find(
                              (field) => `${field.fieldType}:${field.id}` === id
                            )?.size as string) /
                              10) *
                            scale
                          }rem`,
                      lineHeight: 1.4285714286,
                    }}
                    onDoubleClick={
                      !newField && draggable
                        ? () => setIsEdit((prev) => !prev)
                        : undefined
                    }
                  >
                    {
                      fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.name
                    }
                  </Body3Regular>
                )}
              </>
            )}
          </div>
          {!newField && draggable && id === selectedFieldId && (
            <Resizable
              pages={pages}
              setPages={setPages}
              selectedFieldId={selectedFieldId}
              selectedPageId={selectedPageId}
              handleMouseUp={handleMouseUp}
              handleMouseDown={handleMouseDown}
              handleMouseMove={handleMouseMove}
              color={
                type === "sender"
                  ? "#666F7B"
                  : !!fields.find(
                      (field) => `${field.fieldType}:${field.id}` === id
                    )?.required
                  ? "red"
                  : "var(--blue-500)"
              }
              scale={scale}
            />
          )}
        </div>
        {draggable && id === selectedFieldId && (
          <DeleteIcon onClick={() => onDeleteHandler(id)} />
        )}
      </div>
      {draggable && selectedFieldId === id && (
        <div className="options" onClick={(e) => e.stopPropagation()}>
          <div className="option">
            <FontSizeOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="option">
            <AlignOption
              alignFunc={align}
              type="NUMBER"
              selected={
                fields.find((field) => `${field.fieldType}:${field.id}` === id)
                  ?.align || "LEFT"
              }
            />
          </div>
          <div className="option">
            <Body3Regular>자릿수 구분</Body3Regular>
            <Toggle
              isSelected={
                !!pages
                  .find((page) => page.id === selectedPageId)
                  ?.fields.find(
                    (field) => `${field.fieldType}:${field.id}` === id
                  )?.commas
              }
              handleToggle={handleCommas}
            />
          </div>
          <div className="option">
            <RequiredOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
        </div>
      )}
    </NumberStyled>
  );
}

const NumberStyled = styled(DraggableStyled)`
  .wrapper {
    .container {
      padding: ${({ scale, isMobile }) =>
        isMobile ? "0" : `0 ${(scale as number) * 1}rem`};
      box-sizing: border-box;
      display: flex;
      column-gap: 0.4rem;
      border-radius: ${({ scale }) => (scale ? `${scale * 0.4}rem` : "0.4rem")};
      color: var(--text-secondary);

      input[type="number"] {
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;
        background-color: transparent;
      }
    }
  }

  .options {
    .option {
      .dropdown {
        width: max-content;

        .contentContainer {
          justify-content: flex-start;
          padding: 0;
        }
      }

      .alignOptions {
        display: flex;
        align-items: center;
        column-gap: 0.4rem;
      }
    }
  }
`;

import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import {
  Body1Regular,
  Body1SemiBold,
  Body3Regular,
} from "components/TextStyle";

import { ReactComponent as ClipIcon } from "assets/common/24px/icon_clip.svg";
import { ReactComponent as TrashIcon } from "assets/common/30px/icon_trash.svg";
import SortagblePage from "components/Common/SortableItem/Page";
import Input from "components/Common/Input/Input";
import Tooltip from "components/Common/Tooltip";
import Dropdown from "components/Common/Dropdown/Dropdown";
import { ReactComponent as InfoIcon } from "assets/common/20px/icon_information_small.svg";
import { Field, ParticipantsDropdown } from "pages/contract/send";
import Toggle from "components/Common/Toggle/Toggle";
import { ReactComponent as CheckBoxCheckedIcon } from "assets/common/checkbox/icon_checkbox_selected.svg";
import { ReactComponent as CheckBoxNotCheckedIcon } from "assets/common/checkbox/icon_checkbox_unselected.svg";
import { ReactComponent as RadioButtonCheckedIcon } from "assets/common/radio/icon_radio_selected.svg";
import { ReactComponent as RadioButtonNotCheckedIcon } from "assets/common/radio/icon_radio_unselected.svg";
import {
  IAttachedFile,
  IField,
  IOption,
  IPage,
  IParticipant,
  SelectionMode,
} from "interfaces/contract";
import { v4 as uuidv4 } from "uuid";
import RequiredOption from "./Options/Required";
import FontSizeOption from "./Options/FontSize";
import DescriptionOption from "./Options/Description";
import NameOption from "./Options/Name";
import AlignOption from "./Options/Align";
import CopyOption from "./Options/Copy";
import { ConcurrentSelectType, TextAlign } from "models/Contract";
import { Document, Page } from "react-pdf";
import { getAllFields } from "utils/getAllFields";
import { extractNumbersFromStrings } from "utils/utility";
import ModalLayout from "components/Common/Modal/ModalLayout";

interface IRightProps {
  template?: boolean;
  selectedFieldId: string;
  selectedFieldType: Field | null;
  selectedPageId: string;
  setSelectedPageId: React.Dispatch<React.SetStateAction<string>>;
  fields: IField[];
  pages: IPage[];
  setPages: React.Dispatch<React.SetStateAction<IPage[]>>;
  attachedFiles: IAttachedFile[];
  deleteAttachedFile: (id: string) => void;
  participants: IParticipant[];
  setSignatureSelectionModal?: React.Dispatch<React.SetStateAction<boolean>>;
  align: ({
    type,
    align,
    all,
  }: {
    type: Field;
    align?: TextAlign | undefined;
    all?: boolean | undefined;
  }) => void;
  scrollToPage?: (pageId: string) => void;
}

export default function Right({
  template,
  selectedFieldId,
  selectedFieldType,
  selectedPageId,
  setSelectedPageId,
  fields,
  pages,
  setPages,
  attachedFiles,
  deleteAttachedFile,
  participants,
  setSignatureSelectionModal,
  align,
  scrollToPage,
}: IRightProps) {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [detail, setDetail] = useState<{
    display: boolean;
    id: string;
    pages?: number;
  }>({
    display: false,
    id: "",
    pages: 0,
  });
  // const sensors = useSensors(
  //   useSensor(PointerSensor, {
  //     activationConstraint: {
  //       distance: 10,
  //     },
  //   }),
  //   useSensor(KeyboardSensor, {
  //     coordinateGetter: sortableKeyboardCoordinates,
  //   })
  // );

  // const handleDragEnd = ({ active, over }: DragEndEvent) => {
  //   if (!over) return;

  //   if (active.id !== over.id) {
  //     setPages((prev) => {
  //       const oldIndex = prev.findIndex((page) => page.id === active.id);
  //       const newIndex = prev.findIndex((page) => page.id === over.id);
  //       const newArray = arrayMove(prev, oldIndex, newIndex).map(
  //         (page, id) => ({ ...page, order: id + 1 })
  //       );

  //       return newArray;
  //     });
  //   } else return;
  // };

  const onChangeParticipant = (participant: IParticipant) => {
    setPages((pages: IPage[]) =>
      pages.map((page: IPage) => {
        if (page.id === selectedPageId) {
          return {
            ...page,
            fields: page?.fields?.map((field) => {
              if (`${field.fieldType}:${field.id}` === selectedFieldId)
                return {
                  ...field,
                  type: participant.type,
                  writer: participant,
                };
              else return { ...field };
            }),
          };
        } else return { ...page };
      })
    );

    setDropdownOpened(false);
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) return;

    const number = Math.min(+e.target.value, 100);

    setPages((prev) =>
      prev.map((page) => {
        if (page.id === selectedPageId) {
          return {
            ...page,
            fields: page.fields.map((field) => {
              if (`${field.fieldType}:${field.id}` === selectedFieldId) {
                const countDifference =
                  number - (field.selectCount?.options?.length as number);

                if (countDifference > 0) {
                  const newArray = Array(countDifference)
                    .fill(0)
                    .map((_) => ({
                      id: uuidv4(),
                      label: "",
                      width: "max-content",
                      height: "max-content",
                      position: { x: 0, y: 0 },
                    }));

                  return {
                    ...field,
                    selectCount: {
                      ...field.selectCount,
                      options: field.selectCount?.options?.concat(
                        ...newArray.map((option) => {
                          if (field.selectCount?.options?.length === 1) {
                            return {
                              ...option,
                              width: "max-content",
                              height: "max-content",
                              checked: false,
                              position: {
                                x: field.position.x,
                                y: field.position.y + 60,
                              },
                            };
                          } else
                            return {
                              ...option,
                              width: "max-content",
                              height: "max-content",
                              checked: false,
                              position: {
                                x: field.position.x,
                                y:
                                  ((
                                    (field.selectCount?.options as IOption[])[
                                      (field.selectCount?.options
                                        ?.length as number) - 1
                                    ] as IOption
                                  )?.position?.y || field.position.y) + 60,
                              },
                            };
                        })
                      ),
                    },
                  };
                } else
                  return {
                    ...field,
                    selectCount: {
                      ...field.selectCount,
                      options: field.selectCount?.options?.slice(
                        0,
                        (field.selectCount?.options.length as number) +
                          countDifference
                      ),
                    },
                  };
              } else return { ...field };
            }),
          };
        } else return { ...page };
      })
    );
  };

  return (
    <RightStyled>
      {(!selectedFieldId || !selectedFieldType) && (
        <div className="container default">
          <Body1Regular className="total">
            {selectedPageId
              ? pages?.find((page) => page.id === selectedPageId)?.order
              : 1}
            /{pages.length}페이지
          </Body1Regular>
          <div className="pages">
            {/* <DndContext
              id="dnd-pages"
              sensors={sensors}
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={pages}
                strategy={verticalListSortingStrategy}
              > */}
            {pages.map((page) => (
              <SortagblePage
                key={page.id}
                id={page.id}
                order={page.order}
                page={page}
                selectedPageId={selectedPageId}
                setSelectedPageId={setSelectedPageId}
                scrollToPage={scrollToPage}
              />
            ))}
            {/* </SortableContext>
            </DndContext> */}
            {attachedFiles?.length > 0 && <div className="divider" />}
            {attachedFiles.map(({ fileType, id, sequence, preview }) =>
              fileType === "pdf" ? (
                <Document
                  className="attachment"
                  key={id}
                  file={preview as File}
                >
                  <div className="label">
                    <Body1Regular>{sequence + 1}</Body1Regular>
                    <ClipIcon />
                  </div>
                  <div
                    className="thumbnail-container"
                    onClick={() => setDetail({ display: true, id })}
                  >
                    <Page
                      className="thumbnail"
                      height={140}
                      pageNumber={1}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                    <div className="dimmed" />
                  </div>
                  <div className="functions">
                    <TrashIcon onClick={() => deleteAttachedFile(id)} />
                  </div>
                </Document>
              ) : (
                <div className="attachment" key={id}>
                  <div className="label">
                    <Body1Regular>{sequence + 1}</Body1Regular>
                    <ClipIcon />
                  </div>
                  <div
                    className="thumbnail-container"
                    onClick={() =>
                      setDetail(() => ({
                        display: true,
                        id,
                      }))
                    }
                  >
                    <div
                      className="thumbnail"
                      style={{ backgroundImage: `url(${preview})` }}
                    />
                    <div className="dimmed" />
                  </div>
                  <div className="functions">
                    <TrashIcon onClick={() => deleteAttachedFile(id)} />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}
      {selectedFieldId && selectedFieldType === "SIGN" && (
        <div className="container signatures">
          <div className="field">
            <NameOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          {fields.find(
            (field) => `${field.fieldType}:${field.id}` === selectedFieldId
          )?.type === "receiver" && (
            <>
              <div className="field">
                <RequiredOption
                  side
                  fields={fields}
                  setPages={setPages}
                  selectedPageId={selectedPageId}
                  selectedFieldId={selectedFieldId}
                />
              </div>
              <div className="field">
                <Label>서명자</Label>
                <ParticipantsDropdown
                  dropdownOpened={dropdownOpened}
                  setDropdownOpened={setDropdownOpened}
                  options={participants}
                  selectOption={onChangeParticipant}
                  selectedOption={
                    fields.find(
                      (field) =>
                        `${field.fieldType}:${field.id}` === selectedFieldId
                    )?.writer as IParticipant
                  }
                />
              </div>
              <div className="field">
                <DescriptionOption
                  fields={fields}
                  setPages={setPages}
                  selectedPageId={selectedPageId}
                  selectedFieldId={selectedFieldId}
                  noToolTip
                />
              </div>
            </>
          )}
          {fields.find(
            (field) => `${field.fieldType}:${field.id}` === selectedFieldId
          )?.type === "sender" && (
            <div className="field">
              <Label>
                발신자 서명 입력란
                <Tooltip side="top" trigger={<InfoIcon />}>
                  <Body3Regular>
                    수신자가 모든 서명을 완료한 경우 자동으로
                    <br />
                    서명이 삽입됩니다.
                  </Body3Regular>
                </Tooltip>
              </Label>
              <button
                type="button"
                className="select-signature"
                onClick={() =>
                  setSignatureSelectionModal && setSignatureSelectionModal(true)
                }
              >
                <Body1Regular>발신자 서명 선택하기</Body1Regular>
              </button>
            </div>
          )}
        </div>
      )}
      {selectedFieldId && selectedFieldType === "TEXT" && (
        <div className="container texts">
          <div className="field">
            <NameOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <RequiredOption
              side
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <Label>작성자 지정</Label>
            <ParticipantsDropdown
              dropdownOpened={dropdownOpened}
              setDropdownOpened={setDropdownOpened}
              options={participants}
              selectOption={onChangeParticipant}
              selectedOption={
                fields.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.writer as IParticipant
              }
            />
          </div>
          <div className="field">
            <FontSizeOption
              side
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <AlignOption
              side
              alignFunc={align}
              type="TEXT"
              selected={
                fields.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.align || "LEFT"
              }
            />
          </div>
          <div className="field">
            <DescriptionOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <CopyOption
              template={template}
              selectedField={fields.find(
                (field) => `${field.fieldType}:${field.id}` === selectedFieldId
              )}
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
        </div>
      )}
      {selectedFieldId && selectedFieldType === "LONGTEXT" && (
        <div className="container longTexts">
          <div className="field">
            <NameOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <RequiredOption
              side
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <Label>작성자 지정</Label>
            <ParticipantsDropdown
              dropdownOpened={dropdownOpened}
              setDropdownOpened={setDropdownOpened}
              options={participants}
              selectOption={onChangeParticipant}
              selectedOption={
                fields.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.writer as IParticipant
              }
            />
          </div>
          <div className="field">
            <FontSizeOption
              side
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <AlignOption
              side
              alignFunc={align}
              type="LONGTEXT"
              selected={
                fields.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.align || "LEFT"
              }
            />
          </div>
          <div className="field">
            <DescriptionOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <CopyOption
              template={template}
              selectedField={fields.find(
                (field) => `${field.fieldType}:${field.id}` === selectedFieldId
              )}
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
        </div>
      )}
      {selectedFieldId && selectedFieldType === "NUMBER" && (
        <div className="container numbers">
          <div className="field">
            <NameOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <RequiredOption
              side
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <Label>작성자 지정</Label>
            <ParticipantsDropdown
              dropdownOpened={dropdownOpened}
              setDropdownOpened={setDropdownOpened}
              options={participants}
              selectOption={onChangeParticipant}
              selectedOption={
                fields.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.writer as IParticipant
              }
            />
          </div>
          <div className="field">
            <FontSizeOption
              side
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <AlignOption
              side
              alignFunc={align}
              type="NUMBER"
              selected={
                fields.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.align || "LEFT"
              }
            />
          </div>
          <div className="field">
            <Label>
              자릿수 구분
              <Tooltip side="top" trigger={<InfoIcon />}>
                <Body3Regular>
                  3자리 숫자를 기준으로 콤마가 표시됩니다.
                  <br />
                  {`예) 1000000 입력 시 1,000,000으로 자동 변경`}
                </Body3Regular>
              </Tooltip>
            </Label>
            <Toggle
              isSelected={
                !!fields?.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.commas
              }
              handleToggle={() => {
                setPages((pages: IPage[]) =>
                  pages.map((page: IPage) => {
                    if (page.id === selectedPageId) {
                      return {
                        ...page,
                        fields: page?.fields?.map((field) => {
                          if (
                            `${field.fieldType}:${field.id}` === selectedFieldId
                          )
                            return {
                              ...field,
                              commas: !field.commas,
                            };
                          else return { ...field };
                        }),
                      };
                    } else return { ...page };
                  })
                );
              }}
            />
          </div>
          <div className="field">
            <DescriptionOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <CopyOption
              template={template}
              selectedField={fields.find(
                (field) => `${field.fieldType}:${field.id}` === selectedFieldId
              )}
              label="1,000,000"
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
        </div>
      )}
      {selectedFieldId && selectedFieldType === "DATE" && (
        <div className="container dates">
          <div className="field">
            <NameOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <RequiredOption
              side
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <Label>작성자 지정</Label>
            <ParticipantsDropdown
              dropdownOpened={dropdownOpened}
              setDropdownOpened={setDropdownOpened}
              options={participants}
              selectOption={onChangeParticipant}
              selectedOption={
                fields.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.writer as IParticipant
              }
            />
          </div>
          <div className="field">
            <FontSizeOption
              side
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <AlignOption
              side
              alignFunc={align}
              type="DATE"
              selected={
                fields.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.align || "LEFT"
              }
            />
          </div>
          <div className="field">
            <Label>
              서명날짜 지정
              <Tooltip side="top" trigger={<InfoIcon />}>
                <Body3Regular>
                  문서에 있는 마지막 서명 입력란을 작성 완료한 일자를
                  <br />
                  자동으로 입력하게 합니다.
                  <br />이 기능이 설정될 경우 날짜를 임의로
                  <br />
                  입력/수정할 수 없습니다.
                </Body3Regular>
              </Tooltip>
            </Label>
            <Toggle
              isSelected={
                !!fields?.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.autoDate
              }
              handleToggle={() => {
                setPages((pages: IPage[]) =>
                  pages.map((page: IPage) => {
                    if (page.id === selectedPageId) {
                      return {
                        ...page,
                        fields: page?.fields?.map((field) => {
                          if (
                            `${field.fieldType}:${field.id}` === selectedFieldId
                          )
                            return {
                              ...field,
                              autoDate: !field.autoDate,
                            };
                          else return { ...field };
                        }),
                      };
                    } else return { ...page };
                  })
                );
              }}
            />
          </div>
        </div>
      )}
      {selectedFieldId && selectedFieldType === "ADDRESS" && (
        <div className="container addresses">
          <div className="field">
            <NameOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <RequiredOption
              side
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <Label>작성자 지정</Label>
            <ParticipantsDropdown
              dropdownOpened={dropdownOpened}
              setDropdownOpened={setDropdownOpened}
              options={participants}
              selectOption={onChangeParticipant}
              selectedOption={
                fields.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.writer as IParticipant
              }
            />
          </div>
          <div className="field">
            <FontSizeOption
              side
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <AlignOption
              side
              alignFunc={align}
              type="ADDRESS"
              selected={
                fields.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.align || "LEFT"
              }
            />
          </div>
        </div>
      )}
      {selectedFieldId && selectedFieldType === "IMAGE" && (
        <div className="container images">
          <div className="field">
            <NameOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <RequiredOption
              side
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <Label>작성자 지정</Label>
            <ParticipantsDropdown
              dropdownOpened={dropdownOpened}
              setDropdownOpened={setDropdownOpened}
              options={participants}
              selectOption={onChangeParticipant}
              selectedOption={
                fields.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.writer as IParticipant
              }
            />
          </div>
          <div className="field">
            <DescriptionOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
        </div>
      )}
      {selectedFieldId && selectedFieldType === "DROPDOWN" && (
        <div className="container dropdownLists">
          <div className="field">
            <NameOption
              label="선택란 이름"
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <RequiredOption
              side
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <Label>작성자 지정</Label>
            <ParticipantsDropdown
              dropdownOpened={dropdownOpened}
              setDropdownOpened={setDropdownOpened}
              options={participants}
              selectOption={onChangeParticipant}
              selectedOption={
                fields.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.writer as IParticipant
              }
            />
          </div>
          <div className="field">
            <FontSizeOption
              side
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <AlignOption
              side
              alignFunc={align}
              type="DROPDOWN"
              selected={
                fields.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.align || "LEFT"
              }
            />
          </div>
          <div className="field">
            <Label>내용 입력</Label>
            <Input
              value={fields
                ?.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )
                ?.dropDownItems?.map((item) => item.value)
                .join(",")}
              placeholder="내용 입력"
              maxLength={200}
              disabled={true}
            />
          </div>
        </div>
      )}
      {selectedFieldId && selectedFieldType === "CHECKBOX" && (
        <div className="container selections">
          <div className="field">
            <NameOption
              label="그룹 이름"
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <RequiredOption
              side
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="field">
            <Label>작성자 지정</Label>
            <ParticipantsDropdown
              dropdownOpened={dropdownOpened}
              setDropdownOpened={setDropdownOpened}
              options={participants}
              selectOption={onChangeParticipant}
              selectedOption={
                pages
                  .find((page) => page.id === selectedPageId)
                  ?.fields.find(
                    (field) =>
                      `${field.fieldType}:${field.id}` === selectedFieldId
                  )?.writer as IParticipant
              }
            />
          </div>
          <div className="field">
            <Label>
              체크/라디오 박스 선택
              <Tooltip side="top" trigger={<InfoIcon />}>
                <Body3Regular>
                  체크/라디오 버튼을 설정할 수 있습니다.
                </Body3Regular>
                <ul className="detail">
                  {[
                    "체크/라디오 선택: 사용할 필드를 선택합니다.",
                    "버튼 개수: 동일 그룹으로 설정할 필드를 생성합니다.",
                    "동시 선택 개수: 동일 그룹 내에서 동시에 선택할 수 있는\n필드 개수, 조건을 선택합니다.(라디오의 경우 1, 일치로 고정됩니다.)",
                    "필드 이름: 각 필드의 이름을 지정합니다.",
                  ].map((item, idx) => (
                    <li key={idx}>
                      <span>{idx + 1}</span>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </Tooltip>
            </Label>
            <label htmlFor="checkbox">
              <input
                id="checkbox"
                type="radio"
                name="type"
                value="checkbox"
                checked={
                  fields?.find(
                    (field) =>
                      `${field.fieldType}:${field.id}` === selectedFieldId
                  )?.buttonType === "checkbox"
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPages((pages: IPage[]) =>
                    pages.map((page: IPage) => {
                      if (page.id === selectedPageId) {
                        return {
                          ...page,
                          fields: page?.fields?.map((field) => {
                            if (
                              `${field.fieldType}:${field.id}` ===
                              selectedFieldId
                            )
                              return {
                                ...field,
                                name: `${
                                  field.writer?.name || field.writer?.label
                                } 체크박스${extractNumbersFromStrings(
                                  page?.fields.map((field) => field.name || ""),
                                  "체크박스"
                                )}`,
                                buttonType: e.target.value as
                                  | "checkbox"
                                  | "radio",
                                selectCount: {
                                  ...field.selectCount,
                                  options: field.selectCount?.options?.map(
                                    (option) => ({
                                      ...option,
                                      checked: false,
                                    })
                                  ),
                                },
                              };
                            else return { ...field };
                          }),
                        };
                      } else return { ...page };
                    })
                  )
                }
              />
              {fields?.find(
                (field) => `${field.fieldType}:${field.id}` === selectedFieldId
              )?.buttonType === "checkbox" ? (
                <CheckBoxCheckedIcon />
              ) : (
                <CheckBoxNotCheckedIcon />
              )}
              <Body3Regular>체크박스</Body3Regular>
            </label>
            <label htmlFor="radio">
              <input
                id="radio"
                type="radio"
                name="type"
                value="radio"
                checked={
                  fields?.find(
                    (field) =>
                      `${field.fieldType}:${field.id}` === selectedFieldId
                  )?.buttonType === "radio"
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPages((pages: IPage[]) =>
                    pages.map((page: IPage) => {
                      if (page.id === selectedPageId) {
                        return {
                          ...page,
                          fields: page?.fields?.map((field) => {
                            if (
                              `${field.fieldType}:${field.id}` ===
                              selectedFieldId
                            )
                              return {
                                ...field,
                                name: `${
                                  field.writer?.name || field.writer?.label
                                } 라디오버튼${extractNumbersFromStrings(
                                  page?.fields.map((field) => field.name || ""),
                                  "라디오버튼"
                                )}`,
                                buttonType: e.target.value as
                                  | "checkbox"
                                  | "radio",
                                selectCount: {
                                  ...field.selectCount,
                                  options: field.selectCount?.options?.map(
                                    (option) => ({
                                      ...option,
                                      checked: false,
                                    })
                                  ),
                                },
                              };
                            else return { ...field };
                          }),
                        };
                      } else return { ...page };
                    })
                  )
                }
              />
              {fields?.find(
                (field) => `${field.fieldType}:${field.id}` === selectedFieldId
              )?.buttonType === "radio" ? (
                <RadioButtonCheckedIcon />
              ) : (
                <RadioButtonNotCheckedIcon />
              )}
              <Body3Regular>라디오 버튼</Body3Regular>
            </label>
          </div>
          <div className="field">
            <Label>버튼 개수</Label>
            <Input
              type="text"
              value={
                fields.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )?.buttonsCount
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (/\D/gi.test(e.target.value)) return;

                setPages((prev) =>
                  prev.map((page) => {
                    if (page.id === selectedPageId) {
                      return {
                        ...page,
                        fields: page.fields.map((field) => {
                          if (
                            `${field.fieldType}:${field.id}` === selectedFieldId
                          ) {
                            return {
                              ...field,
                              buttonsCount: Math.min(+e.target.value, 100),
                            };
                          } else return { ...field };
                        }),
                      };
                    } else return { ...page };
                  })
                );

                onChangeHandler(e);
              }}
            />
          </div>
          <div className="field">
            <Label>동시 선택 개수</Label>
            <div className="dropdowns">
              <Dropdown
                isDisabled={
                  fields?.find(
                    (field) =>
                      `${field.fieldType}:${field.id}` === selectedFieldId
                  )?.buttonType === "radio"
                }
                table
                dropdownList={Array(
                  fields?.find(
                    (field) =>
                      `${field.fieldType}:${field.id}` === selectedFieldId
                  )?.buttonsCount
                )
                  .fill(0)
                  .map((_, idx) => ({
                    value: `${idx + 1}`,
                    label: `${idx + 1}`,
                  }))}
                selected={
                  fields?.find(
                    (field) =>
                      `${field.fieldType}:${field.id}` === selectedFieldId
                  )?.buttonType === "radio"
                    ? `1`
                    : `${
                        pages
                          ?.find((page) => page.id === selectedPageId)
                          ?.fields?.find(
                            (field) =>
                              `${field.fieldType}:${field.id}` ===
                              selectedFieldId
                          )?.selectCount?.number
                      }`
                }
                setSelected={(number: string) => {
                  setPages((pages: IPage[]) =>
                    pages.map((page: IPage) => {
                      if (page.id === selectedPageId) {
                        return {
                          ...page,
                          fields: page?.fields?.map((field) => {
                            if (
                              `${field.fieldType}:${field.id}` ===
                              selectedFieldId
                            )
                              return {
                                ...field,
                                selectCount: {
                                  ...field?.selectCount,
                                  number: +number || 1,
                                },
                              };
                            else return { ...field };
                          }),
                        };
                      } else return { ...page };
                    })
                  );
                }}
              />
              <Dropdown
                isDisabled={
                  fields?.find(
                    (field) =>
                      `${field.fieldType}:${field.id}` === selectedFieldId
                  )?.buttonType === "radio"
                }
                table
                dropdownList={[
                  { value: "MATCH", label: "일치" },
                  { value: "ABOVE", label: "이상" },
                  { value: "BELOW", label: "이하" },
                ].map(({ value, label }) => ({
                  value: value as ConcurrentSelectType,
                  label,
                }))}
                selected={
                  fields?.find(
                    (field) =>
                      `${field.fieldType}:${field.id}` === selectedFieldId
                  )?.buttonType === "radio"
                    ? `MATCH`
                    : (fields?.find(
                        (field) =>
                          `${field.fieldType}:${field.id}` === selectedFieldId
                      )?.selectCount?.mode as SelectionMode)
                }
                setSelected={(mode: string) => {
                  setPages((pages: IPage[]) =>
                    pages.map((page: IPage) => {
                      if (page.id === selectedPageId) {
                        return {
                          ...page,
                          fields: page?.fields?.map((field) => {
                            if (
                              `${field.fieldType}:${field.id}` ===
                              selectedFieldId
                            )
                              return {
                                ...field,
                                selectCount: {
                                  ...field.selectCount,
                                  mode: mode as ConcurrentSelectType,
                                },
                              };
                            else return { ...field };
                          }),
                        };
                      } else return { ...page };
                    })
                  );
                }}
              />
            </div>
            <div className="options" onClick={(e) => e.stopPropagation()}>
              {fields
                ?.find(
                  (field) =>
                    `${field.fieldType}:${field.id}` === selectedFieldId
                )
                ?.selectCount?.options?.map((option, idx) => (
                  <Input
                    key={option.id}
                    type="text"
                    placeholder={`${
                      fields?.find(
                        (field) =>
                          `${field.fieldType}:${field.id}` === selectedFieldId
                      )?.buttonType === "radio"
                        ? "라디오버튼"
                        : "체크박스"
                    } ${idx + 1}`}
                    value={option.label}
                    setValue={(label: string) =>
                      setPages((prev) =>
                        prev.map((page) => {
                          if (page.id === selectedPageId)
                            return {
                              ...page,
                              fields: page.fields.map((field) => {
                                if (
                                  `${field.fieldType}:${field.id}` ===
                                  selectedFieldId
                                ) {
                                  return {
                                    ...field,
                                    selectCount: {
                                      ...field.selectCount,
                                      options: field.selectCount?.options?.map(
                                        (el) => {
                                          if (el.id === option.id)
                                            return {
                                              ...el,
                                              label,
                                            };
                                          else return { ...el };
                                        }
                                      ),
                                    },
                                  };
                                } else return { ...field };
                              }),
                            };
                          else return { ...page };
                        })
                      )
                    }
                  />
                ))}
            </div>
          </div>
          <div className="field">
            <DescriptionOption
              selection
              fields={getAllFields(pages)}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
        </div>
      )}
      {detail.display && detail.id && (
        <ModalLayout
          handleModalClose={() => setDetail({ display: false, id: "" })}
        >
          {["jpeg", "png"].includes(
            attachedFiles.find((file) => file.id === detail.id)
              ?.fileType as string
          ) && (
            <img
              src={
                attachedFiles.find((file) => file.id === detail.id)
                  ?.preview as string
              }
              alt="첨부파일"
            />
          )}
          {(attachedFiles.find((file) => file.id === detail.id)
            ?.fileType as string) === "pdf" && (
            <Document
              file={
                attachedFiles.find((file) => file.id === detail.id)
                  ?.preview as File
              }
              onLoadSuccess={({ numPages }) =>
                setDetail((prev) => ({ ...prev, pages: numPages }))
              }
              loading={<></>}
            >
              <PagesStyled>
                {Array(detail.pages as number)
                  .fill(0)
                  .map((_, i) => i + 1)
                  .map((el) => (
                    <div key={el} className="page-container">
                      <Page
                        key={el}
                        pageNumber={el}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                      />
                    </div>
                  ))}
              </PagesStyled>
            </Document>
          )}
        </ModalLayout>
      )}
    </RightStyled>
  );
}

const RightStyled = styled.div`
  box-sizing: border-box;
  min-width: 26rem;
  max-width: 26rem;
  min-height: calc(100dvh - 13.6rem);
  padding: 3rem 1rem;
  background-color: var(--bg-white);
  border-left: 0.1rem solid var(--stroke-light);
  overflow-y: scroll;

  .container {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 3rem;

    .field {
      display: flex;
      flex-direction: column;
      row-gap: 1.2rem;

      .checkbox {
        display: flex;
        align-items: center;
        column-gap: 0.8rem;
      }

      .contentContainer {
        padding: 0;
        column-gap: 0.4rem;
        justify-content: flex-start;
      }
    }

    &.default {
      padding: 0 1rem;

      .total {
        color: var(--text-secondary);
      }

      .pages {
        padding: 0 0.4rem;
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        .page {
          display: flex;
          flex-direction: column;
          row-gap: 1.2rem;

          &.current .thumbnail {
            border: 0.1rem solid var(--grey-700);
          }

          .label {
            display: flex;
            align-items: center;
            column-gap: 0.4rem;

            svg {
              width: 2.4rem;
              height: 2.4rem;
            }
          }

          .thumbnail {
            cursor: pointer;
            border-radius: 1.2rem;
            width: 100%;
            overflow: hidden;
            height: 14rem;
            background-color: var(--grey-200);
            border: 0.1rem solid transparent;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              border: 0.1rem solid var(--grey-700);
            }

            .react-pdf__Thumbnail__page {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .functions {
            display: flex;
            align-items: center;
            column-gap: 1.2rem;

            svg {
              cursor: pointer;
            }
          }
        }

        .divider {
          width: 100%;
          height: 0.1rem;
          background-color: var(--stroke-light);
        }

        .attachment {
          display: flex;
          flex-direction: column;
          row-gap: 1.2rem;

          .label {
            display: flex;
            align-items: center;
            column-gap: 0.4rem;

            svg {
              width: 2.4rem;
              height: 2.4rem;
            }
          }

          .thumbnail-container {
            position: relative;
            border-radius: 1.2rem;
            overflow: hidden;
          }

          .thumbnail {
            width: 100%;
            overflow: hidden;
            height: 14rem;
            border: 0.1rem solid transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
          }

          .functions {
            display: flex;
            align-items: center;
            column-gap: 1.2rem;

            svg {
              cursor: pointer;
            }
          }
        }

        .dimmed {
          position: absolute;
          inset: 0;
          background-color: #000000;
          opacity: 30%;
        }
      }
    }

    &.signatures {
      display: flex;
      flex-direction: column;
      row-gap: 4rem;

      .select-signature {
        width: 100%;
        padding: 1.2rem 1.4rem;
        border-radius: 0.5rem;
        border: 0.1rem solid var(--stroke-light);
      }
    }

    &.texts,
    &.longTexts,
    &.numbers,
    &.dates,
    &.addresses,
    &.dropdownLists {
      .alignOptions {
        display: flex;
        align-items: center;
        column-gap: 0.4rem;

        svg {
          cursor: pointer;
        }
      }
    }

    &.selections {
      label {
        display: flex;
        align-items: center;
        column-gap: 0.8rem;

        input {
          display: none;
        }
      }

      .dropdowns {
        display: flex;
        column-gap: 4rem;
        align-items: center;

        .contentContainer {
          min-width: 6.2rem;

          & > div:first-of-type {
            flex-grow: 1;
          }
        }
      }

      .field > .options {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
      }
    }

    .copyOptions {
      display: flex;
      flex-direction: column;
      row-gap: 1.2rem;
    }
  }
`;

export const Label = styled(Body1SemiBold)<{ justifyContent?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || "unset"};
`;

export const PagesStyled = styled.div`
  max-height: 80dvh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  margin: auto;
  overflow: scroll;

  .page-container {
    position: relative;
    background-color: transparent !important;
    margin: 0 auto;

    .canvas {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }

  .page {
    background-color: transparent !important;
    box-sizing: border-box;

    & > canvas {
      width: 100%;
      height: 100%;
      inset: 0;
    }
  }
`;

import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  getSignDetails,
  getSignDirectUrls,
  getSignDirectUrlsDetails,
  getSignDirectUrlsPassword,
  getSignPhoneVerifyValid,
  getSignDirectUrlsReviewDetails,
} from "apis/sign";

export const signQueryKey = createQueryKeys("sign", {
  details: {
    queryKey: null,
    queryFn: getSignDetails,
  },
  phoneVerifyValid: ({ verifyType, requestId, entityId }) => ({
    queryKey: [verifyType, requestId, entityId],
    queryFn: () => getSignPhoneVerifyValid({ requestId, verifyType, entityId }),
  }),
  directUrlCode: ({ directUrlCode }) => ({
    queryKey: [directUrlCode],
    queryFn: () => getSignDirectUrls({ directUrlCode }),
  }),
  needPassword: ({ contractDirectUrlUuid }) => ({
    queryKey: [contractDirectUrlUuid],
    queryFn: () => getSignDirectUrlsPassword({ contractDirectUrlUuid }),
  }),
  directUrlDetails: {
    queryKey: null,
    queryFn: getSignDirectUrlsDetails,
  },
  reviewDetails: {
    queryKey: null,
    queryFn: getSignDirectUrlsReviewDetails,
  },
});

import { MenuItems } from ".";
import HomeIcon from "assets/common/24px/icon_home.svg";
import ActiveHomeIcon from "assets/common/24px/icon_home_active.svg";
import TemplateIcon from "assets/common/24px/icon_template.svg";
import ActiveTemplateIcon from "assets/common/24px/icon_template_active.svg";
import ContractIcon from "assets/common/24px/icon_contract.svg";
import ActiveContractIcon from "assets/common/24px/icon_contract_active.svg";
import SettingsIcon from "assets/common/24px/icon_settings.svg";
import ActiveSettingsIcon from "assets/common/24px/icon_settings_active.svg";
import MyInfoIcon from "assets/common/16px/icon_my_info.svg";
import MySignIcon from "assets/common/16px/icon_my_signature.svg";
import MyOrganizationIcon from "assets/common/16px/icon_invited_organization.svg";
import TemplateStoreIcon from "assets/common/16px/icon_template_store.svg";
import TemplateBookmarkIcon from "assets/common/16px/icon_bookmark_templates.svg";
import TemplateAllIcon from "assets/common/16px/icon_all_templates.svg";
import TemplateMyIcon from "assets/common/16px/icon_my_templates.svg";
import GeneralContractIcon from "assets/common/16px/icon_general_contract.svg";
import GeneralSettingIcon from "assets/common/16px/icon_general_settings.svg";
import DirectUrlIcon from "assets/common/16px/icon_direct_url.svg";
import PaymentIcon from "assets/common/16px/icon_payment_management.svg";
import MemberGroupIcon from "assets/common/16px/icon_member_group_management.svg";
import SimpleSettingIcon from "assets/common/16px/icon_simple_settings.svg";
import InquiryIcon from "assets/common/16px/icon_inquiry.svg";
import SettingSupportIcon from "assets/MobileMenuIcon/icon_setting_support.svg";
import SettingSupportActiveIcon from "assets/MobileMenuIcon/active/icon_setting_support.svg";

// TODO routes에 맞게 수정 필요
// subMenu > include는 subMenu의 path(to)는 아니지만 해당 subMenu가 활성화되야 하는 경우 추가
export const MENU_ITEMS: MenuItems[] = [
  {
    label: "홈",
    icon: HomeIcon,
    activeIcon: ActiveHomeIcon,
    to: "/dashboard",
    include: ["/notice", "/alert"],
    permission: {
      organization: ["MASTER", "ADMIN", "USER"],
    },
    subMenu: [
      {
        label: "내 정보",
        to: "/profile/information",
        icon: MyInfoIcon,
        include: ["/profile/information"],
      },
      {
        label: "내 서명",
        to: "/profile/sign",
        icon: MySignIcon,
        include: ["/profile/sign"],
      },
      {
        label: "가입된 조직",
        to: "/profile/invited-organization",
        icon: MyOrganizationIcon,
        include: ["/profile/invited-organization"],
      },
    ],
  },
  {
    label: "템플릿 관리",
    icon: TemplateIcon,
    activeIcon: ActiveTemplateIcon,
    to: "/template/dashboard",
    permission: {
      organization: ["MASTER", "ADMIN", "USER"],
    },
    subMenu: [
      {
        label: "전체 템플릿 목록",
        to: "/template/dashboard",
        icon: TemplateAllIcon,
        include: ["/template/dashboard"],
      },
      {
        label: "내가 만든 템플릿",
        to: "/template/dashboard/me",
        icon: TemplateMyIcon,
        include: ["/template/dashboard/me"],
      },
      {
        label: "즐겨찾기",
        to: "/template/dashboard/bookmark",
        icon: TemplateBookmarkIcon,
        include: ["/template/dashboard/bookmark"],
      },
      {
        label: "템플릿 스토어",
        to: "/template/store",
        icon: TemplateStoreIcon,
        include: ["/template/store"],
      },
    ],
  },
  {
    label: "계약 관리",
    icon: ContractIcon,
    activeIcon: ActiveContractIcon,
    to: "/contract/dashboard/normal",
    permission: {
      organization: ["MASTER", "ADMIN", "USER"],
    },
    subMenu: [
      {
        label: "일반 계약",
        to: "/contract/dashboard/normal",
        icon: GeneralContractIcon,
        include: ["/contract/dashboard/normal"],
      },
      {
        label: "Direct URL",
        to: "/contract/dashboard/direct-url",
        icon: DirectUrlIcon,
        include: ["/contract/dashboard/direct-url"],
      },
    ],
  },
  {
    label: "설정",
    icon: SettingsIcon,
    activeIcon: ActiveSettingsIcon,
    to: "/setting/payment/dashboard",
    permission: {
      organization: ["MASTER", "ADMIN"],
    },
    subMenu: [
      {
        label: "결제 관리",
        to: "/setting/payment/dashboard",
        icon: PaymentIcon,
        include: [
          "/setting/payment/billing",
          "/setting/payment/dashboard",
          "/setting/payment/history",
          "/setting/payment/billing/complete",
        ],
        permission: {
          organization: ["MASTER"],
        },
      },
      {
        label: "일반 설정",
        to: "/setting/organization",
        icon: GeneralSettingIcon,
        include: ["/setting/organization", "/setting/service"],
        permission: {
          organization: ["MASTER", "ADMIN"],
        },
      },
      {
        label: "멤버/그룹 관리",
        to: "/setting/member",
        icon: MemberGroupIcon,
        include: ["/setting/member", "/setting/group"],
      },
      {
        label: "간편 설정",
        to: "/setting/contact",
        icon: SimpleSettingIcon,
        include: ["/setting/contact", "/setting/list-box-db", "/setting/mail"],
        permission: {
          organization: ["MASTER", "ADMIN"],
        },
      },
      {
        label: "문의하기",
        to: "/setting/support",
        icon: InquiryIcon,
        include: ["/setting/support", "/setting/faq"],
        permission: {
          organization: ["MASTER", "ADMIN", "USER"],
        },
      },
    ],
  },
  {
    label: "API",
    to: "/api/dashboard",
    icon: TemplateIcon,
    activeIcon: ActiveTemplateIcon,
    permission: {
      organization: ["MASTER", "ADMIN", "USER"],
    },
  },
  {
    label: "지원",
    to: "/setting/support",
    icon: SettingSupportIcon,
    activeIcon: SettingSupportActiveIcon,
    permission: {
      organization: ["MASTER", "ADMIN", "USER"],
    },
    hidden: true,
  },
];

import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactComponent as HandlerIcon } from "assets/Contract/icon_handle.svg";
import { Body1Medium } from "components/TextStyle";
import Input from "components/Common/Input/Input";
import Button from "components/Common/Button/Button";
import { FieldArrayWithId } from "react-hook-form";
import { ManyReceiverConfigFormData } from "../Config/ManyReceiverConfigForm";
import { PALETTE } from "config/constant";
import { ContactDto } from "models/Contact";
import styled from "styled-components";
import {
  formatNameInput,
  formatPhoneNumberInput,
  formatEmailInput,
  isValidPhoneNumberInput,
  isValidEmail,
} from "utils/utility";
import { UseFormRegister } from "react-hook-form";

export function SortableItem({
  id,
  idx,
  field,
  register,
  setVerificationModal,
  mode,
  dragOverlay,
  contacts,
  openDropdownIndex,
  setOpenDropdownIndex,
  setValue,
}: {
  id: string;
  idx?: number;
  field?: FieldArrayWithId<ManyReceiverConfigFormData, "receivers", "id">;
  register: UseFormRegister<ManyReceiverConfigFormData>;
  setVerificationModal?: React.Dispatch<
    React.SetStateAction<{
      opened: boolean;
      index: number;
      prevData: {
        password: {
          isUsed: boolean;
          password: string;
        };
        phoneNumber: {
          isUsed: boolean;
        };
      };
    }>
  >;
  mode?: "MANY" | "MANY_SEQ" | null;
  dragOverlay?: boolean;
  contacts: ContactDto[];
  openDropdownIndex: number | null;
  setOpenDropdownIndex: (index: number | null) => void;
  setValue: any;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const [phoneErrors, setPhoneErrors] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [emailErrors, setEmailErrors] = useState<{ [key: number]: boolean }>(
    {}
  );

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: dragOverlay ? "grabbing" : "grab",
  };

  const handleNameChange = (index: number, value: string) => {
    const formattedName = formatNameInput(value);
    setValue(`receivers.${index}.name`, formattedName);
    if (!formattedName) {
      setOpenDropdownIndex(index); // 드롭다운 열기
    } else {
      setOpenDropdownIndex(null);
    }
  };

  const handlePhoneNumberChange = (index: number, value: string) => {
    const formatted = formatPhoneNumberInput(value);
    setValue(`receivers.${index}.phoneNumber`, formatted || "");

    setPhoneErrors((prev) => ({
      ...prev,
      [index]: !isValidPhoneNumberInput(formatted || "") && value !== "",
    }));
  };

  const handleEmailChange = (index: number, value: string) => {
    const formattedEmail = formatEmailInput(value);
    setValue(`receivers.${index}.email`, formattedEmail);

    setEmailErrors((prev) => ({
      ...prev,
      [index]: value !== "" && !isValidEmail(formattedEmail),
    }));
  };

  const handleNameInputClick = () => {
    if (idx === undefined) return;
    setOpenDropdownIndex(openDropdownIndex === idx ? null : idx);
  };

  const handleContactSelect = (index: number, contact: ContactDto) => {
    // update(index, {
    //   ...field,
    //   name: contact.contactName,
    //   email: contact.contactEmail,
    //   phoneNumber: contact.contactPhoneNumber,
    // });
    setValue(`receivers.${index}.name`, contact.contactName);
    setValue(`receivers.${index}.email`, contact.contactEmail);
    setValue(`receivers.${index}.phoneNumber`, contact.contactPhoneNumber);
    setOpenDropdownIndex(null);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".name-input-container")) {
        setOpenDropdownIndex(null);
      }
    };

    // 드롭다운이 열려있을 때만 이벤트 리스너 추가
    if (openDropdownIndex !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // 컴포넌트 언마운트 또는 openDropdownIndex 변경 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdownIndex, setOpenDropdownIndex]);

  if (dragOverlay) {
    return (
      <div
        key={id}
        className="single-receiver-form-from"
        style={{ cursor: "grabbing" }}
      >
        <div>
          <div className="color" />
          <Input placeholder="이름" disabled />
          <Input placeholder="이메일" disabled />
          <Input placeholder="휴대폰 번호" disabled />
        </div>
      </div>
    );
  }

  if (idx === undefined) return;

  return (
    <StyledSortableItem
      key={id}
      className="single-receiver-form-from"
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
    >
      <div>
        <div
          className="color"
          style={{ backgroundColor: PALETTE.receivers[idx] }}
        />
        <div className="name-input-container">
          <Input
            placeholder="이름"
            {...register(`receivers.${idx}.name` as const, {
              onChange: (e) => handleNameChange(idx, e.target.value),
            })}
            onClick={() => handleNameInputClick()}
          />
          {openDropdownIndex === idx && contacts && contacts.length > 0 && (
            <ul className="contact-dropdown">
              {(field?.name
                ? contacts.filter((contact) =>
                    contact.contactName
                      .toLowerCase()
                      .includes(field.name.toLowerCase())
                  )
                : contacts
              ).map((contact) => (
                <li
                  key={contact.contactUuid}
                  onClick={() => handleContactSelect(idx, contact)}
                >
                  {contact.contactName}
                </li>
              ))}
            </ul>
          )}
        </div>
        <InputWrapper>
          <Input
            placeholder="이메일"
            {...register(`receivers.${idx}.email` as const, {
              onChange: (e) => handleEmailChange(idx, e.target.value),
            })}
            isError={emailErrors[idx]}
            errorMessage="이메일 형식이 올바르지 않습니다."
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            placeholder="휴대폰 번호"
            {...register(`receivers.${idx}.phoneNumber` as const, {
              onChange: (e) => handlePhoneNumberChange(idx, e.target.value),
            })}
            isError={phoneErrors[idx]}
            errorMessage="휴대폰 번호 형식이 올바르지 않습니다."
            maxLength={13}
          />
        </InputWrapper>
        <div
          style={{
            marginLeft: "2.4rem",
          }}
        >
          <Body1Medium>
            {field?.verification.password.isUsed &&
            field?.verification.password.password
              ? "암호 "
              : field?.verification.phoneNumber.isUsed
              ? "보안"
              : `개별`}
            인증:{" "}
          </Body1Medium>
          <Button
            colorType="tertiary"
            size="small"
            onClick={() =>
              setVerificationModal?.((prev) => ({
                ...prev,
                opened: true,
                index: idx,
              }))
            }
          >
            {(field?.verification.password.isUsed &&
              field?.verification.password.password) ||
            field?.verification.phoneNumber.isUsed
              ? "변경"
              : "설정"}
            하기
          </Button>
        </div>
      </div>

      {mode === "MANY_SEQ" && <HandlerIcon {...listeners} />}
    </StyledSortableItem>
  );
}

const StyledSortableItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
  align-items: center;
  margin-bottom: 1rem;

  > div {
    display: flex;
    gap: 0.8rem;
    align-items: center;

    .color {
      width: 2rem;
      aspect-ratio: 1/1;
      border-radius: 100%;
    }
  }

  > div > div {
    display: flex;
    gap: 1.2rem;
    align-items: center;
  }

  svg {
    cursor: pointer;
  }

  input {
    margin-top: 0;
    margin-left: 0;
  }

  .name-input-container {
    position: relative;

    .contact-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      background: white;
      border: 1px solid var(--border-default);
      border-radius: 8px;
      margin-top: 4px;
      padding: 4px 0;
      z-index: 1000;
      color: var(--text-secondary);

      li {
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
          background-color: var(--background-hover);
        }
      }
    }
  }
`;

const InputWrapper = styled.div`
  position: relative;

  .errorMsg {
    position: absolute;
    top: 100%;
    white-space: nowrap;
    font-size: 1.2rem;
  }
`;

import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  getContractById,
  getContractByIdRemainCredit,
  getContractCreationInfo,
  getContractDocumentHistories,
  getContractInputs,
  getContractParticipantInfo,
  getContractParticipantsInfo,
  getContracts,
  getContractsGanin,
  getContractStatus,
  postContractSend,
  getCheckPhoneVerify,
} from "apis/contract";
import {
  ContractRequest,
  GetContractCreationInfoRequest,
  GetContractParticipantInfoRequest,
  GetContractParticipantsInfoRequest,
  GetContractsDocumentHistoriesRequest,
  GetContractsGaninRequest,
  GetContractsRequest,
} from "models/Contract";
import { Organization } from "models/Organization";

export type PartialOrganization<T extends ContractRequest> = Omit<
  T,
  "organizationId"
> & {
  organizationId?: T["organizationId"];
};

export const contractQueryKey = createQueryKeys("contract", {
  contractStatus: (id?: Organization["uuid"]) => ({
    queryKey: [id],
    queryFn: () => {
      if (!id) return;
      return getContractStatus(id);
    },
  }),
  organizationId: (id?: Organization["uuid"]) => ({
    queryKey: [id],
    contextQueries: {
      remainCredits: {
        queryKey: [id],
        queryFn: () => {
          if (!id) return;
          return getContractByIdRemainCredit(id);
        },
      },
    },
  }),
  list: (options: PartialOrganization<GetContractsRequest>) => ({
    queryKey: [options],
    queryFn: () => {
      if (!options.organizationId) {
        return;
      }
      return getContracts(options as GetContractsRequest);
    },
  }),
  detail: (contractUuid: string) => ({
    queryKey: [contractUuid],
    queryFn: () => {
      if (!contractUuid) {
        return;
      }
      return getContractById(contractUuid);
    },
  }),
  participant: (
    options: PartialOrganization<GetContractParticipantInfoRequest>
  ) => ({
    queryKey: [options],
    queryFn: () => {
      if (!options.organizationId) {
        return;
      }
      return getContractParticipantInfo(
        options as GetContractParticipantInfoRequest
      );
    },
  }),
  participants: (
    options: PartialOrganization<GetContractParticipantsInfoRequest>
  ) => ({
    queryKey: [options],
    queryFn: () => {
      if (!options.organizationId) {
        return;
      }
      return getContractParticipantsInfo(
        options as GetContractParticipantsInfoRequest
      );
    },
  }),
  creationInfo: (
    options: PartialOrganization<GetContractCreationInfoRequest>
  ) => ({
    queryKey: [options],
    queryFn: () => {
      if (!options.organizationId) {
        return;
      }
      return getContractCreationInfo(options as GetContractCreationInfoRequest);
    },
  }),
  sendContract: (options: any) => ({
    queryKey: options,
    queryFn: postContractSend,
  }),
  ganin: (options: GetContractsGaninRequest) => ({
    queryKey: [options],
    queryFn: () => {
      return getContractsGanin(options);
    },
  }),
  documentHistories: (options: GetContractsDocumentHistoriesRequest) => ({
    queryKey: [options],
    queryFn: () => {
      if (!options.organizationId) {
        return;
      }
      return getContractDocumentHistories(
        options as GetContractsDocumentHistoriesRequest
      );
    },
  }),
  inputs: (contractUuid: string) => ({
    queryKey: [contractUuid],
    queryFn: () => {
      if (!contractUuid) {
        return;
      }
      return getContractInputs(contractUuid);
    },
  }),
  checkPhoneVerify: (organizationUuid: string) => ({
    queryKey: [organizationUuid],
    queryFn: () => {
      return getCheckPhoneVerify(organizationUuid);
    },
  }),
});

import { ReactComponent as LocationIcon } from "assets/common/20px/icon_location.svg";
import { ReactComponent as DeleteIcon } from "assets/common/24px/icon_close_default.svg";
import Postcode from "components/Common/DaumPostcode";
import AlignOption from "components/Contract/Options/Align";
import FontSizeOption from "components/Contract/Options/FontSize";
import RequiredOption from "components/Contract/Options/Required";
import Resizable from "components/Resizable";
import { Body3Regular } from "components/TextStyle";
import { IField, IPage } from "interfaces/contract";
import { TextAlign } from "models/Contract";
import { Field } from "pages/contract/send";
import React, { useState } from "react";
import { Address as AddressType } from "react-daum-postcode/lib/loadPostcode";
import styled from "styled-components";
import { DraggableStyled } from "./styles";

interface AddressProps extends React.HTMLAttributes<HTMLDivElement> {
  edit?: boolean;
  complete?: boolean;
  id: string;
  type: "sender" | "receiver";
  fields: IField[];
  pages: IPage[];
  setPages: React.Dispatch<React.SetStateAction<IPage[]>>;
  selectedPageId: string;
  selectedFieldId?: string;
  onDeleteHandler: (id: string) => void;
  selectField?: (id: string, type: Field) => void;
  scale: number;
  align?: ({
    type,
    align,
    all,
  }: {
    type: Field;
    align?: TextAlign | undefined;
    all?: boolean | undefined;
  }) => void;
  pageContainerRef?: any;
  currentSize: { w: number; h: number };
  handleMouseDown: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>,
    direction: string
  ) => void;
  handleMouseMove: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>
  ) => void;
  handleMouseUp: () => void;
  newField?: boolean;
  isMobile?: boolean;
}

export default function Address({
  edit = false,
  complete = false,
  id,
  type,
  fields,
  pages,
  setPages,
  selectedPageId,
  selectedFieldId,
  onDeleteHandler,
  selectField,
  scale,
  draggable,
  align,
  pageContainerRef,
  currentSize,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  newField,
  isMobile,
  ...args
}: AddressProps) {
  const [isEdit, setIsEdit] = useState(false);

  const [visible, setVisible] = useState(false); // 다음 우편번호 모달 내용 렌더링 여부
  const [address, setAddress] = useState({
    main: "",
    detail: "",
  });
  const [detailMode, setDetailMode] = useState(false);

  const openModal = () => {
    setAddress({ main: "", detail: "" });
    setVisible(true);
  };

  const onCompleteHandler = (data: AddressType) => {
    setAddress({ main: data.address, detail: "" });
    setDetailMode(true);
  };

  return (
    <AddressStyled
      id={id}
      type={type}
      color={
        type === "sender"
          ? "#666F7B"
          : (fields.find((field) => `${field.fieldType}:${field.id}` === id)
              ?.writer?.color as string)
      }
      required={
        !!fields.find((field) => `${field.fieldType}:${field.id}` === id)
          ?.required
      }
      scale={scale}
      draggable={draggable}
      align={
        fields.find((field) => `${field.fieldType}:${field.id}` === id)?.align
      }
      fontSize={
        fields.find((field) => `${field.fieldType}:${field.id}` === id)?.size
      }
      complete={complete}
      newField={newField}
      isMobile={isMobile}
      {...args}
    >
      <div className="wrapper">
        <div className="field-container">
          <div
            className="container address"
            onClick={(e) => {
              e.stopPropagation();
              if (edit && !visible) openModal();
              else selectField && selectField(id, id.split(":")[0] as Field);
            }}
            style={{
              width: `${(currentSize.w / 10) * scale}rem`,
              height: isMobile
                ? `${1.4 * scale}rem`
                : `${(currentSize.h / 10) * scale}rem`,
            }}
          >
            {edit ? (
              <>
                <input
                  type="text"
                  readOnly
                  value={
                    fields.find(
                      (field) => `${field.fieldType}:${field.id}` === id
                    )?.value || ""
                  }
                  onClick={() => {
                    !complete && edit && !visible && openModal();
                  }}
                  disabled={complete}
                />
                {!complete && visible && (
                  <Postcode
                    address={address}
                    setAddress={setAddress}
                    handleModalClose={() => setVisible(false)}
                    detailMode={detailMode}
                    onCompleteHandler={onCompleteHandler}
                    handleCancelButton={() => {
                      setAddress({ main: "", detail: "" });
                      setDetailMode(false);
                      setVisible(false);
                    }}
                    handleConfirmButton={() => {
                      setPages((prev) =>
                        prev.map((page) => {
                          if (isMobile) {
                            if (page.order.toString() === selectedPageId) {
                              return {
                                ...page,
                                fields: page.fields.map((field) => {
                                  if (`${field.fieldType}:${field.id}` === id) {
                                    return {
                                      ...field,
                                      value: `${address.main}, ${address.detail}`,
                                    };
                                  } else return { ...field };
                                }),
                              };
                            } else return { ...page };
                          } else
                            return {
                              ...page,
                              fields: page.fields.map((field) => {
                                if (`${field.fieldType}:${field.id}` === id) {
                                  return {
                                    ...field,
                                    value: `${address.main}, ${address.detail}`,
                                  };
                                } else return { ...field };
                              }),
                            };
                        })
                      );
                      setVisible(false);
                      setDetailMode(false);
                    }}
                  />
                )}
              </>
            ) : (
              <>
                <LocationIcon />
                {isEdit ? (
                  <textarea
                    id="name"
                    value={
                      fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.name
                    }
                    style={{
                      fontSize: isMobile
                        ? "0.4rem"
                        : `${
                            (+(fields.find(
                              (field) => `${field.fieldType}:${field.id}` === id
                            )?.size as string) /
                              10) *
                            scale
                          }rem`,
                      lineHeight: 1.4285714286,
                      color: "var(--text-secondary)",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsEdit(false);
                      }
                    }}
                    onChange={(e) => {
                      setPages((prev) =>
                        prev.map((page) => {
                          if (
                            page.fields
                              .map((field) => `${field.fieldType}:${field.id}`)
                              .includes(id)
                          ) {
                            return {
                              ...page,
                              fields: page.fields.map((field) => {
                                if (`${field.fieldType}:${field.id}` === id) {
                                  return { ...field, name: e.target.value };
                                } else return { ...field };
                              }),
                            };
                          } else return { ...page };
                        })
                      );
                    }}
                  />
                ) : (
                  <Body3Regular
                    style={{
                      fontSize: isMobile
                        ? "0.4rem"
                        : `${
                            (+(fields.find(
                              (field) => `${field.fieldType}:${field.id}` === id
                            )?.size as string) /
                              10) *
                            scale
                          }rem`,
                      lineHeight: 1.4285714286,
                    }}
                    onDoubleClick={
                      !newField && draggable
                        ? () => setIsEdit((prev) => !prev)
                        : undefined
                    }
                  >
                    {
                      fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.name
                    }
                  </Body3Regular>
                )}
              </>
            )}
            {!newField && draggable && id === selectedFieldId && (
              <Resizable
                pages={pages}
                setPages={setPages}
                selectedFieldId={selectedFieldId}
                selectedPageId={selectedPageId}
                handleMouseUp={handleMouseUp}
                handleMouseDown={handleMouseDown}
                handleMouseMove={handleMouseMove}
                color={
                  type === "sender"
                    ? "#666F7B"
                    : !!fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.required
                    ? "red"
                    : "var(--blue-500)"
                }
                scale={scale}
              />
            )}
          </div>
        </div>
        {draggable && id === selectedFieldId && (
          <DeleteIcon onClick={() => onDeleteHandler(id)} />
        )}
      </div>
      {draggable && selectedFieldId === id && (
        <div className="options" onClick={(e) => e.stopPropagation()}>
          <div className="option">
            <FontSizeOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="option">
            <AlignOption
              alignFunc={align}
              type="ADDRESS"
              selected={
                fields.find((field) => `${field.fieldType}:${field.id}` === id)
                  ?.align || "LEFT"
              }
            />
          </div>
          <div className="option">
            <RequiredOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
        </div>
      )}
    </AddressStyled>
  );
}

const AddressStyled = styled(DraggableStyled)`
  .wrapper {
    .container {
      padding: ${({ scale, isMobile }) =>
        isMobile ? "0" : scale ? `0 ${scale * 1}rem` : "0 1rem"};

      box-sizing: border-box;
      display: flex;
      column-gap: 0.4rem;
      border-radius: ${({ scale }) => (scale ? `${scale * 0.4}rem` : "0.4rem")};
      color: var(--text-secondary);

      input[type="text"] {
        cursor: default;
        font-size: ${({ isMobile }) => (isMobile ? "0.5rem" : "1rem")};
      }
    }
  }

  .options {
    .option {
      .dropdown {
        width: max-content;

        .contentContainer {
          justify-content: flex-start;
          padding: 0;
        }
      }

      .alignOptions {
        display: flex;
        align-items: center;
        column-gap: 0.4rem;
      }
    }
  }
`;

import Button from "components/Mobile/Common/Button/Button";
import { Body2Regular } from "components/TextStyle";
import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

export default function VerificationLogin() {
  const navigate = useNavigate();
  const {
    state: {
      securityVerifyType,
      accessJwt,
      senderName,
      senderEmail,
      receiverName,
    },
  } = useLocation();

  const [query] = useSearchParams();
  const ci = query.get("ci");
  const ri = query.get("ri");
  const params = `?ci=${ci}&ri=${ri}`;
  const onClickNonMember = () => {
    if (!ci || !ri) return;

    if (!securityVerifyType) {
      if (accessJwt) sessionStorage.setItem("accessJwt", accessJwt);
      sessionStorage.setItem("verifyType", "RECEIVER");

      navigate(`/receiver/confirm${params}`, {
        state: {
          senderName,
          senderEmail,
          receiverName,
        },
      });
    } else {
      sessionStorage.clear();

      navigate(
        `/receiver/verification/${(
          securityVerifyType as "PHONE" | "PASSWORD"
        ).toLowerCase()}${params}`,
        {
          state: {
            senderName,
            senderEmail,
            receiverName,
          },
        }
      );
    }
  };

  const onClickMember = () => {
    if (accessJwt) sessionStorage.setItem("accessJwt", accessJwt);
    navigate("/auth/sign-in", {
      state: {
        securityVerifyType,
        senderName,
        senderEmail,
        receiverName,
        params,
        accessJwt,
      },
    });
  };
  return (
    <LoginStyled>
      <div className="title-container">
        <p
          style={{
            fontSize: "3.2rem",
            fontWeight: "bold",
            marginBottom: "2rem",
          }}
        >
          비회원으로 작성하시겠어요?
        </p>
        <Body2Regular>로그인 없이 바로 작성하실 수 있습니다.</Body2Regular>
        <Button colorType="tertiary" size="large" onClick={onClickNonMember}>
          비회원으로 작성
        </Button>
      </div>
      <div className="title-container">
        <p
          style={{
            fontSize: "3.2rem",
            fontWeight: "bold",
            marginBottom: "2rem",
          }}
        >
          회원이신가요?
        </p>
        <Body2Regular>로그인 후 다양한 서비스를 이용하세요.</Body2Regular>
        <Button colorType="primary" size="large" onClick={onClickMember}>
          로그인 하기
        </Button>
      </div>
    </LoginStyled>
  );
}

const LoginStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 6rem;
  margin-top: 13.2rem;
  .title-container {
    text-align: center;
    width: max-content;
    display: flex;
    flex-direction: column;
    > button {
      margin-top: 6rem;
      width: 53rem;
    }
    &:last-child {
      border-top: 1px solid var(--stroke-light);
      padding-top: 6rem;
      margin-bottom: 5rem;
    }
  }
`;

import Button from "components/Common/Button/Button";
import Input from "components/Common/Input/Input";
import ActionModal from "components/Common/Modal/ActionModal";
import ContentModal from "components/Common/Modal/ContentModal";
import Stamp from "components/Profile/Stamp";
import { STAMP_FONT, TEXT_INFO } from "components/Profile/type";
import {
  Body1Regular,
  Body1SemiBold,
  Body3SemiBold,
  Header1,
  Title1,
} from "components/TextStyle";
import useSignatureQuery from "hooks/queries/signature/useSignatureQuery";
import useFontLoad from "hooks/useFontLoad";
import { StampGeneratorForm } from "pages/profile/sign/(textStamp)/StampContent";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Stage as StageRefType } from "konva/lib/Stage";
import { useForm } from "react-hook-form";
import resizeImage from "utils/resizeImage";
import konvaToImage from "utils/konvaToImage";
import useSignaturesMutation from "hooks/mutations/signature/useSignaturesMutation";
import iconTrash from "assets/common/20px/icon_trash.svg";
import useSignaturesDeleteMutation from "hooks/mutations/signature/useSignaturesDeleteMutation";
import { toast } from "react-toastify";

interface FormData {
  name: string;
}

export default function ParaphStampModal({
  setStampModalOpened,
  setContractModeModalOpened,
  goToPreview,
}: {
  setStampModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setContractModeModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  goToPreview: (ganinImageUrl: string) => void;
}) {
  const { data, refetch } = useSignatureQuery();
  const fontLoaded = useFontLoad(STAMP_FONT);
  const [mode, setMode] = useState<"LIST" | "NEW" | null>(null);
  const { register, handleSubmit, setValue, watch } = useForm<FormData>();
  const watchedName = watch("name");
  const [name, setName] = useState("");
  const [selectedStamp, setSelectedStamp] = useState<{
    id: string;
    ref?: React.MutableRefObject<StageRefType | null>;
    url?: string;
  }>({
    id: "",
    ref: undefined,
    url: "",
  });

  const { mutate: postNewSignature } = useSignaturesMutation();
  const { mutate: deleteSignatureById } = useSignaturesDeleteMutation();

  const handleGeneratorSubmit = (data: FormData) => {
    setName(data.name);
  };

  const handleCreateStamp = async () => {
    if (!selectedStamp.ref?.current) return;

    const scaleSize = resizeImage(
      {
        width: selectedStamp.ref.current.width(),
        height: selectedStamp.ref.current.height(),
      },
      {
        width: 300,
        height: 300,
      }
    );

    const file = await konvaToImage(selectedStamp.ref.current, {
      width: 300,
      height: 300,
      ...scaleSize,
    });

    const ganinImageUrl = await postNewSignature(file);

    if (ganinImageUrl) goToPreview(ganinImageUrl);
  };

  const selectStamp = () => {
    if (!selectedStamp.url) return;

    goToPreview(selectedStamp.url);
  };

  const deleteSignature = async (id: string) => {
    if (!id) return;

    await deleteSignatureById({ id });
    refetch();
  };

  const confirmStamp = () => {
    if (mode === null) toast("간인방식을 선택해주세요.");
    else setStampModalOpened(false);
  };

  useEffect(() => {
    if (!mode) {
      setSelectedStamp({
        id: "",
        ref: undefined,
      });
      setName("");
      setValue("name", "");
    }
  }, [mode]);

  useEffect(() => {
    if (data?.signatureList.length === 0 && mode === "LIST") {
      setMode(null);
    }
  }, [data?.signatureList]);

  return mode ? (
    <ContentModal
      title={
        <Header1>
          {mode === "LIST" ? "내 서명 선택하기" : "간인 도장 선택하기"}
        </Header1>
      }
      size="large"
      isPrevIcon
      handleModalClose={() => {
        setMode(null);
        setStampModalOpened(false);
        setContractModeModalOpened(true);
      }}
      maxHeight="89.4rem"
    >
      <NewStampModalStyled>
        {mode === "NEW" && (
          <>
            <Body1Regular>
              다양한 모양의 도장을 만들고 마음에 드는 도장을 입력해 보세요.
            </Body1Regular>
            <StampGeneratorForm onSubmit={handleSubmit(handleGeneratorSubmit)}>
              <Input
                placeholder="이름을 입력하고 만들기 버튼을 눌러주세요"
                {...register("name")}
                maxLength={9}
              />
              <Button
                colorType="primary"
                size="large"
                disabled={!watchedName || watchedName.length > 9}
              >
                <Body1SemiBold>만들기</Body1SemiBold>
              </Button>
            </StampGeneratorForm>
          </>
        )}
        <div className="containerAndButton">
          {mode === "NEW" && (
            <div className="container new">
              {STAMP_FONT.map((stampType) => {
                const textInfo = TEXT_INFO.find(
                  (info) => info.textLength === name.length
                );

                if (!textInfo || !fontLoaded) return;
                return textInfo.shapes.map((shape) => {
                  return (
                    <Stamp
                      key={stampType.fontFamily + shape.id}
                      fontFamily={stampType.fontFamily}
                      name={name}
                      shape={shape}
                      onClick={({ ref }) => {
                        setSelectedStamp({
                          ref,
                          id: stampType.fontFamily + shape.id,
                        });
                      }}
                      isSelected={
                        selectedStamp.id === stampType.fontFamily + shape.id
                      }
                    />
                  );
                });
              })}
            </div>
          )}
          {mode === "LIST" && (
            <div className="container">
              <div className="signatures">
                {data?.signatureList.map(({ signatureUuid, signatureUrl }) => (
                  <div
                    key={signatureUuid}
                    className={`signature${
                      selectedStamp.url === signatureUrl ? " selected" : ""
                    }`}
                    onClick={() =>
                      setSelectedStamp({
                        id: signatureUuid,
                        url: signatureUrl,
                      })
                    }
                  >
                    <img src={signatureUrl} alt="" />
                  </div>
                ))}
              </div>
              {(data?.signatureList?.length as number) > 0 && (
                <button
                  className="delete"
                  onClick={() => deleteSignature(selectedStamp.id)}
                >
                  <Body3SemiBold>삭제하기</Body3SemiBold>
                  <img src={iconTrash} alt="delete" />
                </button>
              )}
            </div>
          )}
          <Button
            type="button"
            colorType="primary"
            size="large"
            disabled={
              mode === "NEW"
                ? !selectedStamp.id || !selectedStamp.ref
                : mode === "LIST"
                ? !selectedStamp.id || !selectedStamp.url
                : false
            }
            onClick={mode === "NEW" ? handleCreateStamp : selectStamp}
          >
            <Body1SemiBold>
              {mode === "NEW" ? "도장 생성하기" : "확인"}
            </Body1SemiBold>
          </Button>
        </div>
      </NewStampModalStyled>
    </ContentModal>
  ) : (
    <ActionModal
      title={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "1.6rem",
          }}
        >
          <Title1>간인 서명 선택하기</Title1>
          <Body1Regular style={{ color: "var(--text-secondary)" }}>
            간인 방식을 선택하세요.
          </Body1Regular>
        </div>
      }
      confirmText="선택하기"
      handleCancelButton={() => {
        setStampModalOpened(false);
        setContractModeModalOpened(true);
      }}
      handleModalClose={() => setStampModalOpened(false)}
      handleConfirmButton={confirmStamp}
    >
      <StampModalStyled>
        <Button
          type="button"
          colorType="tertiary"
          size="large"
          disabled={data?.signatureList.length === 0}
          onClick={() => setMode("LIST")}
        >
          <Body1SemiBold>가지고 있는 서명으로 간인하기</Body1SemiBold>
        </Button>
        <Button
          type="button"
          colorType="tertiary"
          size="large"
          onClick={() => setMode("NEW")}
        >
          <Body1SemiBold>새로운 도장으로 간인하기</Body1SemiBold>
        </Button>
      </StampModalStyled>
    </ActionModal>
  );
}

const StampModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;

  button {
    width: 100%;
    color: var(--text-default);
  }
`;

const NewStampModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3rem;

  form {
    margin-top: 0;
  }

  .inputAndButton {
    display: flex;
    align-items: center;
    column-gap: 2rem;
  }

  .containerAndButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5.6rem;

    .container {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;

      .signatures {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 1.2rem;
        row-gap: 3rem;
        max-height: 51.2rem;
        overflow-y: scroll;

        .signature {
          cursor: pointer;
          width: 100%;
          aspect-ratio: 1/1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 1.2rem;
          background-color: var(--grey-100);
          padding: 6rem;
          box-sizing: border-box;
          border: 0.15rem solid transparent;

          img {
            max-width: 100%;
          }

          &.selected {
            border: 0.15rem solid var(--primary-500);
          }
        }
      }

      &.new {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 1.2rem;
        row-gap: 3rem;
        max-height: 51.2rem;
        overflow-y: scroll;
      }

      .delete {
        width: max-content;
        cursor: pointer;
        display: flex;
        align-items: center;
        column-gap: 0.4rem;

        & > div {
          text-decoration: underline;
          color: var(--text-secondary);
        }
      }
    }

    button {
      width: 24rem;
    }
  }
`;

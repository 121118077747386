import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import { SCALES } from "config/constant";
import { IPage } from "interfaces/contract";
import { Field, FIELDS } from "pages/contract/send";
import { PageContainer } from "pages/contract/send/detail/editor";
import { PreviewStyled } from "pages/contract/send/styles";
import { Body1Medium, Body1Regular, Body2Medium } from "components/TextStyle";
import Item from "components/Draggable/Item";
import Dropdown from "components/Common/Dropdown/Dropdown";
import Button from "components/Common/Button/Button";
import ReviewRejectModal from "components/Contract/directUrl/ReviewRejectModal";
import {
  ScaleControllerStyled,
  ToolbarStyled,
} from "components/Toolbar/styles";
import useModal from "hooks/useModal";
import useReviewApprovalMutation from "hooks/mutations/directURL/useReviewApprovalMutation";
import useSignReviewDetailsQuery from "hooks/queries/sign/useSignReviewDetailsQuery";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function Review() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [scale, setScale] = useState("50%");
  const [pdf, setPdf] = useState<File | string | null>(null);
  const [pages, setPages] = useState<IPage[]>([]);
  const [selectedPageId, setSelectedPageId] = useState("");
  const [, setSelectedFieldType] = useState<Field>(null);
  const [selectedFieldId, setSelectedFieldId] = useState("");
  const pagesContainerRef = useRef<null[] | HTMLDivElement[]>([]);

  const { mutate: reviewApproval } = useReviewApprovalMutation();
  const { data: directUrlDetails } = useSignReviewDetailsQuery();

  const jwt =
    window.sessionStorage.getItem("jwt") || window.localStorage.getItem("jwt");

  const refreshJwt =
    window.sessionStorage.getItem("refreshJwt") ||
    window.localStorage.getItem("refreshJwt");

  const {
    isOpen: isRejectModalOpen,
    closeModal: closeRejectModal,
    openModal: openRejectModal,
  } = useModal();

  const selectField = (id: string, type: Field) => {
    if (
      pages
        ?.find((page) => page.id === selectedPageId)
        ?.fields.findIndex(
          (field) => `${field.fieldType}:${field.id}` === id
        ) !== -1
    ) {
      setSelectedFieldId(id);
      setSelectedFieldType(type);
    }
  };

  const scrollToPage = (pageId: string) => {
    document.getElementById(pageId)?.scrollIntoView({ behavior: "smooth" });
  };

  const handleApproval = () => {
    reviewApproval(undefined, {
      onSuccess: () => {
        if (jwt || refreshJwt) {
          navigate("/contract/dashboard/direct-url");
        } else {
          navigate("/auth/sign-in", {
            state: {
              ...state,
              isReview: true,
            },
          });
        }
      },
      onError: (error) => {
        console.error("계약 승인 실패", error);
      },
    });
  };

  useEffect(() => {
    if (directUrlDetails?.contractFile) {
      setPdf(directUrlDetails?.contractFile);
    }
  }, [directUrlDetails]);

  return (
    <>
      <Top>
        <div className="title">계약명 : {state?.title || ""}</div>
      </Top>
      <ToolbarStyled>
        <div className="left">
          <Body1Medium onClick={() => navigate(-1)}>나가기</Body1Medium>
        </div>
        <div className="right">
          <Button
            colorType="tertiary"
            size="medium"
            handleClick={openRejectModal}
          >
            <Body2Medium>거절하기</Body2Medium>
          </Button>
          <Button
            colorType="primary"
            size="medium"
            handleClick={handleApproval}
          >
            <Body2Medium>검토 완료</Body2Medium>
          </Button>
        </div>
      </ToolbarStyled>
      <ScaleControllerStyled>
        <Dropdown
          table
          selected={scale}
          setSelected={setScale}
          dropdownList={SCALES.map((scale) => ({
            label: `${scale}%`,
            value: `${scale}%`,
          }))}
          backgroundDimmedWhenOpened
        />
      </ScaleControllerStyled>
      <PreviewStyled>
        <Document
          className="pdf"
          file={pdf}
          noData={<></>}
          onLoadSuccess={(doc) => {
            setPages(() => {
              const firstPageId = uuidv4();
              setSelectedPageId(firstPageId);

              return Array(doc.numPages)
                .fill(0)
                .map((_, idx) => ({
                  id: idx === 0 ? firstPageId : uuidv4(),
                  order: idx + 1,
                  fields: (
                    directUrlDetails?.inputList?.filter(
                      (input) => input.page === idx + 1
                    ) || []
                  ).map((input) => ({
                    id: input.contractInputUuid,
                    fieldType: input.inputType,
                    type: input.isReceiversInput ? "receiver" : "sender",
                    width: input.width,
                    height: input.height,
                    position: {
                      x: input.xcoordinate,
                      y: input.ycoordinate,
                    },
                  })),
                }));
            });
          }}
        >
          <div className="left">
            <div className="pages">
              {pages.map((page) => (
                <div
                  className={`page${
                    selectedPageId === page.id ? " current" : ""
                  }`}
                  key={page.id}
                  onClick={() => {
                    setSelectedPageId(page.id);
                    scrollToPage(page.id);
                  }}
                >
                  <div className="label">
                    <Body1Regular>{page.order}</Body1Regular>
                  </div>
                  <Page
                    className="thumbnail"
                    height={140}
                    pageNumber={page.order}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="main">
            <div className="pdf-container">
              {pages.map((page, order) => (
                <PageContainer
                  key={page.id}
                  id={page.id}
                  order={order}
                  setSelectedPageId={setSelectedPageId}
                >
                  <Page
                    className="page"
                    pageNumber={page.order}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    scale={+scale.replace("%", "") / 50}
                  />
                  <div
                    className={`canvas:${page.id} canvas`}
                    onClick={() =>
                      setSelectedPageId && setSelectedPageId(page.id)
                    }
                    ref={(el) => {
                      if (!pagesContainerRef.current) return;

                      pagesContainerRef.current[order] = el;
                    }}
                  >
                    {page?.fields.map(
                      ({
                        id,
                        type,
                        fieldType,
                        selectCount,
                        position: { x, y },
                      }) =>
                        fieldType === "CHECKBOX" ? (
                          selectCount?.options?.map((option) => (
                            <Item
                              edit={type === "sender"}
                              preview
                              field={
                                FIELDS.find(
                                  (field) => field.value === fieldType
                                ) || FIELDS[0]
                              }
                              id={id}
                              optionId={option.id}
                              key={id}
                              pages={pages}
                              setPages={setPages}
                              type={type}
                              setSelectedFieldType={setSelectedFieldType}
                              selectedFieldId={selectedFieldId}
                              setSelectedFieldId={setSelectedFieldId}
                              selectedPageId={selectedPageId}
                              selectField={selectField}
                              scale={+scale.replace("%", "") / 100}
                              style={{
                                width: `max-content`,
                                height: `max-content`,
                                position: "absolute",
                                left:
                                  option.position.x *
                                  (+scale.replace("%", "") / 100),
                                top:
                                  option.position.y *
                                  (+scale.replace("%", "") / 100),
                                zIndex:
                                  selectedFieldId === `${fieldType}:${id}`
                                    ? 5
                                    : 4,
                                opacity: 1,
                              }}
                            />
                          ))
                        ) : (
                          <Item
                            edit={type === "sender"}
                            preview
                            templateSend
                            field={
                              FIELDS.find(
                                (field) => field.value === fieldType
                              ) || FIELDS[0]
                            }
                            id={id}
                            key={id}
                            pages={pages}
                            setPages={setPages}
                            type={type}
                            setSelectedFieldType={setSelectedFieldType}
                            selectedFieldId={selectedFieldId}
                            setSelectedFieldId={setSelectedFieldId}
                            selectedPageId={selectedPageId}
                            selectField={selectField}
                            scale={+scale.replace("%", "") / 100}
                            style={{
                              width: `max-content`,
                              height: `max-content`,
                              position: "absolute",
                              left: x * (+scale.replace("%", "") / 100),
                              top: y * (+scale.replace("%", "") / 100),
                              zIndex:
                                selectedFieldId === `${fieldType}:${id}`
                                  ? 5
                                  : 4,
                              opacity: 1,
                            }}
                          />
                        )
                    )}
                  </div>
                </PageContainer>
              ))}
            </div>
          </div>
        </Document>
      </PreviewStyled>
      {isRejectModalOpen && <ReviewRejectModal onClose={closeRejectModal} />}
    </>
  );
}

const Top = styled.div`
  width: 100%;
  height: 6.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-bottom: 0.1rem solid var(--stroke-light);

  .title {
    width: max-content;
    max-width: 103rem;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.8rem;
    letter-spacing: -0.04em;
    text-align: center;
  }
`;

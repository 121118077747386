import { getMonth, getYear } from "date-fns";
import styled from "styled-components";
import { breakpoints } from "components/styles";
import getYearRange from "utils/getYearRange";
import Button from "../Button/Button";
import React, { useState } from "react";
import { ReactComponent as ArrowUpIcon } from "assets/common/24px/icon_up.svg";

interface YearDropdownContentProps {
  date: Date;
  changeYear: (year: number) => void;
  closeContent: () => void;
  isMobile?: boolean;
}

const yearRange = getYearRange({
  startYear: getYear(new Date()),
  endYear: 2099,
  step: 1,
});

const YearDropdownContent = ({
  date,
  changeYear,
  closeContent,
  isMobile,
}: YearDropdownContentProps) => {
  const [selectedYear, setSelectedYear] = useState(getYear(date));
  const handleCloseContent = () => {
    closeContent();
  };

  const handleYearClick = (year: number) => {
    setSelectedYear(year);
  };

  const handleConfirmYear = () => {
    changeYear(selectedYear);
    closeContent();
  };
  return (
    <YearDropdownLayout>
      <div className="year-dropdown-title">
        {getYear(date)}년 {getMonth(date) + 1}월 <ArrowUpIcon />
      </div>
      <div className="year-dropdown-grid" role="menu">
        {yearRange.map((year) => (
          <DateOfYear
            key={year}
            onClick={() => handleYearClick(year)}
            role="menuitem"
            isSelected={selectedYear === year}
          >
            {year}
          </DateOfYear>
        ))}
      </div>
      <div className="year-dropdown-footer">
        <Button
          colorType="ghost"
          size={isMobile ? "small" : "large"}
          onClick={handleCloseContent}
        >
          취소
        </Button>
        <Button
          colorType="primary"
          size={isMobile ? "small" : "large"}
          onClick={handleConfirmYear}
        >
          확인
        </Button>
      </div>
    </YearDropdownLayout>
  );
};

const YearDropdownLayout = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2rem;
  border-radius: 2rem;

  background-color: var(--bg-white);
  text-align: left;

  color: var(--text-secondary);
  font-weight: 500;

  .year-dropdown-title {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    color: var(--text-default);
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
    margin-top: 1rem;

    @media (max-width: ${breakpoints.lg}) {
      margin-bottom: 1.5rem;
      margin-top: 0.5rem;
      font-size: 1.4rem;
    }
  }

  .year-dropdown-grid {
    display: grid;
    gap: 0.8rem;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 1.6rem;
    margin-bottom: 2.5rem;
    font-size: 1.8rem;
    overflow-y: scroll;
    height: 14rem;

    @media (max-width: ${breakpoints.lg}) {
      height: 10rem;
      font-size: 1.4rem;
      gap: 0.5rem;
    }

    div {
      cursor: pointer;
    }
  }

  .year-dropdown-footer {
    display: flex;
    justify-content: end;

    @media (max-width: ${breakpoints.lg}) {
      gap: 0.5rem;
    }
  }
`;

const DateOfYear = styled.div<{
  isSelected: boolean;
}>`
  color: ${({ isSelected }) =>
    isSelected ? "var(--primary-400)" : "var(--text-secondary)"};
`;

export default YearDropdownContent;

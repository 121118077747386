import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { device } from "components/styles";
import { MobileBoard } from "components/Mobile/Common/Board/Board";
import { ReactComponent as LogoIcon } from "assets/common/icon_logo.svg";
import { ReactComponent as CloseIcon } from "assets/common/24px/icon_close_default.svg";
import { ReactComponent as MenuIcon } from "assets/common/icon_menu.svg";
import { ReactComponent as LeftIcon } from "assets/common/36px/icon_left_large.svg";
import useUserState from "hooks/recoil/useUserState";
import { useLocation } from "react-router-dom";
import NotificationDropDownMenu from "./Notification/NotificationDropDownMenu";
import UserDropDownMenu from "./User/UserDropDownMenu";
import { MENU_ITEMS } from "./MenuItemsConstant";
import { MenuItem, SubMenuItem } from "./MenuItems";
import {
  MenuItemsContainer,
  MenuItemContainer,
  SubMenuItemsContainer,
} from "./style";
import { Title1 } from "components/TextStyle";
import { Organization } from "models/Organization";
import { Role } from "models/OrganizationGroup";

const AppHeader = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getPathLabel = () => {
    if (pathname.match(/^\/contract\/[\w-]+$/)) {
      return "상세보기";
    }

    const menu = MENU_ITEMS.find(
      (menu) =>
        menu.to === pathname ||
        menu.include?.includes(pathname) ||
        menu.subMenu?.some(
          (subMenu) =>
            subMenu.to === pathname || subMenu.include.includes(pathname)
        )
    );

    if (pathname.includes("setting")) {
      const subMenu = menu?.subMenu?.find(
        (sub) => sub.to === pathname || sub.include.includes(pathname)
      );

      if (pathname.includes("billing")) {
        return "결제하기";
      }

      return subMenu?.label || menu?.label || "";
    }

    return menu?.label || "";
  };

  const currentLabel = getPathLabel();

  return (
    <HeaderLayout>
      {pathname === "/dashboard" ||
      ["/profile", "/alert"].some((path) => pathname.includes(path)) ? (
        <>
          <Link to={"/dashboard"}>
            <LogoIcon />
          </Link>
          <HeaderRightContainer>
            <NotificationDropDownMenu />
            <UserDropDownMenu />
            <MobileMenu />
          </HeaderRightContainer>
        </>
      ) : (
        <>
          <button onClick={() => navigate(-1)}>
            <LeftIcon />
          </button>
          <Title1>{currentLabel}</Title1>
          <MobileMenu />
        </>
      )}
    </HeaderLayout>
  );
};

export default AppHeader;

export interface MenuItems {
  label: string;
  icon: string;
  activeIcon: string;
  to: string;
  include?: string[];
  subMenu?: {
    label: string;
    icon: string;
    activeIcon: string;
    to: string;
    include: string[];
    permission?: {
      organization: Organization["permission"][];
      organization_group?: Role[];
    };
  }[];
  hidden?: boolean;
}

const MobileMenu = () => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [user] = useUserState();

  const handleToggle = () => {
    setToggle((pre) => !pre);
  };

  React.useEffect(() => {
    if (toggle) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [toggle]);

  React.useEffect(() => {
    setToggle(false);
  }, [pathname]);

  return (
    <>
      <button onClick={handleToggle}>
        {toggle ? <CloseIcon /> : <MenuIcon />}
      </button>
      {toggle && (
        <MobileMenuLayout>
          <MenuItemsContainer>
            {MENU_ITEMS.filter((item) => !item.hidden).map(
              (menuItem: MenuItems, idx: number) => {
                const { subMenu, to, include } = menuItem;
                const isSelectedMenu =
                  pathname.split("/")[1].includes(to.split("/")[1]) ||
                  include?.some((sub) =>
                    sub.split("/")[1].includes(pathname.split("/")[1])
                  );

                const isIncludeSubMenu =
                  (pathname === menuItem.to ||
                    subMenu?.some((sub) => sub.include.includes(pathname))) ??
                  false;

                const handleMenu = () => {
                  setToggle(false);
                  navigate(to);
                };

                return (
                  <MenuItemContainer key={idx}>
                    <MenuItem
                      handleClick={handleMenu}
                      isSelected={isSelectedMenu || isIncludeSubMenu}
                      menuItem={menuItem}
                    />

                    {isIncludeSubMenu && (
                      <SubMenuItemsContainer>
                        {subMenu?.map((subMenuItem, idx: number) => {
                          const isSelectedSubMenu =
                            subMenuItem.include.includes(pathname);
                          const handleSubMenu = () => {
                            setToggle(false);
                            navigate(subMenuItem.to);
                          };
                          const isOrganizationPermission =
                            !subMenuItem.permission?.organization ||
                            (user.organization_permission &&
                              subMenuItem.permission.organization.includes(
                                user.organization_permission
                              ));

                          return (
                            isOrganizationPermission && (
                              <SubMenuItem
                                key={idx}
                                isSelected={isSelectedSubMenu}
                                subMenuItem={subMenuItem}
                                handleClick={handleSubMenu}
                              />
                            )
                          );
                        })}
                      </SubMenuItemsContainer>
                    )}
                  </MenuItemContainer>
                );
              }
            )}
          </MenuItemsContainer>
        </MobileMenuLayout>
      )}
    </>
  );
};

const HeaderLayout = styled.header`
  background-color: var(--bg-white);
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  border-bottom: 1px solid var(--grey-300);
  background: var(--bg-white);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  height: 6.8rem;
  box-sizing: border-box;
  width: 100%;

  z-index: 200;

  > button > svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

const HeaderRightContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${device.md}, ${device.sm} {
    gap: 3rem;
  }

  @media ${device.xs} {
    gap: 2rem;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0;

    > svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;

const MobileMenuLayout = styled(MobileBoard)`
  position: absolute;
  background-color: var(--bg-white);
  top: 6.8rem;
  left: 0;
  width: 100%;
  height: calc(100vh - 6.8rem);
  overflow-y: auto;
  z-index: 100;
`;

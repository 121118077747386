import React, { useState } from "react";
import {
  InputContainer,
  SupportContainer,
  SupportContentContainer,
} from "pages/setting/support/(mobile)/styles";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import { Body3Regular } from "components/TextStyle";
import Dropdown from "components/Mobile/Common/Dropdown/Dropdown";
import Input from "components/Mobile/Common/Input/Input";
import TextArea from "components/Mobile/Common/Input/TextArea";
import Button from "components/Mobile/Common/Button/Button";
import CheckBox from "components/Mobile/Common/CheckBox/CheckBox";
import { REGEX } from "config/regex";
import useInquiriesMutation from "hooks/mutations/inquiries/useInquiriesMutation";
import { PostAppInquiriesRequest } from "models/Inquiries";
import { formatPhoneNumber } from "utils/utility";
import { toast } from "react-toastify";
import useInquiriesAllTypesQuery from "hooks/queries/inquiry/useInquiriesAllTypesQuery";
import LandingMobileNavigation from "pages/(landing)/(mobile)/LandingMobileNavigation";
import { LayoutContainer } from "components/Mobile/landing/actionLayout";

type InquiryFormData = Omit<PostAppInquiriesRequest, "personalInfoTermAgree">;

const LandingSupportMobilePage = () => {
  return (
    <LayoutContainer>
      <LandingMobileNavigation />
      <SupportBox />
    </LayoutContainer>
  );
};

export default LandingSupportMobilePage;

export const SupportBox = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm<InquiryFormData>({
    mode: "onTouched",
    defaultValues: {
      serviceInquiryType: "",
      email: "",
      name: "",
      inquiryContent: "",
    },
  });
  const [isChecked, setIsChecked] = useState(false);

  const { mutate } = useInquiriesMutation();

  const handleInquirySubmit: SubmitHandler<InquiryFormData> = (data) => {
    const { contactPhoneNumber, companyName, ...restData } = data;

    const inquiriesData = {
      ...restData,
      ...(contactPhoneNumber && { contactPhoneNumber }),
      ...(companyName && { companyName }),
      personalInfoTermAgree: isChecked,
    };

    mutate(inquiriesData, {
      onSuccess: () => {
        toast("문의에 대한 답변은 메일로 전송됩니다.");
        reset();
      },
    });
  };

  const { field: inquiryTypeField, fieldState: inquiryTypeFieldState } =
    useController({
      name: "serviceInquiryType",
      control,
      rules: {
        required: "true",
      },
    });

  const { field: phoneNumberField } = useController({
    name: "contactPhoneNumber",
    control,
  });

  const { data: typeListData } = useInquiriesAllTypesQuery();

  const dropdownList =
    typeListData?.flatMap((e) => {
      return {
        value: e.typeName,
        label: e.typeName,
      };
    }) || [];

  return (
    <SupportContainer onSubmit={handleSubmit(handleInquirySubmit)}>
      <div className="inputContainer">
        <SupportContentContainer>
          <InputContainer>
            <Body3Regular>문의 종류</Body3Regular>
            <Dropdown
              placeholder="문의 종류를 선택해 주세요"
              selected={inquiryTypeField.value}
              setSelected={inquiryTypeField.onChange}
              dropdownList={dropdownList}
              isError={inquiryTypeFieldState.error ? true : false}
            />
          </InputContainer>
          <InputContainer>
            <Body3Regular>이름</Body3Regular>
            <Input
              placeholder="이름을 입력해 주세요"
              {...register("name", {
                required: "이름을 입력해주세요.",
              })}
              isError={errors.name ? true : false}
              errorMessage={errors.name?.message}
              maxLength={30}
              width="100%"
            />
          </InputContainer>
          <InputContainer>
            <Body3Regular>연락처(선택)</Body3Regular>
            <Input
              placeholder="연락처를 입력해 주세요"
              {...register("contactPhoneNumber")}
              maxLength={13}
              value={phoneNumberField.value}
              onChange={(e) => {
                phoneNumberField.onChange(formatPhoneNumber(e.target.value));
              }}
            />
          </InputContainer>
          <InputContainer>
            <Body3Regular>이메일</Body3Regular>
            <Input
              placeholder="이메일을 입력해 주세요"
              {...register("email", {
                required: "이메일 주소를 입력하세요",
                pattern: {
                  value: REGEX.email,
                  message: "유효한 이메일 주소를 입력해 주세요",
                },
              })}
              isError={errors.email ? true : false}
              errorMessage={errors.email?.message}
              maxLength={50}
            />
          </InputContainer>
          <InputContainer>
            <Body3Regular>회사명(선택)</Body3Regular>
            <Input
              placeholder="회사명을 입력해 주세요"
              {...register("companyName")}
              maxLength={20}
            />
          </InputContainer>
          <InputContainer>
            <Body3Regular>문의 내용</Body3Regular>
            <div></div>
            <TextArea
              placeholder="문의 내용을 입력해주세요"
              maxLength={1000}
              {...register("inquiryContent", {
                required: true,
              })}
              isError={errors.inquiryContent ? true : false}
            />
          </InputContainer>
          <div className="checkboxContainer">
            <CheckBox
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <Body3Regular className="checkboxText">
              <a
                href="https://softsquared.notion.site/b145b6a389704010a4a8c7c59f625d86?pvs=4"
                target="_blank"
                rel="noopener noreferrer"
              >
                개인정보 수집 및 이용
              </a>
              에 동의합니다.
            </Body3Regular>
          </div>
        </SupportContentContainer>
      </div>

      <Button
        colorType="primary"
        size="large"
        type="submit"
        isDisabled={!isValid || !isChecked}
      >
        문의하기
      </Button>
    </SupportContainer>
  );
};

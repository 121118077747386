import React, { useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import styled from "styled-components";
import * as Slider from "@radix-ui/react-slider";
import { Layer, Stage } from "react-konva";
import { ReactComponent as ImageIcon } from "assets/common/20px/icon_image.svg";
import { ReactComponent as RefreshIcon } from "assets/common/30px/icon_refresh.svg";
import {
  Body1Regular,
  Body1SemiBold,
  Body2Medium,
  Body3Regular,
} from "components/TextStyle";
import Button from "components/Common/Button/Button";
import ImagePreview from "./ImagePreview";
import { tertiaryButtonState } from "components/Common/Button/ButtonStyledComponents";
import useFileUpload from "hooks/useFileUpload";
import useSignaturesMutation from "hooks/mutations/signature/useSignaturesMutation";
import useImageStamp from "hooks/useImageStamp";

interface UploadStampContentProps {
  closeModal: () => void;
  signMode?: boolean;
  sign?: (file: File) => void;
}

const UploadStampContent = ({
  closeModal,
  signMode = false,
  sign,
}: UploadStampContentProps) => {
  const { mutate } = useSignaturesMutation();
  const { files, fileInputProps, inputFor, removeFile, isError } =
    useFileUpload({
      types: ["image/jpeg", "image/png", "image/jpg"],
      size: 2,
      maxFileCount: 1,
      limitNameLength: 40,
    });

  const file = files?.[0];
  const isFileSelected = !!file;

  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    width: 100,
    height: 100,
    x: 0,
    y: 0,
  });

  const {
    contrast,
    setContrast,
    rotation,
    rotateRight,
    stageRef,
    resetEdit,
    createStamp,
  } = useImageStamp();

  const onCropComplete = async (editedFile: File, crop: Crop) => {
    return new Promise<File | null>((resolve) => {
      if (!editedFile || !crop.width || !crop.height) {
        resolve(null);
        return;
      }

      const image = new Image();
      image.src = URL.createObjectURL(editedFile);

      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = crop.width;
        canvas.height = crop.height;

        const ctx = canvas.getContext("2d");
        if (!ctx) {
          resolve(null);
          return;
        }

        ctx.drawImage(
          image,
          crop.x,
          crop.y,
          crop.width,
          crop.height,
          0,
          0,
          crop.width,
          crop.height
        );

        canvas.toBlob((blob) => {
          if (!blob) {
            resolve(null);
            return;
          }
          const croppedImage = new File([blob], editedFile.name, {
            type: "image/png",
          });
          resolve(croppedImage);
        }, "image/png");

        URL.revokeObjectURL(image.src);
      };
    });
  };

  const handleSaveClick = async () => {
    const editedFile = await createStamp();
    if (!editedFile) return console.log("editedFile is null");

    const croppedFile = await onCropComplete(editedFile, crop);
    if (!croppedFile) return console.log("croppedFile is null");

    if (signMode && sign) {
      sign(croppedFile);
      closeModal();
    } else {
      mutate(croppedFile, {
        onSuccess: () => {
          closeModal();
        },
      });
    }
  };

  const handleResetImageClick = () => {
    removeFile(0);
    resetEdit();
  };

  return (
    <>
      <Body1Regular>
        실제 도장을 찍은 사진을 업로드 해 주세요. (2MB이하의 이미지 파일)
      </Body1Regular>
      {!isFileSelected ? (
        <UploadButton
          colorType={"primary"}
          size="medium"
          {...(!isFileSelected ? inputFor : {})}
        >
          <input type="file" {...fileInputProps} />
          <UploadName>도장 이미지 업로드 하기</UploadName>
        </UploadButton>
      ) : (
        <ResetUpload>
          <UploadName>
            {file.name}
            {isFileSelected && <a onClick={handleResetImageClick}>취소</a>}
          </UploadName>
        </ResetUpload>
      )}

      <EmptyImagePreview
        isError={Object.values(isError).some((value) => value === true)}
      >
        {isFileSelected ? (
          <ReactCrop crop={crop} onChange={setCrop} aspect={1}>
            <Stage width={250} height={250} ref={stageRef}>
              <Layer>
                <ImagePreview
                  key={file.name}
                  file={file}
                  contrast={contrast}
                  rotation={rotation}
                />
              </Layer>
            </Stage>
          </ReactCrop>
        ) : (
          <ImageIcon width={42} height={42} />
        )}
      </EmptyImagePreview>
      {isError.nameLength && (
        <Body3Regular
          style={{
            marginTop: "0.6rem",
            color: "#FA4D56",
          }}
        >
          이미지 파일명이 길어 업로드가 어렵습니다. 파일명 변경 후 다시
          적용해주세요
        </Body3Regular>
      )}
      {isError.size && (
        <Body3Regular
          style={{
            marginTop: "0.6rem",
            color: "#FA4D56",
          }}
        >
          지원하지 않는 파일 크기입니다. 2MB 이하의 이미지 파일을 적용해주세요.
        </Body3Regular>
      )}
      <ImageEditFooter>
        {isFileSelected && (
          <div>
            <Body2Medium>약한 대비</Body2Medium>
            <SliderRoot
              className="SliderRoot"
              defaultValue={[0]}
              max={100}
              min={-100}
              step={1}
              onValueChange={(value) => {
                setContrast(value[0]);
              }}
            >
              <Slider.Track className="SliderTrack">
                <Slider.Range className="SliderRange" />
              </Slider.Track>
              <Slider.Thumb className="SliderThumb" aria-label="Volume" />
            </SliderRoot>
            <Body2Medium>강한 대비</Body2Medium>
          </div>
        )}
        <Button
          colorType="tertiary"
          size="medium"
          onClick={rotateRight}
          disabled={!isFileSelected}
        >
          <RefreshIcon />
          이미지 회전하기
        </Button>
      </ImageEditFooter>
      <ModalFooter signMode={signMode}>
        {signMode ? (
          <>
            <Button colorType="tertiary" size="large" onClick={closeModal}>
              취소
            </Button>
            <Button colorType="primary" size="large" onClick={handleSaveClick}>
              확인
            </Button>
          </>
        ) : (
          <Button
            colorType="primary"
            size="large"
            onClick={handleSaveClick}
            disabled={!isFileSelected}
          >
            도장 이미지 업로드 하기
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

export default UploadStampContent;

const ModalFooter = styled.div<{ signMode?: boolean }>`
  display: ${({ signMode }) => (signMode ? "flex" : "block")};
  justify-content: ${({ signMode }) => (signMode ? "center" : "unset")};
  margin: 2rem 0;
  margin-top: ${({ signMode }) => (signMode ? "5.6rem" : "2rem")};
  gap: ${({ signMode }) => (signMode ? "3rem" : "unset")};

  > button {
    width: ${({ signMode }) => (signMode ? "24rem" : "unset")};
    display: block;
    margin: ${({ signMode }) => (signMode ? "unset" : "auto")};
  }
`;

const ImageEditFooter = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
  > div:first-child {
    display: flex;
    gap: 1rem;

    position: absolute;

    left: 50%;
    transform: translateX(-50%);
  }

  > button {
    margin-left: auto;
  }
`;

const EmptyImagePreview = styled.div<{ isError: boolean }>`
  background-color: ${({ isError }) =>
    isError ? "#FFF9F9" : "var(--primary-100)"};

  width: 100%;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ isError }) =>
    isError
      ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23DB5257FF' stroke-width='1' stroke-dasharray='2' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
      : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%235B96FFFF' stroke-width='1' stroke-dasharray='2' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`};

  margin-top: 2rem;

  svg {
    color: ${({ isError }) => (isError ? "#DB5257" : "#5b96ff")};
    opacity: 0.2;
  }
`;

const UploadButton = styled(Button)`
  margin-top: 2rem;

  input {
    display: none;
  }
`;

const UploadName = styled(Body1SemiBold)`
  display: flex;
  gap: 1rem;
`;

const SliderRoot = styled(Slider.Root)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 200px;
  height: 20px;

  .SliderTrack {
    background-color: #f2f4f8;
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
    height: 0.6rem;
  }

  .SliderThumb {
    display: block;
    width: 3rem;
    height: 1.2rem;
    background-color: var(--blue-500);
    border-radius: 10px;
  }
`;

const ResetUpload = styled.div`
  ${tertiaryButtonState.default}

  width: fit-content;
  padding: 0.8rem 1.6rem;
  border-radius: 5px;
  margin-top: 2rem;

  &:hover {
    border: 1px solid var(--grey-400);
    background-color: var(--bg-white);
    color: var(--grey-600);
  }

  a {
    text-decoration: underline;
    font-weight: 400;
    color: var(--text-secondary);
  }
`;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTemplateByIdFavoriteById } from "apis/template";
import { queries } from "config/queryKey";
import { toast } from "react-toastify";

const useDeleteTemplateFavoriteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteTemplateByIdFavoriteById,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.template.list._def,
      });
      toast("즐겨찾기 해제되었습니다.");
    },
  });
};

export default useDeleteTemplateFavoriteMutation;

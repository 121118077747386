import Button from "components/Common/Button/Button";
import Dropdown from "components/Common/Dropdown/Dropdown";
import SearchInput from "components/Common/Input/SearchInput";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "assets/common/20px/icon_plus.svg";
import useModal from "hooks/useModal";
import TemplateDeleteModal from "../Modals/TemplateDeleteModal";
import useDeleteTemplateMutation from "hooks/mutations/template/useDeleteTemplateMutation";
import useUserState from "hooks/recoil/useUserState";
import { match } from "ts-pattern";
import { useNavigate } from "react-router-dom";
import { Body2Regular } from "components/TextStyle";
import { GetTemplateListByIdResponse } from "models/Template";
import { toast } from "react-toastify";
import useStandardAvailabilityCheck from "hooks/queries/payment/useStandardAvailabilityCheckQuery";

type Option = "ALL" | "CREATED_USER_NAME" | "TEMPLATE_NAME";

type SearchOptionsType = {
  searchType: Option;
  keyword: string;
};
interface FormData {
  search: string;
  option: Option;
}

interface DashboardHeaderProps<T extends Partial<SearchOptionsType>> {
  checkedRow: string[];
  setSearchOptions: React.Dispatch<React.SetStateAction<T>>;
  searchOptions?: Option[];
  templateList?: GetTemplateListByIdResponse["templateList"]["contents"];
}

const DashboardHeader = <T extends Partial<SearchOptionsType>>({
  checkedRow,
  setSearchOptions,
  searchOptions = ["ALL", "CREATED_USER_NAME", "TEMPLATE_NAME"],
  templateList,
}: DashboardHeaderProps<T>) => {
  const navigate = useNavigate();
  const SEARCH_OPTION_DROPDOWN = searchOptions.map((option) => {
    const label = match(option)
      .with("ALL", () => "전체")
      .with("CREATED_USER_NAME", () => "등록자")
      .with("TEMPLATE_NAME", () => "템플릿 이름")
      .exhaustive();

    return {
      label,
      value: option,
    };
  });

  const { register, control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      search: "",
      option: searchOptions[0],
    },
  });
  const [user] = useUserState();
  const { mutate: templateDeleteMutate } = useDeleteTemplateMutation();
  const { openModal, closeModal, isOpen } = useModal();
  const searchSubmit = (data: FormData) => {
    match(data.option)
      .with("ALL", () =>
        setSearchOptions((pre) => ({
          ...pre,
          searchType: "ALL",
          keyword: data.search,
        }))
      )
      .with("CREATED_USER_NAME", () =>
        setSearchOptions((pre) => ({
          ...pre,
          searchType: "CREATED_USER_NAME",
          keyword: data.search,
        }))
      )
      .with("TEMPLATE_NAME", () => {
        setSearchOptions((pre) => ({
          ...pre,
          searchType: "TEMPLATE_NAME",
          keyword: data.search,
        }));
      });
  };

  const handleDelete = () => {
    if (!user.organization) return;
    templateDeleteMutate({
      organizationId: user.organization,
      organizationGroupId: user.organizationGroup,
      templateIdList: checkedRow,
    });
    closeModal();
  };

  const { data: isAboveStandard } = useStandardAvailabilityCheck(
    user?.organization
  );

  const isAvailableToCreate = () => {
    if (
      (templateList?.filter(
        (template) => template.templateStatus === "COMPLETE"
      )?.length as number) >= 1 &&
      !isAboveStandard?.isAvailable
    ) {
      toast("템플릿은 최대 1개까지 만들 수 있습니다.");
      return;
    }

    navigate("/template/create/upload");
  };

  return (
    <>
      {isOpen && (
        <TemplateDeleteModal
          closeModal={closeModal}
          handleDelete={handleDelete}
          deleteCount={checkedRow.length}
        />
      )}
      <DashboardHeaderLayout>
        <form
          className="search-aside-form"
          onSubmit={handleSubmit(searchSubmit)}
        >
          <Button
            type="button"
            colorType="ghost"
            size="medium"
            disabled={checkedRow.length < 1}
            onClick={openModal}
          >
            <Body2Regular>삭제</Body2Regular>
          </Button>
          <Controller
            control={control}
            name="option"
            render={({ field: { onChange, value, ref } }) => (
              <SearchDropdown
                selected={value}
                setSelected={onChange}
                dropdownList={SEARCH_OPTION_DROPDOWN}
                ref={ref}
                outline={false}
              />
            )}
          />
          <SearchInput
            style={{
              backgroundColor: "var(--bg-white)",
            }}
            {...register("search")}
            placeholder="등록자, 템플릿 이름"
          />
        </form>
        {user.organizationGroup_permission !== "MEMBER" && (
          <Button
            colorType="primary"
            size="large"
            onClick={isAvailableToCreate}
          >
            <PlusIcon fill="#ffffff" />
            템플릿 만들기
          </Button>
        )}
      </DashboardHeaderLayout>
    </>
  );
};

export default DashboardHeader;

const DashboardHeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 3.2rem;

  .search-aside-form {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
`;

const SearchDropdown = styled(Dropdown)`
  background-color: var(--bg-white);
  width: 14.4rem;
  padding: 0.7rem 1.6rem;
  border: none;

  height: fit-content;
  > * {
    line-height: 2.2rem !important;
    color: #666f7b !important;
  }
`;

import Button from "components/Common/Button/Button";
import ContentModal from "components/Common/Modal/ContentModal";
import ContractModeModal from "components/Contract/ContractModeModal";
import ParaphStampModal from "components/Contract/ParaphStampModal";
import { Body1Medium } from "components/TextStyle";
import ContractConfigForm, {
  ContractConfigFormData,
} from "components/template/Config/ContractConfigForm";
import {
  ConfigContent,
  ConfigNavigation,
  ContentLayout,
} from "components/template/Config/Layout";
import MailConfigForm, {
  MailConfigFormData,
} from "components/template/Config/MailConfigForm";
import { SingleReceiverConfigFormData } from "components/template/Config/SingleReceiverConfigForm";
import { addDays } from "date-fns";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useContractRemainCreditsQuery from "hooks/queries/contract/useContractRemainCreditsQuery";
import { isValidEmail, isValidPhoneNumberInput } from "utils/utility";

export interface ConfigFormData
  extends SingleReceiverConfigFormData,
    MailConfigFormData,
    ContractConfigFormData {}

export default function TemplateSendConfigLayout({
  children,
}: PropsWithChildren) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { templateId } = useParams();
  const { data, refetch } = useContractRemainCreditsQuery();
  const remainCredits = data?.remainContractCredit.remainCredits || 0;

  const methods = useForm<ConfigFormData>({
    defaultValues: {
      contractClass: "NORMAL",
      ganinImageUrl: "",
      mail: {
        type: "DEFAULT",
        title: "",
        content: "",
      },
      contractName: "",
      contractType:
        pathname.split("/")[pathname.split("/").length - 1] === "many"
          ? "MANY_SEQ"
          : "SINGLE",
      password: {
        password: "",
        isUsed: false,
      },
      isPhoneNumberAuth: {
        isUsed: false,
      },
      expired: {
        expired: true,
        expiredAt: addDays(new Date(), 7),
      },
      receivers: [],
    },
  });

  const [contractModeModalOpened, setContractModeModalOpened] = useState(false);
  const [stampModalOpened, setStampModalOpened] = useState(false);

  const {
    password: { isUsed: passwordIsUsed },
    isPhoneNumberAuth: { isUsed: phoneNumberAuthIsUsed },
  } = methods.watch();

  const passwordValue = methods.getValues("password.password");
  const openContractModeModal = async () => {
    const { contractName, receivers } = methods.getValues();

    // 계약명 입력 확인
    if (!contractName || contractName.trim() === "") {
      toast("계약명을 입력해주세요.");
      return;
    }

    // 수신자 정보 확인
    if (
      receivers.some(
        (receiver) =>
          !receiver.name ||
          receiver.name.trim() === "" ||
          ((!receiver.email || receiver.email.trim() === "") &&
            (!receiver.phoneNumber || receiver.phoneNumber.trim() === "")) ||
          (receiver.email && !isValidEmail(receiver.email)) ||
          (receiver.phoneNumber &&
            !isValidPhoneNumberInput(receiver.phoneNumber))
      ) ||
      receivers.length === 0
    ) {
      toast("수신자 설정을 확인해주세요.");
      return;
    }

    // 암호가 on인데 암호가 없거나 길이가 4~12자가 아닌 경우
    if (
      passwordIsUsed &&
      (passwordValue.length < 4 || passwordValue.length > 12)
    ) {
      toast("암호는 4 ~ 12자까지 입력 가능합니다.");
      return;
    }

    // 유효 크레딧 확인
    await refetch();
    if (remainCredits < receivers.length) {
      toast(`전송할 수 있는 잔여 건수가 충분하지 않습니다.`);
      return;
    }

    if (!templateId) return;

    setContractModeModalOpened(true);
  };

  const goToPreview = async (ganinImageUrl?: string) => {
    if (ganinImageUrl) {
      methods.setValue("ganinImageUrl", ganinImageUrl);
      methods.setValue("contractClass", "GANIN");
    }

    navigate(`/template/send/${templateId}/preview`, {
      state: {
        data: methods.getValues(),
      },
    });
  };

  useEffect(() => {
    if (passwordIsUsed && phoneNumberAuthIsUsed)
      methods.setValue("isPhoneNumberAuth.isUsed", false);

    if (!passwordIsUsed) {
      methods.setValue("password.password", "");
    }
  }, [passwordIsUsed]);

  useEffect(() => {
    if (passwordIsUsed && phoneNumberAuthIsUsed) {
      methods.setValue("password.isUsed", false);
      methods.setValue("password.password", "");
    }
  }, [phoneNumberAuthIsUsed]);

  return (
    <>
      <ConfigNavigation>
        <Body1Medium className={"prev"} onClick={() => navigate(-1)}>
          나가기
        </Body1Medium>
        <Button
          colorType="primary"
          size="medium"
          onClick={openContractModeModal}
        >
          다음
        </Button>
      </ConfigNavigation>
      <ContentLayout>
        <ConfigContent>
          <FormProvider {...methods}>
            <ContractConfigForm />
            {children}
            <MailConfigForm />
          </FormProvider>
        </ConfigContent>
      </ContentLayout>
      {contractModeModalOpened && (
        <ContentModal
          size="medium"
          title="계약 형식 선택"
          handleModalClose={() => setContractModeModalOpened(false)}
        >
          <ContractModeModal
            methods={methods}
            setContractModeModalOpened={setContractModeModalOpened}
            setStampModalOpened={setStampModalOpened}
            goToPreview={goToPreview}
          />
        </ContentModal>
      )}
      {stampModalOpened && (
        <ParaphStampModal
          setContractModeModalOpened={setContractModeModalOpened}
          setStampModalOpened={setStampModalOpened}
          goToPreview={goToPreview}
        />
      )}
    </>
  );
}

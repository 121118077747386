import useServicePlanQuery from "hooks/queries/payment/useServicePlanQuery";

const RATE = 30; //Standard, Premium 간인 할인율
const BASIC = 5000;
const STANDARD = 60000;
const PREMIUM = 60000;

const usePrice = () => {
  const { data, isFetching } = useServicePlanQuery();

  const changePlanName = (planName: string) =>
    planName === "Premium" ? "Premium 간인" : planName;

  const defaultPrice = (planName: string) => {
    if (planName === "Basic") return BASIC;
    if (planName === "Standard") return STANDARD;
    if (planName === "Premium 간인" || planName === "Premium") return PREMIUM;
    return 0;
  };

  const renderPlanPrice = (name: string) => {
    const planName = changePlanName(name);
    if (planName === "Free") return 0;
    return !isFetching && data
      ? data.filter((e) => e.name === planName)[0]?.price
      : defaultPrice(planName);
  };

  const renderDiscountPrice = (name: string) => {
    const planName = changePlanName(name);
    return !isFetching && data
      ? data.filter((e) => e.name === planName)[0]?.price * (1 - RATE * 0.01)
      : 60000 * (1 - RATE * 0.01);
  };

  const renderVat = (planName: string) => {
    if (planName === "Free") return 0;
    if (planName === "Standard" || planName === "Premium")
      return renderDiscountPrice(planName) * 0.1;
    return renderPlanPrice(planName) * 0.1;
  };

  const renderTotalPrice = (planName: string) => {
    if (planName === "Free") return 0;
    if (planName === "Standard" || planName === "Premium")
      return renderVat(planName) + renderDiscountPrice(planName);
    return renderVat(planName) + renderPlanPrice(planName);
  };

  return {
    renderPlanPrice,
    renderDiscountPrice,
    renderVat,
    renderTotalPrice,
    RATE,
    changePlanName,
  };
};

export default usePrice;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchReviewDeny } from "apis/sign";
import { queries } from "config/queryKey";

const useReviewDenyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchReviewDeny,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.directURL._def,
      });
    },
  });
};

export default useReviewDenyMutation;

import { ReactComponent as DeleteIcon } from "assets/common/24px/icon_close_default.svg";
import { ReactComponent as CheckBoxCheckedIcon } from "assets/common/checkbox/icon_checkbox_selected.svg";
import { ReactComponent as CheckBoxNotCheckedIcon } from "assets/common/checkbox/icon_checkbox_unselected.svg";
import { ReactComponent as RadioButtonCheckedIcon } from "assets/common/radio/icon_radio_selected.svg";
import { ReactComponent as RadioButtonNotCheckedIcon } from "assets/common/radio/icon_radio_unselected.svg";
import Tooltip from "components/Common/Tooltip";
import { Body3Regular } from "components/TextStyle";
import { IField, IPage } from "interfaces/contract";
import { TextAlign } from "models/Contract";
import { Field } from "pages/contract/send";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { DraggableStyled } from "./styles";

interface SelectionProps extends React.HTMLAttributes<HTMLDivElement> {
  edit?: boolean;
  complete?: boolean;
  id: string;
  optionId: string;
  type: "sender" | "receiver";
  fields: IField[];
  pages: IPage[];
  setPages: React.Dispatch<React.SetStateAction<IPage[]>>;
  selectedPageId: string;
  selectedFieldId?: string;
  onDeleteHandler: (id: string, optionid?: string) => void;
  selectField?: (id: string, type: Field) => void;
  scale: number;
  align?: ({
    type,
    align,
    all,
  }: {
    type: Field;
    align?: TextAlign | undefined;
    all?: boolean | undefined;
  }) => void;
  newField?: boolean;
  isMobile?: boolean;
}

const InitialSelection = ({
  tooltip,
  description,
  name,
  id,
  type,
  checked,
  onChange,
  disabled,
}: {
  tooltip: boolean;
  description: string;
  name: string;
  id: string;
  type: "checkbox" | "radio" | undefined;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}) => {
  return !disabled && tooltip && description !== "" ? (
    <Tooltip
      align="center"
      side="top"
      trigger={
        <label htmlFor={id}>
          <input
            type={type}
            id={id}
            checked={checked}
            onChange={onChange}
            name={name}
          />
          {type === "checkbox" &&
            (checked ? <CheckBoxCheckedIcon /> : <CheckBoxNotCheckedIcon />)}
          {type === "radio" &&
            (checked ? (
              <RadioButtonCheckedIcon />
            ) : (
              <RadioButtonNotCheckedIcon />
            ))}
        </label>
      }
    >
      <Body3Regular>{description}</Body3Regular>
    </Tooltip>
  ) : (
    <label htmlFor={id}>
      <input
        type={type}
        id={id}
        checked={checked}
        onChange={onChange}
        name={name}
        disabled={disabled}
      />
      {type === "checkbox" &&
        (checked ? <CheckBoxCheckedIcon /> : <CheckBoxNotCheckedIcon />)}
      {type === "radio" &&
        (checked ? <RadioButtonCheckedIcon /> : <RadioButtonNotCheckedIcon />)}
    </label>
  );
};

export default function Selection({
  edit = false,
  complete = false,
  id,
  optionId,
  type,
  fields,
  pages,
  setPages,
  selectedPageId,
  selectedFieldId,
  onDeleteHandler,
  selectField,
  scale,
  draggable,
  align,
  newField,
  isMobile,
  ...args
}: SelectionProps) {
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setPages((prev) =>
      prev.map((page) => {
        if (isMobile) {
          if (page.order.toString() === selectedPageId) {
            return {
              ...page,
              fields: page.fields.map((field) => {
                if (`${field.fieldType}:${field.id}` === id) {
                  return {
                    ...field,
                    selectCount: {
                      ...field.selectCount,
                      options: field.selectCount?.options?.map((option) => {
                        if (`${field.fieldType}:${option.id}` === optionId) {
                          if (checked) {
                            if (field.buttonType === "radio") {
                              return { ...option, checked };
                            } else {
                              if (
                                field.selectCount?.mode === "BELOW" ||
                                field.selectCount?.mode === "MATCH"
                              ) {
                                if (
                                  (field.selectCount?.number as number) <=
                                  (field.selectCount.options?.filter(
                                    (option) => option.checked
                                  )?.length as number)
                                ) {
                                  if (field.selectCount?.mode === "MATCH")
                                    toast(
                                      `${field.selectCount?.number}개의 체크박스를 선택해주세요.`,
                                      { toastId: "selections" }
                                    );
                                  else
                                    toast(
                                      `${field.selectCount?.number}개 이하로 선택해주세요.`,
                                      { toastId: "selections" }
                                    );
                                  return { ...option };
                                } else return { ...option, checked };
                              } else return { ...option, checked };
                            }
                          } else return { ...option, checked };
                        } else {
                          if (field.buttonType === "radio") {
                            return { ...option, checked: false };
                          } else {
                            return { ...option };
                          }
                        }
                      }),
                    },
                  };
                } else return { ...field };
              }),
            };
          } else return { ...page };
        } else {
          return {
            ...page,
            fields: page.fields.map((field) => {
              if (`${field.fieldType}:${field.id}` === id) {
                return {
                  ...field,
                  selectCount: {
                    ...field.selectCount,
                    options: field.selectCount?.options?.map((option) => {
                      if (`${field.fieldType}:${option.id}` === optionId) {
                        if (checked) {
                          if (field.buttonType === "radio") {
                            return { ...option, checked };
                          } else {
                            if (
                              field.selectCount?.mode === "BELOW" ||
                              field.selectCount?.mode === "MATCH"
                            ) {
                              if (
                                (field.selectCount?.number as number) <=
                                (field.selectCount.options?.filter(
                                  (option) => option.checked
                                )?.length as number)
                              ) {
                                if (field.selectCount?.mode === "MATCH")
                                  toast(
                                    `${field.selectCount?.number}개의 체크박스를 선택해주세요.`,
                                    { toastId: "selections" }
                                  );
                                else
                                  toast(
                                    `${field.selectCount?.number}개 이하로 선택해주세요.`,
                                    { toastId: "selections" }
                                  );
                                return { ...option };
                              } else return { ...option, checked };
                            } else {
                              return { ...option, checked };
                            }
                          }
                        } else return { ...option, checked };
                      } else {
                        if (field.buttonType === "radio") {
                          return { ...option, checked: false };
                        } else {
                          return { ...option };
                        }
                      }
                    }),
                  },
                };
              } else return { ...field };
            }),
          };
        }
      })
    );
  };

  return (
    <SelectionStyled
      edit={edit}
      complete={complete}
      id={id}
      type={type}
      color={
        type === "sender"
          ? "#666F7B"
          : (fields.find((field) => `${field.fieldType}:${field.id}` === id)
              ?.writer?.color as string)
      }
      required={
        !!fields.find((field) => `${field.fieldType}:${field.id}` === id)
          ?.required
      }
      field="CHECKBOX"
      draggable={draggable}
      scale={scale}
      newField={newField}
      {...args}
    >
      <div className="wrapper">
        <div
          className="container"
          onClick={(e) => {
            e.stopPropagation();
            selectField && selectField(id, id.split(":")[0] as Field);
          }}
        >
          <InitialSelection
            tooltip={edit}
            description={
              fields.find((field) => `${field.fieldType}:${field.id}` === id)
                ?.description || ""
            }
            name={id}
            id={optionId}
            type={
              fields.find((field) => `${field.fieldType}:${field.id}` === id)
                ?.buttonType
            }
            checked={
              !!fields
                .find((field) => `${field.fieldType}:${field.id}` === id)
                ?.selectCount?.options?.find(
                  (option) => `CHECKBOX:${option.id}` === optionId
                )?.checked
            }
            onChange={onChangeHandler}
            disabled={complete}
          />
        </div>
        {draggable && id === selectedFieldId && (
          <DeleteIcon onClick={() => onDeleteHandler(id, optionId)} />
        )}
      </div>
      {draggable && selectedFieldId === id && (
        <div className="order">
          {(fields
            .find(
              (field) => `${field.fieldType}:${field.id}` === selectedFieldId
            )
            ?.selectCount?.options?.findIndex(
              (option) => `CHECKBOX:${option.id}` === optionId
            ) as number) + 1}
        </div>
      )}
    </SelectionStyled>
  );
}

const SelectionStyled = styled(DraggableStyled)<{
  edit?: boolean;
  complete?: boolean;
}>`
  .wrapper {
    .container {
      position: relative;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 0.4rem;
      border-radius: ${({ scale }) => (scale ? `${0.4 * scale}rem` : "0.4rem")};
      color: var(--text-secondary);
      background-color: ${({ edit, complete }) =>
        edit && complete ? "transparent" : "current"};
      border: ${({ edit, complete }) =>
        edit && complete ? "none" : "current"};

      label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${({ scale }) => (scale ? `${2 * scale}rem` : "2rem")};
        /* height: ${({ scale }) => (scale ? `${2 * scale}rem` : "2rem")}; */
        height: 5rem;
        input {
          display: none;
        }
      }

      svg {
        width: ${({ scale }) => (scale ? `${2 * scale}rem` : "2rem")};
        height: ${({ scale }) => (scale ? `${2 * scale}rem` : "2rem")};
        color: ${({ type }) =>
          type === "sender" ? "var(--red-400)" : "var(--blue-500)"};
      }
    }
  }

  .order {
    width: 3.2rem;
    height: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    border: 0.1rem solid var(--stroke-light);
    background-color: white;
    font-size: 1.2rem;
    line-height: 2rem;
    letter-spacing: -0.05em;
  }
`;

import NoticeNavigation from "components/Mobile/Notice/NoticeNavigation";
import React from "react";
import styled from "styled-components";
import { ReactComponent as RightArrowIcon } from "assets/common/24px/icon_right_default.svg";
import { Body3Regular, Body2SemiBold } from "components/TextStyle";
import { useNavigate, Link } from "react-router-dom";

const GUIDE_LIST = [
  {
    subTitle: "처음 오셨나요?",
    title: "01. 퀵 가이드 다운로드",
    link: "https://eform-s3-public-static-files-bucket.s3.ap-northeast-2.amazonaws.com/guide/%5B%E1%84%8B%E1%85%B5%E1%84%91%E1%85%A9%E1%86%B7%5D+%E1%84%8F%E1%85%B1%E1%86%A8%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3.pdf",
  },

  {
    subTitle: "및춤형 서비스를 찾으시나요?",
    title: "02. 브랜딩 서비스 가이드 다운로드",
    link: "https://eform-s3-public-static-files-bucket.s3.ap-northeast-2.amazonaws.com/guide/%5B%E1%84%8B%E1%85%B5%E1%84%91%E1%85%A9%E1%86%B7%5D+%E1%84%87%E1%85%B3%E1%84%85%E1%85%A2%E1%86%AB%E1%84%83%E1%85%B5%E1%86%BC+%E1%84%89%E1%85%A5%E1%84%87%E1%85%B5%E1%84%89%E1%85%B3+%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3.pdf",
  },
];

const NoticeGuidePage = () => {
  const navigate = useNavigate();
  return (
    <NoticeGuideLayout>
      <NoticeNavigation />
      <NoticeGuideBoard>
        <NoticeGuideVideo controls>
          <source src="path/to/your/video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </NoticeGuideVideo>
        <GuideListLayout>
          {GUIDE_LIST.map((guide) => {
            return (
              <div key={guide.link}>
                <GuideListItem>
                  <Body3Regular style={{ color: "var(--text-secondary)" }}>
                    {guide.subTitle}
                  </Body3Regular>
                  <Link
                    to={guide.link}
                    className="guide-list-title"
                    target="_blank"
                  >
                    <Body2SemiBold>{guide.title}</Body2SemiBold>
                    <RightArrowIcon />
                  </Link>
                </GuideListItem>
              </div>
            );
          })}
          <div
            className="add-more-guide"
            onClick={() => {
              navigate("/setting/support");
            }}
          >
            <Body3Regular>더 많은 도움이 필요하신가요</Body3Regular>
          </div>
        </GuideListLayout>
      </NoticeGuideBoard>
    </NoticeGuideLayout>
  );
};

export default NoticeGuidePage;

const NoticeGuideLayout = styled.div`
  padding: 3rem;
`;

const NoticeGuideBoard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  gap: 3rem;
`;

const NoticeGuideVideo = styled.video`
  width: 80%;
  margin: 0 auto;
`;

const GuideListLayout = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;

  .guide-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .add-more-guide {
    display: flex;
    align-items: center;
    color: var(--text-secondary);
  }
`;

const GuideListItem = styled.li`
  margin-bottom: 3.2rem;
`;

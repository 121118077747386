import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTemplateById } from "apis/template";
import { queries } from "config/queryKey";
import { toast } from "react-toastify";
const useDeleteTemplateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteTemplateById,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.template.list._def,
      });

      toast("선택된 템플릿이 삭제되었습니다.");
    },
  });
};

export default useDeleteTemplateMutation;

import React, { SetStateAction, useEffect } from "react";
import { EditorStyled } from "./styles";
import { Page } from "react-pdf";
import { IPage } from "interfaces/contract";
import { FIELDS, Field, History } from "../..";
import { TextAlign } from "models/Contract";
import Item from "components/Draggable/Item";
import { useInView } from "react-intersection-observer";

interface EditorProps {
  template?: boolean;
  pages: IPage[];
  setPages: React.Dispatch<React.SetStateAction<IPage[]>>;
  scale: string;
  setSelectedPageId?: React.Dispatch<React.SetStateAction<string>>;
  selectedPageId: string;
  setSelectedFieldType: React.Dispatch<React.SetStateAction<Field>>;
  selectedFieldId: string;
  setSelectedFieldId: React.Dispatch<SetStateAction<string>>;
  handleDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  pagesContainerRef: React.RefObject<null[] | HTMLDivElement[]>;
  selectField?: (id: string, type: Field) => void;
  setSignatureSelectionModal?: React.Dispatch<React.SetStateAction<boolean>>;
  editDropdown: (contents: string) => void;
  align: ({
    type,
    align,
    all,
  }: {
    type: Field;
    align?: TextAlign | undefined;
    all?: boolean | undefined;
  }) => void;
  openModal: () => void;
  saveToHistory: (current: History) => void;
  saveLastSize?: (field: Field, size: { w: number; h: number }) => void;
}

const ContractSendDetailEditorPage = ({
  template,
  pages,
  setPages,
  scale,
  selectedPageId,
  setSelectedPageId,
  setSelectedFieldType,
  selectedFieldId,
  setSelectedFieldId,
  handleDragStart,
  handleDragEnd,
  onDrop,
  pagesContainerRef,
  selectField,
  setSignatureSelectionModal,
  editDropdown,
  openModal,
  align,
  saveToHistory,
  saveLastSize,
}: EditorProps) => {
  return (
    <EditorStyled onClick={() => setSelectedFieldId("")}>
      {pages.map((page, order) => (
        <PageContainer
          key={page.id}
          id={page.id}
          order={order}
          setSelectedPageId={setSelectedPageId}
        >
          <Page
            className="page"
            pageNumber={page.order}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            scale={+scale.replace("%", "") / 50}
          />
          <div
            className={`canvas:${page.id} canvas`}
            onDragOver={(e) => {
              e.dataTransfer.dropEffect = "move";
              e.preventDefault();
            }}
            onDrop={onDrop}
            onClick={() => setSelectedPageId && setSelectedPageId(page.id)}
            ref={(el) => {
              if (!pagesContainerRef.current) return;

              pagesContainerRef.current[order] = el;
            }}
          >
            {page.fields.map(
              ({ id, type, fieldType, position: { x, y }, selectCount }) =>
                fieldType === "CHECKBOX" ? (
                  selectCount?.options?.map((option) => (
                    <Item
                      field={
                        FIELDS.find((field) => field.value === fieldType) ||
                        FIELDS[0]
                      }
                      id={id}
                      optionId={option.id}
                      key={id}
                      pages={pages}
                      setPages={setPages}
                      type={type}
                      setSelectedFieldType={setSelectedFieldType}
                      selectedFieldId={selectedFieldId}
                      setSelectedFieldId={setSelectedFieldId}
                      selectedPageId={selectedPageId}
                      handleDragStart={handleDragStart}
                      handleDragEnd={handleDragEnd}
                      selectField={selectField}
                      setSignatureSelectionModal={setSignatureSelectionModal}
                      scale={+scale.replace("%", "") / 100}
                      editDropdown={editDropdown}
                      align={align}
                      openModal={openModal}
                      pageContainerRef={pagesContainerRef?.current?.[order]}
                      saveToHistory={saveToHistory}
                      style={{
                        width: `max-content`,
                        height: `max-content`,
                        position: "absolute",
                        left:
                          option.position.x * (+scale.replace("%", "") / 100),
                        top:
                          option.position.y * (+scale.replace("%", "") / 100),
                        zIndex:
                          selectedFieldId === `${fieldType}:${id}` ? 5 : 4,
                        opacity: 1,
                      }}
                    />
                  ))
                ) : (
                  <Item
                    template={template}
                    field={
                      FIELDS.find((field) => field.value === fieldType) ||
                      FIELDS[0]
                    }
                    id={id}
                    key={id}
                    pages={pages}
                    setPages={setPages}
                    type={type}
                    setSelectedFieldType={setSelectedFieldType}
                    selectedFieldId={selectedFieldId}
                    setSelectedFieldId={setSelectedFieldId}
                    selectedPageId={selectedPageId}
                    handleDragStart={handleDragStart}
                    handleDragEnd={handleDragEnd}
                    selectField={selectField}
                    setSignatureSelectionModal={setSignatureSelectionModal}
                    scale={+scale.replace("%", "") / 100}
                    editDropdown={editDropdown}
                    align={align}
                    openModal={openModal}
                    pageContainerRef={pagesContainerRef.current?.[order]}
                    saveToHistory={saveToHistory}
                    saveLastSize={saveLastSize}
                    style={{
                      width: `max-content`,
                      height: `max-content`,
                      position: "absolute",
                      left: x * (+scale.replace("%", "") / 100),
                      top: y * (+scale.replace("%", "") / 100),
                      zIndex: selectedFieldId === `${fieldType}:${id}` ? 5 : 4,
                      opacity: 1,
                    }}
                  />
                )
            )}
          </div>
        </PageContainer>
      ))}
    </EditorStyled>
  );
};

export default ContractSendDetailEditorPage;

export const PageContainer = ({
  id,
  children,
  setSelectedPageId,
  order,
}: React.PropsWithChildren & {
  id: string;
  setSelectedPageId?: React.Dispatch<React.SetStateAction<string>>;
  order: number;
}) => {
  const { ref, inView } = useInView({
    threshold: 0.25,
    initialInView: order === 1 ? true : false,
  });

  useEffect(() => {
    if (setSelectedPageId && inView) {
      setSelectedPageId((prev) => (prev === id ? prev : id));
    }
  }, [inView]);

  return (
    <div className="page-container" ref={ref} id={id}>
      {children}
    </div>
  );
};

import { ReactComponent as DropdownOpenIcon } from "assets/common/20px/icon_down_default.svg";
import { ReactComponent as DropdownIcon } from "assets/common/20px/icon_list.svg";
import { ReactComponent as DeleteIcon } from "assets/common/24px/icon_close_default.svg";
import Button from "components/Common/Button/Button";
import AlignOption from "components/Contract/Options/Align";
import FontSizeOption from "components/Contract/Options/FontSize";
import RequiredOption from "components/Contract/Options/Required";
import Resizable from "components/Resizable";
import { Body3Regular } from "components/TextStyle";
import { IField, IPage } from "interfaces/contract";
import { DropDownItem, TextAlign } from "models/Contract";
import { Field } from "pages/contract/send";
import React, { useState } from "react";
import styled from "styled-components";
import { DraggableStyled } from "./styles";

interface DropdownListProps extends React.HTMLAttributes<HTMLDivElement> {
  edit?: boolean;
  complete?: boolean;
  id: string;
  type: "sender" | "receiver";
  fields: IField[];
  pages: IPage[];
  setPages: React.Dispatch<React.SetStateAction<IPage[]>>;
  selectedPageId: string;
  selectedFieldId?: string;
  onDeleteHandler: (id: string) => void;
  selectField?: (id: string, type: Field) => void;
  scale: number;
  editDropdown?: (contents: string) => void;
  align?: ({
    type,
    align,
    all,
  }: {
    type: Field;
    align?: TextAlign | undefined;
    all?: boolean | undefined;
  }) => void;
  openModal?: () => void;
  pageContainerRef?: any;
  currentSize: { w: number; h: number };
  handleMouseDown: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>,
    direction: string
  ) => void;
  handleMouseMove: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>
  ) => void;
  handleMouseUp: () => void;
  newField?: boolean;
  isMobile?: boolean;
}

export default function DropdownList({
  edit = false,
  complete = false,
  id,
  type,
  fields,
  pages,
  setPages,
  selectedPageId,
  selectedFieldId,
  onDeleteHandler,
  selectField,
  scale,
  editDropdown,
  draggable,
  align,
  openModal,
  pageContainerRef,
  currentSize,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  newField,
  isMobile,
  ...args
}: DropdownListProps) {
  const [isEdit, setIsEdit] = useState(false);
  const [opened, setOpened] = useState(false);

  const toggle = () => setOpened((prev) => !prev);

  const select = (value: DropDownItem) => {
    setPages((prev) =>
      prev.map((page) => {
        if (isMobile) {
          if (page.order.toString() === selectedPageId) {
            return {
              ...page,
              fields: page.fields.map((field) => {
                if (`${field.fieldType}:${field.id}` === id) {
                  return {
                    ...field,
                    dropDownItems: field.dropDownItems?.map((item) => {
                      if (item.id === value.id) {
                        return { ...item, isSelected: true };
                      } else return { ...item, isSelected: false };
                    }),
                  };
                } else return { ...field };
              }),
            };
          } else return { ...page };
        } else
          return {
            ...page,
            fields: page.fields.map((field) => {
              if (`${field.fieldType}:${field.id}` === id) {
                return {
                  ...field,
                  dropDownItems: field.dropDownItems?.map((item) => {
                    if (item.id === value.id) {
                      return { ...item, isSelected: true };
                    } else return { ...item, isSelected: false };
                  }),
                };
              } else return { ...field };
            }),
          };
      })
    );
  };

  return (
    <>
      <DropdownListStyled
        id={id}
        type={type}
        color={
          type === "sender"
            ? "#666F7B"
            : (fields.find((field) => `${field.fieldType}:${field.id}` === id)
                ?.writer?.color as string)
        }
        required={
          !!fields.find((field) => `${field.fieldType}:${field.id}` === id)
            ?.required
        }
        draggable={draggable}
        scale={scale}
        align={
          fields.find((field) => `${field.fieldType}:${field.id}` === id)?.align
        }
        fontSize={
          fields.find((field) => `${field.fieldType}:${field.id}` === id)?.size
        }
        complete={complete}
        newField={newField}
        isMobile={isMobile}
        {...args}
      >
        <div className="wrapper">
          <div className="field-container">
            <div
              className="container dropdownList"
              onClick={(e) => {
                e.stopPropagation();
                if (edit) toggle();
                selectField && selectField(id, id.split(":")[0] as Field);
              }}
              style={{
                width: `${(currentSize.w / 10) * scale}rem`,
                height: isMobile
                  ? `${2 * scale}rem`
                  : `${(currentSize.h / 10) * scale}rem`,
              }}
            >
              {edit ? (
                <div className="select">
                  <div className="current">
                    {fields
                      .find((field) => `${field.fieldType}:${field.id}` === id)
                      ?.dropDownItems?.find((item) => item.isSelected)?.value ||
                      ""}
                  </div>
                  {!complete && <DropdownOpenIcon />}
                  {!complete && opened && (
                    <ul className="options">
                      {fields
                        .find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )
                        ?.dropDownItems?.map((item) => (
                          <li
                            key={item.id}
                            className="option"
                            onClick={() => select(item)}
                          >
                            {item.value}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              ) : (
                <>
                  <DropdownIcon />
                  {isEdit ? (
                    <input
                      type="text"
                      value={
                        fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.name
                      }
                      style={{
                        fontSize: isMobile
                          ? "0.4rem"
                          : `${
                              (+(fields.find(
                                (field) =>
                                  `${field.fieldType}:${field.id}` === id
                              )?.size as string) /
                                10) *
                              scale
                            }rem`,
                        lineHeight: 1.4285714286,
                        color: "var(--text-secondary)",
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setIsEdit(false);
                        }
                      }}
                      onChange={(e) => {
                        setPages((prev) =>
                          prev.map((page) => {
                            if (
                              page.fields
                                .map(
                                  (field) => `${field.fieldType}:${field.id}`
                                )
                                .includes(id)
                            ) {
                              return {
                                ...page,
                                fields: page.fields.map((field) => {
                                  if (`${field.fieldType}:${field.id}` === id) {
                                    return { ...field, name: e.target.value };
                                  } else return { ...field };
                                }),
                              };
                            } else return { ...page };
                          })
                        );
                      }}
                    />
                  ) : (
                    <Body3Regular
                      style={{
                        fontSize: isMobile
                          ? "0.4rem"
                          : `${
                              (+(fields.find(
                                (field) =>
                                  `${field.fieldType}:${field.id}` === id
                              )?.size as string) /
                                10) *
                              scale
                            }rem`,
                        lineHeight: 1.4285714286,
                      }}
                      onDoubleClick={
                        !newField && draggable
                          ? () => setIsEdit((prev) => !prev)
                          : undefined
                      }
                    >
                      {
                        fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.name
                      }
                    </Body3Regular>
                  )}
                </>
              )}
            </div>
            {!newField && draggable && id === selectedFieldId && (
              <Resizable
                pages={pages}
                setPages={setPages}
                selectedFieldId={selectedFieldId}
                selectedPageId={selectedPageId}
                handleMouseUp={handleMouseUp}
                handleMouseDown={handleMouseDown}
                handleMouseMove={handleMouseMove}
                color={
                  type === "sender"
                    ? "#666F7B"
                    : !!fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.required
                    ? "red"
                    : "var(--blue-500)"
                }
                scale={scale}
              />
            )}
          </div>
          {draggable && id === selectedFieldId && (
            <DeleteIcon onClick={() => onDeleteHandler(id)} />
          )}
        </div>
        {draggable && selectedFieldId === id && (
          <div className="options" onClick={(e) => e.stopPropagation()}>
            <div className="option">
              <FontSizeOption
                fields={fields}
                setPages={setPages}
                selectedPageId={selectedPageId}
                selectedFieldId={selectedFieldId}
              />
            </div>
            <div className="option">
              <AlignOption
                alignFunc={align}
                type="DROPDOWN"
                selected={
                  fields.find(
                    (field) => `${field.fieldType}:${field.id}` === id
                  )?.align || "LEFT"
                }
              />
            </div>
            <div className="option">
              <RequiredOption
                fields={fields}
                setPages={setPages}
                selectedPageId={selectedPageId}
                selectedFieldId={selectedFieldId}
              />
            </div>
            <div className="option">
              <Button
                className="edit"
                colorType="tertiary"
                size="small"
                onClick={openModal}
              >
                등록/편집
              </Button>
            </div>
          </div>
        )}
      </DropdownListStyled>
    </>
  );
}

const DropdownListStyled = styled(DraggableStyled)`
  .wrapper {
    .container {
      padding: ${({ scale, isMobile }) =>
        isMobile ? "0" : scale ? `0 ${scale * 1}rem` : "0 1rem"};
      box-sizing: border-box;
      display: flex;
      column-gap: 0.4rem;
      border-radius: ${({ scale }) => (scale ? `${scale * 0.4}rem` : "0.4rem")};
      color: var(--text-secondary);

      .select {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .current {
          cursor: default;
          flex-grow: 1;
          text-align: ${({ align }) => align || "unset"};
          font-size: ${({ scale, fontSize }) =>
            scale
              ? `${scale * (fontSize ? +fontSize / 10 : 1.6)}rem`
              : `${fontSize ? +fontSize / 10 : 1.6}rem`};
        }

        .options {
          position: absolute;
          top: ${({ scale }) =>
            scale ? `calc(100% + ${1.3 * scale}rem)` : `calc(100% + 1.3rem)`};
          left: ${({ scale }) => (scale ? `${-1 * scale}rem` : `-1rem`)};
          width: ${({ scale }) =>
            scale ? `calc(100% + ${2 * scale}rem)` : `calc(100% + 2rem)`};
          min-width: unset;
          padding: ${({ scale, isMobile }) =>
            isMobile ? "0" : scale ? `${scale * 0.8}rem` : `0.8rem`};
          display: flex;
          flex-direction: column;
          row-gap: 0.8rem;
          border-radius: 0.5rem;

          .option {
            width: 100%;
            box-sizing: border-box;
            padding: ${({ scale, isMobile }) =>
              isMobile ? "0" : scale ? `${scale * 0.8}rem` : `0.8rem`};
            font-size: ${({ scale }) =>
              scale ? `${scale * 1.5}rem` : `1.5rem`};
            font-weight: 500;
            line-height: ${({ scale }) =>
              scale ? `${scale * 2.2}rem` : `2.2rem`};
            letter-spacing: -0.03em;
            border-radius: 0.4rem;

            &:hover {
              background-color: var(--bg-dark);
            }
          }
        }

        svg {
          width: ${({ scale }) => (scale ? `${scale * 2}rem` : "2rem")};
          height: ${({ scale }) => (scale ? `${scale * 2}rem` : "2rem")};
        }
      }
    }
  }

  .options {
    .option {
      .dropdown {
        width: max-content;

        .contentContainer {
          justify-content: flex-start;
          padding: 0;
        }
      }

      .alignOptions {
        display: flex;
        align-items: center;
        column-gap: 0.4rem;
      }

      button.edit {
        width: 100%;
      }
    }
  }
`;

import React, { forwardRef } from "react";
import ReactDatePicker, {
  DatePickerProps as ReactDatePickerProps,
} from "react-datepicker";
import { ko } from "date-fns/locale/ko";
import styled from "styled-components";
import { ReactComponent as ArrowLeftIcon } from "assets/common/24px/icon_left_default.svg";
import { ReactComponent as ArrowRightIcon } from "assets/common/24px/icon_right_default.svg";
import { ReactComponent as ArrowDownIcon } from "assets/common/16px/icon_arrow_down.svg";
import { getMonth, getYear } from "date-fns";
import * as Dialog from "@radix-ui/react-dialog";

import useModal from "hooks/useModal";
import YearDropdownContent from "./YearDropdownContent";
import CustomDatePickerInput, {
  CustomDatePickerInputProps,
} from "./CustomDatePickerInput";
import { breakpoints } from "components/styles";

type DatePickerProps = Omit<ReactDatePickerProps, "onChange"> & {
  selectedDate?: Date;
  onChange?: (date: Date | null) => void;
  customInput?: any;
  placeholder?: string;
  disabled?: boolean;
  isMaxDate?: boolean;
  selectsRange?: never;
  selectsMultiple?: never;
  isMobile?: boolean;
};

type InputProps = CustomDatePickerInputProps;

const DatePicker = forwardRef<
  ReactDatePicker,
  DatePickerProps & { inputProps?: InputProps }
>(
  (
    {
      selectedDate,
      onChange,
      customInput,
      placeholder,
      disabled,
      inputProps,
      isMaxDate,
      isMobile,
      ...args
    },
    ref
  ) => {
    const { openModal, closeModal, isOpen } = useModal();
    return (
      <DatePickerStyle>
        <ReactDatePicker
          ref={ref}
          id="date-picker"
          showPopperArrow={false}
          locale={ko}
          selected={selectedDate}
          dateFormat={"yyyy.MM.dd"}
          onChange={(date, _) => {
            if (Array.isArray(date)) {
              onChange?.(date[0]);
            } else {
              onChange?.(date);
            }
          }}
          placeholderText={placeholder}
          disabled={disabled}
          renderCustomHeader={({
            date,
            changeYear,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <DatePickerHeader id="header">
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <ArrowLeftIcon />
              </button>
              {getYear(date)}년 {getMonth(date) + 1}월
              <Dialog.Root open={isOpen}>
                <Dialog.Trigger onClick={openModal}>
                  <ArrowDownIcon />
                </Dialog.Trigger>
                <Dialog.Portal
                  container={
                    document.getElementById("header")?.parentElement
                      ?.parentElement
                  }
                >
                  <Dialog.Content>
                    <YearDropdownContent
                      isMobile={isMobile}
                      changeYear={changeYear}
                      closeContent={closeModal}
                      date={date}
                    />
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <ArrowRightIcon />
              </button>
            </DatePickerHeader>
          )}
          customInput={customInput || <CustomDatePickerInput {...inputProps} />}
          {...(isMaxDate && { maxDate: new Date() })}
          {...args}
        />
      </DatePickerStyle>
    );
  }
);

DatePicker.displayName = "DatePicker";

const DatePickerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  margin-bottom: 1.2rem;
  position: relative;
  font-size: 2rem;
  font-weight: 700;

  @media (max-width: ${breakpoints.lg}) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    margin-bottom: 0.8rem;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const DatePickerStyle = styled.div`
  color: var(--text-default);
  font-family: Pretendard;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-align: center;

  @media (max-width: ${breakpoints.lg}) {
    width: 100%;

    .react-datepicker__input-container {
      font-size: 0.6rem;
      line-height: 140%;
      font-style: normal;
      font-weight: 500;
      text-align: center;
    }
  }

  .react-datepicker-popper {
    z-index: 1000;

    @media (max-width: ${breakpoints.lg}) {
      position: fixed !important;
      max-width: 90% !important;
      top: auto !important;
      bottom: auto !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
      z-index: 1000 !important;
    }
  }

  .react-datepicker__month-container {
    padding: 2rem;
    background-color: var(--bg-white);
    border-radius: 2rem;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);

    @media (max-width: ${breakpoints.lg}) {
      max-width: 100%;
    }
  }

  .react-datepicker__day-names {
    color: var(--text-secondary);
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: grid;
    grid-template-columns: repeat(7, 4.2rem);

    @media (max-width: ${breakpoints.lg}) {
      grid-template-columns: repeat(7, minmax(3rem, 4.2rem));
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
      padding: 1rem;

      @media (max-width: ${breakpoints.lg}) {
        padding: 0.8rem;
        font-size: 1.2rem;
        line-height: 1.2;
        width: 1.2rem;
        height: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .react-datepicker__day {
      cursor: pointer;
    }
  }

  .react-datepicker__day--disabled {
    color: var(--stroke-dark);
  }
  .react-datepicker__day--today {
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.8);
  }
  .react-datepicker__day--selected {
    border-radius: 50%;
    background: var(--blue-500);
    color: var(--bg-white);
    border: none;
  }

  .react-datepicker__aria-live {
    display: none;
  }
`;

export default DatePicker;
